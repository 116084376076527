import { Injectable } from '@angular/core';

import { IEntityService } from '../entities/entity.service';
import { ApiService } from '../core/api.service';
import { AutomapperService } from '../core/automapper.service';
import { GenericFormType } from '../generic-form/generic-form.component';
import { EventCreateConstraints, EventUpdateConstraints } from './event.constraints';
import { ITableColumn } from '../entities/ITableColumn';
import { IEventEntranceCharge } from '../types/IEventEntranceCharge';

@Injectable()
export class EventService implements IEntityService {

  constructor(
    private ApiService: ApiService,
    private AutomapperService: AutomapperService) { }

  getName(): string {
    return 'events';
  }

  getAll(): Promise<any[]> {
    return this.ApiService.get('/admin/event', [])
      .then((events: any[]) => {
        return this.AutomapperService.mapArray('EventApi', 'EventModel', events);
      });
  }

  getActive(venueId: string): Promise<any[]> {
    return this.ApiService.get(`/venue/${venueId}/active-event`, [])
      .then((events: any[]) => {
        return this.AutomapperService.mapArray('EventApi', 'EventModel', events);
      });
  }

  getOne(query: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  find(query: any): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  getById(id: string): Promise<any> {
    return this.ApiService.get('/admin/event/' + id, [])
      .then((event) => {
        console.log("event", event)
        const eventMaped = this.AutomapperService.map('EventApi', 'EventModel', event);
        return eventMaped;
      });
  }

  create(entity: any): Promise<any> {
    return this.ApiService.post('/event', entity, []);
  }

  async update(entity: any): Promise<any> {
    const eventStatus = entity.eventStatus;
    const entityMaped: any = this.AutomapperService.map('EventModel', 'EventApi', entity);
    let event = await this.ApiService.put('/event/' + entityMaped.id, {
      id: entityMaped.id,
      payload: entityMaped
    }, []);
    if (event && !event.error && event.eventStatus !== eventStatus) {
      event = await this.ApiService.post(`/event/${event.id}/status`, { status: eventStatus }, []);
    }
    return event;
  }

  getEventInvoice(eventId: string): Promise<any> {
    return this.ApiService.get(`/event/${eventId}/invoice?showActualTips=true`, [])
      .then((invoice) => {
        console.log('hello',invoice)
        return invoice;
      });
  }

  getEventEntranceFees(eventId: string): Promise<IEventEntranceCharge[]> {
    return this.ApiService.get(`/event/${eventId}/entrance-charges`, [])
      .then((entranceCharges) => {
        console.log('entrance-charges', entranceCharges)
        return entranceCharges;
      });
  }

  delete(entity: any): Promise<boolean> {
    return this.ApiService.delete('/event/' + entity.id, []);
  }

  deleteOrder(orderId: string): Promise<boolean> {
    return this.ApiService.delete('/order/' + orderId, []);
  }

  getEventsInfo(venueId, query, includes, skipErrorHandling): Promise<any> {
    const payload = {
      query,
      includes: includes
    };
    return this.ApiService.post(`/venue/${venueId}/event-info/search`, payload, skipErrorHandling);
  }

  createSitAndEatEvent(venueId, table, maxParticipants): Promise<any> {
    const payload = {
      venueId,
      table,
      maxParticipants
    };
    return this.ApiService.post(`/event/sit-and-eat`, payload, []);
  }

  createOrders(venueId, eventId, items): Promise<any> {
    const preparedItems = [...items];
    preparedItems.forEach((x) => {
      delete x.name;
      delete x.price;
    });
    const payload = {
      items: preparedItems
    };
    return this.ApiService.post(`/venue/${venueId}/event/${eventId}/order`, payload, []);
  }

  setEventAsPaid(eventId): Promise<any> {
    return this.ApiService.post(`/event/${eventId}/status`, { status: 'PAID' }, []);
  }

  getTableColumns(): ITableColumn[] {
    return [{
      index: 0,
      name: 'id',
      type: 'text',
      label: 'ID',
      hidden: false
    }, {
      index: 1,
      type: 'text',
      name: 'name',
      label: 'Name',
      hidden: false
    }, {
      index: 2,
      type: 'text',
      name: 'venueName',
      label: 'Venue Name',
      hidden: false
    }, {
      index: 3,
      type: 'date-range',
      name: 'startDate',
      label: 'Start Date',
      hidden: false
    }, {
      index: 4,
      type: 'date-range',
      name: 'createdAt',
      label: 'Creation Date',
      hidden: false
    }, {
      index: 5,
      type: 'text',
      name: 'type',
      label: 'Type',
      hidden: false
    }, {
      index: 6,
      type: 'text',
      name: 'participantsCount',
      label: 'Participants #',
      hidden: false
    }, {
      index: 7,
      type: 'text',
      name: 'eventStatus',
      label: 'Status',
      hidden: false
    },
    {
      index: 8,
      type: 'text',
      name: 'neighborhood',
      label: 'Neighborhood',
      hidden: false
    },
    ];
  }

  getTableOptions(): any {
    return {
      order: [[3, 'desc']],
    };
  }

  getCreateConstraints(): GenericFormType[] {
    return EventCreateConstraints;
  }
  getUpdateConstraints(): GenericFormType[] {
    return EventUpdateConstraints;
  }
}
