import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumSubscriptionsComponent } from './premium-subscriptions.component';
import { SharedModule } from '../shared/shared.module';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { UserRoles } from '../users/user.roles';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { PremiumSubscriptionsService } from './premium-subscriptions.service';



@NgModule({
  declarations: [PremiumSubscriptionsComponent],
  imports: [
    CommonModule,
    SharedModule,
    LaddaModule,
    GenericFormModule,
    FormsModule,
    RouterModule.forChild([{
      path: 'premium',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/premium',
          icon: 'glyphicon glyphicon-star',
          label: 'Premium',
          category: 'premium subscriptions',
          index: 7
        },
        access: [UserRoles.ADMIN]
      },
      children: [
        {
          path: '',
          component: PremiumSubscriptionsComponent,
          canActivate: [AuthenticatedGuard],
          data: { title: 'Premium Subscriptions', category: 'Subscriptions',  }
        },
      ]
    }])
  ],
  providers: [PremiumSubscriptionsService]
})
export class PremiumSubscriptionsModule { }
