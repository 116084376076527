import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { GenericFormComponent, GenericFormType } from '../../generic-form/generic-form.component';
import { VenueService } from '../venue.service';
import { WaiterCreateConstraints } from './waiter-create.constraints';

@Component({
    selector: 'app-waiter-create',
    templateUrl: './waiter-create.component.html'
})
export class WaiterCreateComponent implements OnInit {
    @ViewChild('waiterForm', { static: false }) waiterForm: GenericFormComponent;

    createRules: GenericFormType[] = WaiterCreateConstraints;
    venue: any;
    venueId: string;
    createInProgress: boolean;
    onClaimed: any;

    constructor(
        public bsModalRef: BsModalRef,
        private venueService: VenueService
    ) { }

    async ngOnInit() {
        this.venueId = this.venue.id;
        this.createInProgress = false;
    }

    async create() {
        if (this.waiterForm.isValid()) {
            try {
                this.createInProgress = true;
                let waiter: any = this.waiterForm.toModel('WaiterCreateForm', 'WaiterModel');
                waiter = await this.venueService.createWaiter(this.venueId, waiter);
                this.createInProgress = false;
                this.onClaimed();
                this.bsModalRef.hide();
            } catch (err) {
                this.createInProgress = false;
            }
        }
    }

    cancel() {
        this.bsModalRef.hide();
    }
}
