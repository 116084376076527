import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { GenericFormComponent, GenericFormType, Lookup, GenericFormControl } from '../../generic-form/generic-form.component';
import { MenuService } from './venue-menu.service';
import { cloneDeep } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap';
import { GenericFormBuilder } from '../../generic-form/generic-form.builder';
import { Validators, FormGroup, FormArray } from '@angular/forms';
import { FileUploadComponent } from '../../file.upload/file.upload.component';
import { getDaysFromRange } from '../../shared/week.days';
import { MenuItemUpdateConstraints } from './menu-item.constraints';
import { GenericMenuCategories } from './generic.menu.categories';
import { HourSlots } from '../../shared/hour.slots';

@Component({
  selector: 'app-menu-item-edit',
  templateUrl: './menu-item.edit.component.html'
})
export class MenuItemEditComponent implements OnInit, AfterViewInit {

  @ViewChild('menuItemForm', { static: false }) menuItemForm: GenericFormComponent;
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUploadComponent;

  updateInProgress: boolean;
  menu: any;
  venue: any;
  venueId: string;
  updateRules: GenericFormType[];
  loadMenus: any;
  updateEditedMenu: any;
  categories: Lookup[];
  currentCategory: string;
  happyHours: any[];
  selectedHH: any;
  happyHourLookups: Lookup[];
  currentType: string;
  selectedOption: string;
  menues: Lookup[];
  defaultImage: boolean;
  imageRemoved: boolean;

  constructor(
    private bsModalRef: BsModalRef,
    private menuService: MenuService
  ) { }

  async ngOnInit() {
    this.updateInProgress = false;
    this.updateRules = cloneDeep(MenuItemUpdateConstraints.slice(0));
    // add autocomplete category selection to the top of the menuItemUpdate rules
    // tslint:disable-next-line:max-line-length
    this.updateRules.unshift(GenericFormBuilder.autocomplete('category', 'Category', [Validators.required], [GenericMenuCategories.UNKNOWN], this.searchItems.bind(this), null, false, (x) => x.type !== 'happyHour'));
    const typeLookup = this.updateRules.find((x) => x.name === 'type');
    if (typeLookup) {
      (typeLookup as GenericFormControl).lookups = this.menues;
    }
    this.isDefaultImage();
  }

  ngAfterViewInit() {
    this.menuItemForm.fromModel<any>('MenuItemModel', 'MenuItemEditForm', this.menu);
    this.menuItemForm.myForm.get('category').setValue({ value: this.currentCategory, label: this.currentCategory });
    if (!!this.menu.addonsCategories) {
      this.menu.addonsCategories.forEach((ac, index) => {
        if (!!ac.addonsLabel) {
          this.menuItemForm.myForm.get("addonsCategories").get(`${index}`).get("addonsLabel").setValue({ value: ac.addonsLabel, label: ac.addonsLabel });
        }
      });
    }
    this.currentType = this.menu.type;
    this.selectedOption = this.menu.category;
    this.happyHourLookups = this.happyHours
      .filter((x) => !!x.startDate)
      .map((x) => {
        return {
          value: x.name,
          // tslint:disable-next-line:max-line-length
          label: `${x.name} @ ${x.startDate.day} ${(x.startDate.dayUntil ? '- ' + x.startDate.dayUntil : '')} ${this.getHourSlot(x.startDate.start)} - ${this.getHourSlot(x.startDate.end)}`,
          selected: (this.menu.category === x.name)
        };
      });
    this.happyHourLookups.unshift({ value: null, label: 'Select Happy Hour' });
    const selectedHH = this.happyHourLookups.find(hh => !!hh.selected);
    if (selectedHH) {
      this.happyHourSelected(selectedHH);
    }
  }

  searchItems(text: string) {
    if (!text) {
      return this.categories;
    }
    return this.categories.filter((c) => {
      return c.value.toLowerCase().indexOf(text.toLowerCase()) !== -1;
    });
  }

  isDefaultImage() {
    this.defaultImage = !!(this.menu.images[0] === 'assets/images/meal-default-img.jpg');
    return;
  }

  removeImage() {
    this.menu.images[0] = 'assets/images/meal-default-img.jpg';
    this.isDefaultImage();
    this.imageRemoved = true;
    return;
  }

  async update() {
    if (this.menuItemForm.isValid()) {
      this.updateInProgress = true;
      try {
        let newMenu: any = this.menuItemForm.toModel<any>('MenuItemCreateForm', 'MenuItemModel');
        newMenu.id = this.menu.id;
        if (this.currentType === 'happyHour' && this.selectedHH) {
          newMenu.category = this.selectedHH.name;
        }
        this.isDefaultImage();
        if (this.defaultImage && this.imageRemoved) {
          newMenu.images = [];
        }
        if (this.fileUpload.fileUploaded && this.fileUpload.filesData && this.fileUpload.filesData.length) {
          newMenu.images = [];
          newMenu = await this.menuService.update(this.venue.id, newMenu);
          newMenu = await this.menuService.uploadPhoto(this.venue.id, newMenu.id, this.fileUpload.filesData[0]);
        } else {
          newMenu = await this.menuService.update(this.venue.id, newMenu);
        }
        this.updateInProgress = false;
        if (this.menu.category !== newMenu.category || this.menu.type !== newMenu.type) {
          this.loadMenus();
          this.bsModalRef.hide();
          return;
        }
        await this.updateEditedMenu(this.menu, newMenu);
        this.bsModalRef.hide();
      } catch (error) {
        console.log(error);
        this.updateInProgress = false;
      }
    }
  }

  happyHourSelected($event) {
    const selected = $event.value;
    this.selectedHH = this.happyHours.find((x) => x.name === selected);
    if (this.selectedHH) {
      const menuModel = this.menuItemForm.toModel<any>('MenuItemCreateForm', 'MenuItemModel');
      menuModel.workingHours = [];
      const days = getDaysFromRange(this.selectedHH.startDate.day, this.selectedHH.startDate.dayUntil);
      days.forEach((day) => {
        menuModel.workingHours.push({
          day: day.value,
          open: true,
          splitWorkingHours: false,
          start: this.selectedHH.startDate.start,
          end: this.selectedHH.startDate.end
        });
      });
      this.menuItemForm.fromModel('MenuItemModel', 'MenuItemEditForm', menuModel);
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }

  dataChanged(data) {
    this.currentType = data.type;
  }

  private getHourSlot(timestamp: string) {
    const hour = HourSlots.find((x) => x.value === timestamp);
    if (hour) {
      return hour.label;
    }
    return timestamp;
  }
}
