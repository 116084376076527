import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToasterService } from "angular2-toaster";
import { AuthService } from "../core/auth.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import {
  PosThirdPartyService,
  cloverCredentials,
} from "../posClover/pos-third-party.service";
import { VenueService } from "../venue/venue.service";
import { environment } from "../../environments/environment";

@Component({
  templateUrl: "./login.component.html",
  styles: [" .container-venue-names {display: flex; flex-direction: column;}"],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  loginInProgress: boolean;
  loginClover: cloverCredentials;
  bsModalRef: BsModalRef;
  venueNameList: {
    id: string;
    name: string;
  }[];
  venueSelected: string;

  @ViewChild("modalSelectVenueCloverId", { static: false })
  modalSelectVenueCloverIdRef;
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private posThirdPartyService: PosThirdPartyService,
    private modalService: BsModalService,
    private venueService: VenueService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (
        params.hasOwnProperty("client_id") &&
        params.hasOwnProperty("code") &&
        params.hasOwnProperty("employee_id") &&
        params.hasOwnProperty("merchant_id")
      ) {
        this.loginClover = {
          client_id: params.client_id,
          code: params.code,
          employee_id: params.employee_id,
          merchant_id: params.merchant_id,
        };
      }
    });
    this.form = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
    });
    this.loginInProgress = false;
  }

  async login(): Promise<void> {
    this.loginInProgress = true;
    try {
      await this.authService.login(
        this.form.controls.email.value,
        this.form.get("password").value
      );

      if (this.loginClover) {
        const dataFromClover = await this.posThirdPartyService
          .getMerchantData(this.loginClover.merchant_id)
          .catch((err) => err);

        if (dataFromClover["statusCode"] === 200 ) {
          window.location.href =
            environment.posAPI.cloverDashboard + dataFromClover.merchantId;
        }

        if (dataFromClover["statusCode"] === 404) {
          this.venueNameList = await this.venueService.getVenuesName();

          if (!this.venueNameList.length) {
            this.toasterService.pop(
              "error",
              "No venues found for this account"
            );
          }
          this.bsModalRef = this.modalService.show(
            this.modalSelectVenueCloverIdRef
          );
        }
      }

      this.router.navigateByUrl("/");
    } catch (err) {
      console.log(err);
      this.toasterService.pop("error", err.error_description || "error login");
    } finally {
      this.loginInProgress = false;
    }
  }

  logout(): void {
    this.authService.logout().then(() => {
      return this.router.navigateByUrl("/login");
    });
  }

  reset() {
    this.form.reset();
  }

  venueCloverSelected(venueId: string) {
    console.log(venueId);
    const cloverAuthBody = {
      ...this.loginClover,
      venueId,
    };
    this.posThirdPartyService
      .loginClover(cloverAuthBody)
      .then((res) => this.toasterService.pop("success", "Cuculi is Ready! Check your orders on Clover POS"))
      .catch((err) => this.toasterService.pop("error", "an error tied the Cuculi venue with the Clover POS data"));
      setTimeout(() => {
        window.location.href = environment.posAPI.cloverDashboard + cloverAuthBody.merchant_id;
      }, 3000);
  }
}
