import { Injectable } from '@angular/core';

import { IEntityService } from '../entities/entity.service';
import { ApiService } from '../core/api.service';
import { IValidationRule } from '../core/model.validator';
import { AutomapperService } from '../core/automapper.service';
import { GenericFormType } from '../generic-form/generic-form.component';
import { ITableColumn } from '../entities/ITableColumn';
import { postDataColumns, PostUpdateConstraints } from './post.constraints';
import { EntityCreateComponent } from '../entities/entity-create/entity-create.component';

@Injectable()
export class PostService implements IEntityService {

    constructor(
        private ApiService: ApiService,
        private AutomapperService: AutomapperService) { }

    getName(): string {
        return 'posts';
    }

    getAll(): Promise<any[]> {
        return this.ApiService.post('/posts', {}, [])
            .then((posts: any[]) => {
                return this.AutomapperService.mapArray('PostApi', 'PostModel', posts);
            });
    }

    getOne(query: any): Promise<any> {
        throw new Error('Method not implemented.');
    }

    find(query: any): Promise<any[]> {
        throw new Error('Method not implemented.');
    }

    getById(id: string): Promise<any> {
        return this.ApiService.get('/post/' + id, [])
            .then((post) => {
                const postMaped = this.AutomapperService.map('PostApi', 'PostModel', post);
                return postMaped;
            });
    }

    create(entity: any): Promise<any> {
        return this.ApiService.post('/post', entity, []);
    }

    update(entity: any): Promise<any> {
        const entityMaped: any = this.AutomapperService.map('PostModel', 'PostApi', entity);
        return this.ApiService.put('/post/' + entityMaped.id, entityMaped, []);
    }

    delete(entity: any): Promise<boolean> {
        return this.ApiService.delete('/post/' + entity.id, []);
    }

    async uploadPhoto(postId: string, payload: any): Promise<any> {
        return await this.ApiService.post('/post/' + postId + "/image", payload, [])
            .then((post) => {
                return this.AutomapperService.map("PostApi", "PostModel", post);
            });
    }

    getTableColumns(): ITableColumn[] {
        return postDataColumns;
    }

    getTableOptions(): any {
        return {
            order: [[2, 'desc']],
        };
    }

    getCreateConstraints(): GenericFormType[] {
        return [];
    }
    getUpdateConstraints(): GenericFormType[] {
        return PostUpdateConstraints;
    }
    getOwnerConstraints(): GenericFormType[] {
        return [];
    }
}
