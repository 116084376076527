import { GenericFormType } from '../generic-form/generic-form.component';
import { Validators } from '@angular/forms';

export const CreateProductConstraints: GenericFormType[] = [
    {
        type: 'control', name: 'name', label: 'Name (required)', inputType: 'text',
        placeHolder: 'Name', validators: [Validators.required]
    },
    {
        type: 'control', name: 'category', label: 'Category (required)', inputType: 'dropdown',
        placeHolder: 'Category', lookups: []
    },
    {
        type: 'control', name: 'vendor', label: 'Vendor', inputType: 'dropdown',
        placeHolder: 'Vendor', lookups: [
            { value: '', label: 'No Vendor selected', selected: true },
        ]
    },
    {
        type: 'control', name: 'description', label: 'Description (required)', inputType: 'textarea',
        placeHolder: 'Description', validators: [Validators.required]
    },
    {
        type: 'control', name: 'orderable', label: 'Orderable', inputType: 'checkbox',
        placeHolder: 'Orderable'
    }];

export const UpdateProductConstraints: GenericFormType[] = [
    {
        type: 'control', name: 'name', label: 'Name (required)', inputType: 'text',
        placeHolder: 'Name', validators: [Validators.required]
    },
    {
        type: 'control', name: 'category', label: 'Category (required)', inputType: 'text',
        placeHolder: 'Category', readonly: true
    },
    {
        type: 'control', name: 'vendor', label: 'Vendor', inputType: 'text',
        placeHolder: 'No Vendor Selected', readonly: true
    },
    {
        type: 'control', name: 'description', label: 'Short Description (required)', inputType: 'textarea',
        placeHolder: 'Description', validators: [Validators.required]
    },
    {
        type: 'control', name: 'longDescription', label: 'Full Description', inputType: 'textarea',
        placeHolder: 'Full Description'
    },
    {
        type: 'control', name: 'orderable', label: 'Orderable', inputType: 'checkbox',
        placeHolder: 'Orderable'
    }];

export const TourDataConstraints: GenericFormType[] = [{
    type: 'control', name: 'type', label: 'Type (required)', inputType: 'dropdown',
    lookups: [], validators: [Validators.required]
},
{
    type: 'control', name: 'language', label: 'Language (required)', inputType: 'dropdown',
    lookups: [], validators: [Validators.required]
},
{
    type: 'control', name: 'durationType', label: 'Duration (required)', inputType: 'text'
},
{
    type: 'control', name: 'numberOfSeats', label: 'Capacity', inputType: 'text'
},
{
    type: 'control', name: 'monday', label: 'Monday', inputType: 'checkbox'
},
{
    type: 'control', name: 'tuesday', label: 'Tuesday', inputType: 'checkbox'
},
{
    type: 'control', name: 'wednesday', label: 'Wednesday', inputType: 'checkbox'
},
{
    type: 'control', name: 'thursday', label: 'Thursday', inputType: 'checkbox'
},
{
    type: 'control', name: 'friday', label: 'Friday', inputType: 'checkbox'
},
{
    type: 'control', name: 'saturday', label: 'Saturday', inputType: 'checkbox'
},
{
    type: 'control', name: 'sunday', label: 'Sunday', inputType: 'checkbox'
},
{
    type: 'control', name: 'hoursRange', label: 'Hours Range (from - to)', inputType: 'text', placeHolder: 'hh - hh'
},
{
    type: 'control', name: 'startDate', label: 'Start Date', inputType: 'text',
    placeHolder: 'DD.MM.YYYY. hh:mm'
}];

export const GigDataConstraints: GenericFormType[] = [{
    type: 'control', name: 'musicType', label: 'Music Type', inputType: 'dropdown',
    lookups: []
}, {
    type: 'control', name: 'performer', label: 'Performer', inputType: 'text',
    placeHolder: 'Performer/Band'
}, {
    type: 'control', name: 'monday', label: 'Monday', inputType: 'checkbox'
},
{
    type: 'control', name: 'tuesday', label: 'Tuesday', inputType: 'checkbox'
},
{
    type: 'control', name: 'wednesday', label: 'Wednesday', inputType: 'checkbox'
},
{
    type: 'control', name: 'thursday', label: 'Thursday', inputType: 'checkbox'
},
{
    type: 'control', name: 'friday', label: 'Friday', inputType: 'checkbox'
},
{
    type: 'control', name: 'saturday', label: 'Saturday', inputType: 'checkbox'
},
{
    type: 'control', name: 'sunday', label: 'Sunday', inputType: 'checkbox'
}, {
    type: 'control', name: 'startTime', label: 'Start Time (required)', inputType: 'text',
    placeHolder: 'hh:mm', validators: [Validators.required]
}, {
    type: 'control', name: 'endTime', label: 'End Time (required)', inputType: 'text',
    placeHolder: 'hh:mm', validators: [Validators.required]
}, {
    type: 'control', name: 'date', label: 'Date', inputType: 'text',
    placeHolder: 'DD.MM.YYYY.'
}];

export const ProductAddonsConstraints: GenericFormType[] = [{
    type: 'control', name: 'name', label: 'Name (required)', inputType: 'text',
    placeHolder: 'Name', validators: [Validators.required]
}, {
    type: 'control', name: 'description', label: 'Description (required)', inputType: 'text',
    placeHolder: 'Description', validators: [Validators.required]
}, {
    type: 'control', name: 'price', label: 'Price', inputType: 'text',
    placeHolder: 'Price'
}];
