import { Injectable } from "@angular/core";
import { AutomapperService } from "../core/automapper.service";
import { IMapperRegistry } from "./mapper.registry";
import * as moment from "moment";
import { ChargeTypes } from "../venue/venue-charges/venue-charges.constraints";

@Injectable()
export class VenueChargesMapper implements IMapperRegistry {
    constructor(private automapper: AutomapperService) { }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap("VenueChargeApi", "VenueChargeModel")
                .forMember("createdAt", (opts) => moment.tz(opts.sourceObject.createdAt, "America/New_York").format("YYYY-MM-DD ( a hh:mm )"))
                .forMember("chargeDate", (opts) => !!opts.sourceObject.chargeDate ? moment.tz(opts.sourceObject.chargeDate, "America/New_York").format("MMMM, YYYY") : "")
                .forMember("chargeType", (opts) => !!opts.sourceObject.chargeType ? ChargeTypes.find((x) => x.value === opts.sourceObject.chargeType)["label"] : "")
                .forMember("amount", (opts) => opts.sourceObject.price.value)
                .forMember("description", (opts) => opts.sourceObject.description || "")
        })
    }
}
