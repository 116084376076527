import { Injectable } from '@angular/core';
import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../core/automapper.service';
import { UserApiRoles, UserModelRoles } from '../shared/user';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { environment } from '../../environments/environment';

@Injectable()
export class VenueMapper implements IMapperRegistry {

  constructor(private automapper: AutomapperService) {
  }

  register() {

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('VenueApi', 'VenueModel')
        .forMember('id', (opts) => opts.sourceObject.id)
        .forMember('mainImage', (opts) => opts.sourceObject.mainImage)
        .forMember('coverImage', (opts) => opts.sourceObject.coverImage)
        .forMember('public', (opts) => {
          switch (opts.sourceObject.public) {
            case true: return 'YES';
            case false: return 'NO';
            default: return 'UNKNOWN_VALUE';
          }
        })
        .forMember('name', (opts) => opts.sourceObject.name)
        .forMember('email', (opts) => opts.sourceObject.email)
        .forMember('slug', (opts) => opts.sourceObject.slug)
        .forMember('description', (opts) => opts.sourceObject.description)
        .forMember('phoneNumber', (opts) => opts.sourceObject.phoneNumber)
        .forMember('location', (opts) => opts.sourceObject.location)
        .forMember('siteUrl', (opts) => opts.sourceObject.siteUrl)
        .forMember('neighborhood', (opts) => opts.sourceObject.neighborhood)
        .forMember('gallery', (opts) => opts.sourceObject.gallery)
        .forMember('workingHours', (opts) => opts.sourceObject.workingHours)
        .forMember('features', (opts) => opts.sourceObject.features)
        .forMember('categories', (opts) => opts.sourceObject.categories)
        .forMember('attire', (opts) => opts.sourceObject.attire)
        .forMember('ambience', (opts) => opts.sourceObject.ambience)
        .forMember('priceRating', (opts) => opts.sourceObject.priceRating)
        .forMember('ownerId', (opts) => opts.sourceObject.ownerId)
        .forMember('noiseLevel', (opts) => opts.sourceObject.noiseLevel)
        .forMember('tip', (opts) => opts.sourceObject.tip)
        .forMember('geoJSON', (opts) => opts.sourceObject.geoJSON)
        .forMember('seatingCapacity', (opts) => opts.sourceObject.seatingCapacity)
        .forMember('userIds', (opts) => opts.sourceObject.userIds)
        .forMember('companyName', (opts) => opts.sourceObject.companyName)
        // .forMember('eventAutoApproval', (opts) => {
        //   switch (opts.sourceObject.eventAutoApproval) {
        //     case true: return 'YES';
        //     case false: return 'NO';
        //     default: return 'UNKNOWN_VALUE';
        //   }
        // })
        .forMember('autoActivate', (opts) => {
          switch (opts.sourceObject.autoActivate) {
            case true: return 'YES';
            case false: return 'NO';
            default: return 'UNKNOWN_VALUE';
          }
        })
        .forMember('createdAt', (opts) => momentTZ(opts.sourceObject.createdAt).tz('America/New_York').format(environment.datTimeFormat))
        .forMember('distance', (opts) => opts.sourceObject.distance)
        .forMember('active', (opts) => {
          switch (opts.sourceObject.active) {
            case true: return 'ACTIVE';
            case false: return 'INACTIVE';
            default: return 'UNKNOWN_ACTIVE_STATUS';
          }
        })
        .forMember('isClaimed', (opts) => {
          if (opts.sourceObject.ownerId) {
            return 'Yes';
          }
          return 'No';
        })
        .forMember('address', (opts) => opts.sourceObject.location.address ? opts.sourceObject.location.address : '')
        .forMember('isBookingEnabled', (opts) => {
          switch (opts.sourceObject.isBookingEnabled) {
            case true: return 'YES';
            case false: return 'NO';
            default: return 'UNKNOWN_VALUE';
          }
        })
        .forMember('isTakeoutEnabled', (opts) => {
          switch (opts.sourceObject.isTakeoutEnabled) {
            case true: return 'YES';
            case false: return 'NO';
            default: return 'UNKNOWN_VALUE';
          }
        })
        .forMember("studentDiscount", (opts) => opts.sourceObject.studentDiscount ? "YES" : "NO")
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('VenueModel', 'VenueApi')
        .forMember('id', (opts) => opts.sourceObject.id)
        .forMember('mainImage', (opts) => opts.sourceObject.mainImage)
        .forMember('coverImage', (opts) => opts.sourceObject.coverImage)
        .forMember('public', (opts) => {
          switch (opts.sourceObject.public) {
            case 'YES': return true;
            case 'NO': return false;
            default: return false;
          }
        })
        .forMember('name', (opts) => opts.sourceObject.name)
        .forMember('email', (opts) => opts.sourceObject.email)
        .forMember('slug', (opts) => opts.sourceObject.slug)
        .forMember('description', (opts) => opts.sourceObject.description)
        .forMember('phoneNumber', (opts) => opts.sourceObject.phoneNumber)
        .forMember('location', (opts) => opts.sourceObject.location)
        .forMember('siteUrl', (opts) => opts.sourceObject.siteUrl)
        .forMember('neighborhood', (opts) => opts.sourceObject.neighborhood)
        .forMember('gallery', (opts) => opts.sourceObject.gallery)
        .forMember('workingHours', (opts) => opts.sourceObject.workingHours)
        .forMember('features', (opts) => opts.sourceObject.features)
        .forMember('categories', (opts) => opts.sourceObject.categories)
        .forMember('attire', (opts) => opts.sourceObject.attire)
        .forMember('ambience', (opts) => opts.sourceObject.ambience)
        .forMember('priceRating', (opts) => opts.sourceObject.priceRating)
        .forMember('ownerId', (opts) => opts.sourceObject.ownerId)
        .forMember('noiseLevel', (opts) => opts.sourceObject.noiseLevel)
        .forMember('tip', (opts) => opts.sourceObject.tip)
        .forMember('geoJSON', (opts) => opts.sourceObject.geoJSON)
        .forMember('seatingCapacity', (opts) => opts.sourceObject.seatingCapacity)
        .forMember('userIds', (opts) => opts.sourceObject.userIds)
        .forMember('companyName', (opts) => opts.sourceObject.companyName)
        // .forMember('eventAutoApproval', (opts) => {
        //   switch (opts.sourceObject.eventAutoApproval) {
        //     case 'YES': return true;
        //     case 'NO': return false;
        //     default: return false;
        //   }
        // })
        .forMember('autoActivate', (opts) => {
          switch (opts.sourceObject.autoActivate) {
            case 'YES': return true;
            case 'NO': return false;
            default: return false;
          }
        })
        .forMember('isBookingEnabled', (opts) => {
          switch (opts.sourceObject.isBookingEnabled) {
            case 'YES': return true;
            case 'NO': return false;
            default: return false;
          }
        })
        .forMember('isTakeoutEnabled', (opts) => {
          switch (opts.sourceObject.isTakeoutEnabled) {
            case 'YES': return true;
            case 'NO': return false;
            default: return false;
          }
        })
        .forMember('active', (opts) => {
          switch (opts.sourceObject.active) {
            case 'ACTIVE': return true;
            case 'INACTIVE': return false;
            default: return true;
          }
        })
        .forMember("studentDiscount", (opts) => {
          switch (opts.sourceObject.studentDiscount) {
            case 'YES': return true;
            case 'NO': return false;
            default: return false;
          }
        })
        .ignoreAllNonExisting();
    });
  }
}
