import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { GenericFormArray, GenericFormControl, GenericFormGroup } from "./generic-form.component";

@Component({
    selector: 'app-generic-array',
    templateUrl: './generic-array.component.html'
})
export class GenericArrayComponent {
    @Input('field') field;

    @Input('formGroup') formGroup;

    @Output('dataChange') dataChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private fb: FormBuilder) { }

    addNewGroup(array: GenericFormArray) {
        const arrayForm: FormArray = this.formGroup as FormArray;
        const newGroup: FormGroup = this.fb.group({});
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < array.groups[0].controls.length; i++) {
            const ctrl = array.groups[0].controls[i];
            if (ctrl.type === 'array') {
                newGroup.addControl(ctrl.name, this.initArray(ctrl));
                continue;
            }
            newGroup.addControl(ctrl.name, this.initControl(ctrl));
        }
        arrayForm.push(newGroup);
        const grpoupsClone = array.groups.slice(0);
        array.groups.push(grpoupsClone[0]);
    }

    removeGroup(array: GenericFormArray, index: number) {
        const arrayForm: FormArray = this.formGroup as FormArray;
        arrayForm.removeAt(index);
        array.groups.splice(index, 1);
    }

    private initControl(control: GenericFormControl): FormControl {
        return this.fb.control(control.value, control.validators);
    }
    private initGroup(group: GenericFormGroup): FormGroup {
        const newFormGroup = this.fb.group({});
        for (const control of group.controls) {
            if (control.type === "array") {
                newFormGroup.addControl(control.name, this.initArray(control));
                continue;
            }
            newFormGroup.addControl(control.name, new FormControl(control.value ? control.value : '', control.validators));
        }
        return newFormGroup;
    }

    private initArray(array: GenericFormArray): FormArray {
        const newFormArray = this.fb.array([]);
        const groupNames = [];
        for (const group of array.groups) {
            if (groupNames.indexOf(group.name) !== -1) {
                continue;
            }
            newFormArray.push(this.initGroup(group));
            groupNames.push(group.name);
        }
        return newFormArray;
    }

    toRaw(): any {
        const raw = {};
        Object.keys(this.formGroup.controls).forEach(key => {
            const value = this.formGroup.get(key).value;
            raw[key] = value;
        });
        return raw;
    }

    formChanged() {
        const rawValues = this.toRaw();
        this.dataChange.emit(rawValues)
    }
}