import { Injectable } from '@angular/core';
import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../core/automapper.service';
import { UserApiRoles, UserModelRoles } from '../shared/user';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { environment } from '../../environments/environment';

@Injectable()
export class UserMapper implements IMapperRegistry {

  constructor(private automapper: AutomapperService) {
  }

  register() {

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('UserApi', 'UserModel')
        .forMember('firstName', (opts) => opts.sourceObject.firstName)
        .forMember('lastName', (opts) => opts.sourceObject.lastName)
        .forMember('fullName', (opts) => opts.sourceObject.firstName + ' ' + opts.sourceObject.lastName)
        .forMember('email', (opts) => opts.sourceObject.email)
        .forMember('username', (opts) => opts.sourceObject.username)
        .forMember('role', (opts) => opts.sourceObject.role)
        .forMember('organizationId', (opts) => opts.sourceObject.organizationId)
        .forMember('phone', (opts) => opts.sourceObject.phone)
        .forMember('phoneNumberVerified', (opts) => opts.sourceObject.phoneNumberVerified ? 'yes' : 'no')
        .forMember('credit', (opts) => opts.sourceObject.credit)
        // tslint:disable-next-line:max-line-length
        .forMember('birthDay', (opts) => opts.sourceObject.birthDay ? moment(opts.sourceObject.birthDay).format(environment.dateFormat) : '')
        .forMember('ssn', (opts) => opts.sourceObject.ssn)
        .forMember('venueIds', (opts) => opts.sourceObject.venueIds)
        .forMember('pushTokens', (opts) => opts.sourceObject.pushTokens)
        .forMember('favouriteRestaurants', (opts) => opts.sourceObject.favouriteRestaurants)
        .forMember('favouriteEvents', (opts) => opts.sourceObject.favouriteEvents)
        .forMember('imageUrl', (opts) => opts.sourceObject.imageUrl)
        .forMember('homeNeighborhood', (opts) => opts.sourceObject.homeNeighborhood)
        .forMember('workNeighborhood', (opts) => opts.sourceObject.workNeighborhood)
        .forMember('interests', (opts) => opts.sourceObject.interests)
        .forMember('gender', (opts) => opts.sourceObject.gender)
        .forMember('relationshipStatus', (opts) => opts.sourceObject.relationshipStatus)
        .forMember('occupation', (opts) => opts.sourceObject.occupation)
        .forMember('active', (opts) => {
          switch (opts.sourceObject.active) {
            case true: return 'Visible';
            case false: return 'Hidden';
            default: return 'UNKNOWN_ACTIVE_STATUS';
          }
        })
        .forMember('_title', (opts) => {
          return `${opts.sourceObject.firstName} ${opts.sourceObject.lastName}`;
        })
        .forMember('id', (opts) => opts.sourceObject.id)
        .forMember('createdAt', (opts) => {
          return momentTZ(opts.sourceObject.createdAt).tz('America/New_York').format('YYYY-MM-DD HH:mm (a hh:mm)');
        })
        .forMember('referredBy', (opts) => opts.sourceObject.referredBy ? opts.sourceObject.referredBy : 'None')
        .forMember('isStudent', (opts) => opts.sourceObject.isStudent ? "YES" : "NO")
        .forMember("studentEmailVerified", (opts) => opts.sourceObject.studentEmailVerified)
        .forMember("banReason", (opts) => opts.sourceObject.banReason)
        .forMember("banDate", (opts) => opts.sourceObject.banDate)
        .forMember("deletedDate", (opts) => opts.sourceObject.deletedDate)
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('UserModel', 'UserApi')
        .forMember('firstName', (opts) => opts.sourceObject.firstName)
        .forMember('lastName', (opts) => opts.sourceObject.lastName)
        .forMember('email', (opts) => opts.sourceObject.email)
        .forMember('username', (opts) => opts.sourceObject.username)
        .forMember('role', (opts) => opts.sourceObject.role)
        .forMember('organizationId', (opts) => opts.sourceObject.organizationId)
        .forMember('phone', (opts) => opts.sourceObject.phone)
        .forMember('phoneNumberVerified', (opts) => opts.sourceObject.phoneNumberVerified === 'yes' ? true : false)
        .forMember('credit', (opts) => opts.sourceObject.credit)
        .forMember('birthDay', (opts) => moment(opts.sourceObject.birthDay))
        .forMember('ssn', (opts) => opts.sourceObject.ssn)
        .forMember('venueIds', (opts) => opts.sourceObject.venueIds)
        .forMember('pushTokens', (opts) => opts.sourceObject.pushTokens)
        .forMember('favouriteRestaurants', (opts) => opts.sourceObject.favouriteRestaurants)
        .forMember('favouriteEvents', (opts) => opts.sourceObject.favouriteEvents)
        .forMember('imageUrl', (opts) => opts.sourceObject.imageUrl)
        .forMember('homeNeighborhood', (opts) => opts.sourceObject.homeNeighborhood)
        .forMember('workNeighborhood', (opts) => opts.sourceObject.workNeighborhood)
        .forMember('interests', (opts) => opts.sourceObject.interests)
        .forMember('gender', (opts) => opts.sourceObject.gender)
        .forMember('relationshipStatus', (opts) => opts.sourceObject.relationshipStatus)
        .forMember('occupation', (opts) => opts.sourceObject.occupation)
        .forMember('referredBy', (opts) => opts.sourceObject.referredBy ? opts.sourceObject.referredBy : 'None')
        .forMember('active', (opts) => {
          switch (opts.sourceObject.active) {
            case 'Visible': return true;
            case 'Hidden': return false;
            default: return true;
          }
        })
        .forMember('id', (opts) => opts.sourceObject.id)
        .forMember('isStudent', (opts) => opts.sourceObject.isStudent === "YES" ? true : false)
        .forMember("studentEmailVerified", (opts) => opts.sourceObject.studentEmailVerified)
        .ignoreAllNonExisting();
    });
  }
}
