import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "../../core/auth.service";
import { ITableColumn } from "../../entities/ITableColumn";
import { VenueService } from "../venue.service";
import { CreateVenueChargeComponent } from "./create-venue-charge.component";
import { VenueChargeDataColumns } from "./venue-charges.constraints";

@Component({
    selector: 'app-venue-charges',
    templateUrl: './venue-charges.component.html'
})
export class VenueChargesComponent implements OnInit {

    venue: any;
    charges: any[];
    datatableReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    columns: ITableColumn[] = VenueChargeDataColumns;
    dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
    };
    showCreateNewButton: boolean;
    bsModalRef: BsModalRef;

    constructor(
        private venueService: VenueService,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private authService: AuthService
    ) {
    }

    async ngOnInit() {
        const id: string = this.route.parent.snapshot.paramMap.get('id');
        this.venue = await this.venueService.getById(id);
        const user = this.authService.getCurrentUser();
        if (!!user && user.role === "ADMIN") {
            this.showCreateNewButton = true;
        }
        await this.fetchCharges();
        this.fillTableData();
    }


    async fetchCharges() {
        this.charges = await this.venueService.getVenueCharges(this.venue.id);
    }

    fillTableData() {
        setTimeout(() => {
            this.datatableReady.next(true);
        }, 500);
    }

    async createNew() {
        const initialState = {
            venue: this.venue,
            fetchCharges: this.fetchCharges.bind(this)
        };
        this.bsModalRef = this.modalService.show(CreateVenueChargeComponent, { initialState });
        this.bsModalRef.setClass('modal-lg');
    }
}