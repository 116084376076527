import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ToasterService } from 'angular2-toaster';
import { ProductsService } from '../products.service';

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html'
})
export class ProductPriceComponent implements OnInit {

  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  entity: any;
  createInProgress: boolean;

  constructor(private productService: ProductsService,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.entityReady.next(false);
    const entityId: string = this.route.parent.snapshot.paramMap.get('id');
    this.productService.getById(entityId)
      .then((entity) => {
        this.entity = entity;
        if (this.entity && !this.entity.price) {
          this.entity.price = this.getEmptyPrice();
        }
        if (this.entity && !this.entity.discount) {
          this.entity.discount = this.getEmptyDiscount();
        }
        this.entityReady.next(true);
      });
  }

  onChange(value) {
    console.log(value);
    console.log(this.entity);
  }

  async saveChanges() {
    try {
      this.createInProgress = true;
      this.entity = await this.productService.update(this.entity);
      this.toasterService.pop('success', 'Entity saved successfully');
    } catch (e) {
      console.error(e);
      this.toasterService.pop('error', 'Entity save failed');
    } finally {
      this.createInProgress = false;
    }
  }

  closePanel() {
    this.router.navigateByUrl('/vendors');
  }

  private getEmptyPrice(): any {
    return {
      value: 0,
      currency: ''
    };
  }

  private getEmptyDiscount(): any {
    return {
      value: 0,
      type: ''
    };
  }
}
