import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { GenericFormComponent, GenericFormType } from '../../generic-form/generic-form.component';
import { MenuService } from './venue-menu.service';
import { BsModalRef } from 'ngx-bootstrap';
import { GenericFormBuilder } from '../../generic-form/generic-form.builder';
import { HappyHourDayConstraints, HappyHourTimeConstraints } from '../../happy-hour/happy-hour.constraints';

@Component({
  selector: 'app-menu-category-edit',
  templateUrl: './menu-category.edit.component.html'
})
export class MenuCategoryEditComponent implements OnInit, AfterViewInit {

  @ViewChild('menuCategoryForm', { static: false }) menuCategoryForm: GenericFormComponent;

  updateInProgress: boolean;
  venue: any;
  menu: any;
  updateRules: GenericFormType[];
  loadMenus: any;
  getMenuTypes: any;
  updateEditedMenuCategory: any;
  currentType: string;

  constructor(
    private bsModalRef: BsModalRef,
    private menuService: MenuService
  ) { }

  async ngOnInit() {
    this.updateInProgress = false;
    this.updateRules = [
      GenericFormBuilder.text('name', 'Name', [], this.menu.type === 'happyHour'),
      GenericFormBuilder.number('priority', 'Priority (1 - highest prio)', []),
      GenericFormBuilder.switch('disabled', 'Hide'),
      GenericFormBuilder.array('day', 'Days', [
        GenericFormBuilder.group('startDate', null, HappyHourDayConstraints, null, 'inline')
      ]),
      GenericFormBuilder.array('time', 'Hours', [
        GenericFormBuilder.group('time', null, HappyHourTimeConstraints, null, 'inline')
      ])
    ];
  }

  ngAfterViewInit() {
    this.menuCategoryForm.fromModel<any>('MenuModel', 'MenuCategoryEditForm', this.menu);
  }

  async update() {
    this.updateInProgress = true;
    const test = this.menuCategoryForm.toObject();
    try {
      const newMenu: any = {
        venueId: this.venue.id,
        ... this.menu,
        ... this.menuCategoryForm.toModel<any>('MenuCategoryEditForm', 'MenuModel')
      };
      await this.menuService.updateMenuByType(this.venue.id, newMenu);
      this.updateEditedMenuCategory();
      this.updateInProgress = false;
      this.bsModalRef.hide();
    } catch (error) {
      this.updateInProgress = false;
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }

  dataChanged(data) {
    this.currentType = data.type;
  }

}
