import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferralCodesComponent } from './referral-codes.component';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { UserRoles } from '../users/user.roles';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LaddaModule,
    GenericFormModule,
    FormsModule,
    RouterModule.forChild([{
      path: 'referral-code',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/referral-code',
          icon: 'glyphicon-tag',
          label: 'Referral codes',
          category: 'referral codes',
          index: 10
        },
        access: [UserRoles.ADMIN]
      },
      children: [
        {
          path: '',
          component: ReferralCodesComponent,
          canActivate: [AuthenticatedGuard],
          data: { title: 'Referral codes', category: 'referral codes',  }
        },
      ]
    }])
  ],
  declarations: [ReferralCodesComponent],
})
export class ReferralCodesModule { }
