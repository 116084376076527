import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class ApiService {

  private host: string;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.host = environment.bdmApi.host;
  }

  get(url: string, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    httpHeaders = this.setAuthToken(httpHeaders);
    return new Promise<any>((resolve, reject) => {
      this.http.get(this.host + url, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error.error);
        });
    });
  }

  post(url: string, data: any, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    httpHeaders = this.setAuthToken(httpHeaders);
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.host + url, data, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error.error);
        });
    });
  }

  put(url: string, data: any, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    httpHeaders = this.setAuthToken(httpHeaders);
    return new Promise<any>((resolve, reject) => {
      this.http.put(this.host + url, data, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error.error);
        });
    });
  }

  delete(url: string, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    httpHeaders = this.setAuthToken(httpHeaders);
    return new Promise<any>((resolve, reject) => {
      this.http.delete(this.host + url, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error.error);
        });
    });
  }

  private setAuthToken(httpHeaders: HttpHeaders): HttpHeaders {
    if (this.authService.isLoggedIn()) {
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${this.authService.user.token}`);
    }
    return httpHeaders;
  }
}
