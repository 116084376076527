import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromoCodesComponent } from './promo-codes.component';
import { PromoCodesService } from './promo-codes.service';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UserRoles } from '../users/user.roles';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { LayoutComponent } from '../layout/layout.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [PromoCodesComponent],
  providers: [PromoCodesService],
  imports: [
    CommonModule,
    SharedModule,
    LaddaModule,
    GenericFormModule,
    FormsModule,
    RouterModule.forChild([{
      path: 'promo-codes',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/promo-codes',
          icon: 'glyphicon-barcode',
          label: 'Promo codes',
          category: 'promo codes',
          index: 9
        },
        access: [UserRoles.ADMIN]
      },
      children: [
        {
          path: '',
          component: PromoCodesComponent,
          canActivate: [AuthenticatedGuard],
          data: { title: 'Promo codes', category: 'promo codes',  }
        },
      ]
    }])
  ]
})
export class PromoCodesModule { }
