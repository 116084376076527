import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BansService } from './bans.service';
import { IEntityService } from '../entities/entity.service';

@Injectable()
export class BansServiceResolver implements Resolve<IEntityService> {
    constructor(private bansService: BansService) { }

    resolve(): IEntityService {
        return this.bansService;
    }
}
