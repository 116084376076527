import { Component, ElementRef, HostListener, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { IEntityService } from '../entity.service';
import { BehaviorSubject } from 'rxjs';
import { ITableColumn } from '../ITableColumn';

@Component({
  selector: 'app-entities-list',
  templateUrl: './entity-list.component.html'
})
export class EntityListComponent implements OnInit {
  @ViewChild('keywordInput', {static: false}) keywordInput: ElementRef;
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;

  entities: any[] = [];
  columns: ITableColumn[];
  tableOptions: any;
  customOrder: any;
  searchForm: FormGroup;
  category: string;

  service: IEntityService;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  datatableReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  displayFilters = true;
  filterDates: any = {};
  showCreateButton: boolean;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router) {
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });
    this.searchForm = this.fb.group({
      keyword: this.fb.control('')
    });
  }

  async ngOnInit() {
    this.service = this.route.snapshot.data.service;
    this.checkAppearanceOfCreateButton();
    this.loadEntitiesData();
  }

  @HostListener('window:keyup', ['$event'])
  focusSearchEvent(event: KeyboardEvent) {
    if (event.keyCode === 191) {
      this.keywordInput.nativeElement.focus();
    }
  }

  search() {
    const keyword = this.searchForm.get('keyword').value.toLowerCase();

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(keyword);
      dtInstance.draw();
    });
  }

  createNew() {
    this.router.navigateByUrl(`${this.service.getName()}/new`);
  }

  togglFilters() {
    this.displayFilters = !this.displayFilters;
  }

  private loadEntitiesData() {
    this.category = this.service.getName();
    this.columns = this.service.getTableColumns();
    this.tableOptions = this.service.getTableOptions();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'tipBl',
      buttons: [
        {
          extend: 'csv',
          text: '<i class="glyphicon glyphicon-download-alt"></i>',
        }
      ],
      ...this.tableOptions
    };

    this.service.getAll(this.route.snapshot.paramMap)
      .then((result) => {
        this.entities = result;
        const self = this;
        setTimeout(() => {
          self.render();
        }, 0);
      });
  }

  private render() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        this.setDatatableReady();
      });
    } else {
      this.dtTrigger.next();
      this.setDatatableReady();
    }
  }

  private setDatatableReady() {
    setTimeout(() => {
      this.datatableReady.next(true);
      this.displayFilters = false;
    }, 500);
  }

  private checkAppearanceOfCreateButton(): void {
    this.showCreateButton = true;
    this.route.data.subscribe(data => {
      if (typeof data.disableCreateEntity === 'boolean') {
        this.showCreateButton = !data.disableCreateEntity;
      }
    });
  }
}
