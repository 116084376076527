import { Injectable } from "@angular/core";
import { ApiService } from "../core/api.service";
import * as moment from "moment";

@Injectable()
export class SummeriesService {
    constructor(private ApiService: ApiService) { }

    async generateVenueMonthlySummary(venueIds: Array<any>, endDate: string): Promise<any> {
        return await this.ApiService.post(`/venues/monthly-summary`, { venueIds, endDate }, []);
    }

    async generateOrganizerMonthlySummary(userIds: Array<any>, endDate: string): Promise<any> {
        return await this.ApiService.post(`/organizers/monthly-summary`, { userIds, endDate }, []);
    }

    async generateCuculiMonthlySummary(endDate: string): Promise<any> {
        return await this.ApiService.post(`/cuculi/monthly-summary`, { endDate }, []);
    }

    async generateUserSummary(startDate: string, endDate?: string): Promise<any> {
        return await this.ApiService.post(`/users/summary`, { startDate, endDate }, []);
    }

    venueMappedLine(line: any) {
        return [
            line.live,
            line.venueName,
            line.lastBookingDate !== "N/A" ? this.formatDate(line.lastBookingDate) : line.lastBookingDate,
            line.MTDBookings,
            line.monthBookings,
            line.YTDBookings,
            line.MTDTicketRevenue.toFixed(2),
            line.MTDOrderRevenue.toFixed(2),
            line.MTDTotalRevenue.toFixed(2),
            line.monthTicketRevenue.toFixed(2),
            line.monthOrderRevenue.toFixed(2),
            line.monthTotalRevenue.toFixed(2),
            line.YTDTicketRevenue.toFixed(2),
            line.YTDOrderRevenue.toFixed(2),
            line.YTDTotalRevenue.toFixed(2),
            line.MTDRewardsPaid.toFixed(2),
            line.monthRewardsPaid.toFixed(2),
            line.YTDRewardsPaid.toFixed(2),
            line.contactPerson1,
            line.contactPerson2,
            line.email,
            line.mainPhone,
            line.mobilePhone,
            this.formatDate(line.onboardDate),
        ];
    }

    organizerMappedLine(line: any) {
        return [
            line.userName,
            line.MTDEventsOrganized,
            line.monthEventsOrganized,
            line.MTDFirstTimeUsersAquired,
            line.monthFirstTimeUsersAquired,
            line.MTDRepeatUsersAquired,
            line.monthRepeatUsersAquired,
            line.email,
            line.phone,
        ];
    }

    cuculiMappedLine(line: any) {
        return [
            line.venueName,
            line.monthSubscriptionPaid.toFixed(2),
            line.YTDSubscriptionPaid.toFixed(2),
            line.monthCommissionPaid.toFixed(2),
            line.YTDCommissionPaid.toFixed(2),
        ];
    }

    userMappedLine(line: any) {
        return [
            line.firstName,
            line.lastName,
            line.lastEventJoined !== "N/A" ? this.formatDate(line.lastEventJoined) : line.lastEventJoined,
            line.lastEventOrganized !== "N/A" ? this.formatDate(line.lastEventOrganized) : line.lastEventOrganized,
            line.eventsOrganized,
            line.eventsJoined,
            line.avgSpendPerEvent.toFixed(2),
            line.avgTipPerEvent.toFixed(0),
            Math.floor(line.cuculiPointsBalance),
            Math.floor(line.cuculiPointsSpent),
            line.groupsJoined,
            line.hasProfilePhoto,
            this.formatDate(line.createdAt),
            line.email,
            line.phone,
        ]
    }

    formatDate(date?: string, format?: string) {
        if (date) {
            return moment(date).tz("America/New_York").format(format ? format : "YYYY-MM-DD");
        }
        return moment.tz("America/New_York").format(format ? format : "YYYY-MM-DD");
    }
}
