import { GenericFormType } from '../generic-form/generic-form.component';
import { GenericFormBuilder } from '../generic-form/generic-form.builder';

export const UserReportUpdateConstraints: GenericFormType[] = [
  GenericFormBuilder.text('id', 'Id', [], true),
  GenericFormBuilder.text('reporterName', 'Reporter Name', [], true),
  GenericFormBuilder.text('reporterEmail', 'Reporter Email', [], true),
  GenericFormBuilder.text('reportedEmail', 'Reported Email', [], true),
  GenericFormBuilder.text('reportedName', 'Reported Name', [], true),
  GenericFormBuilder.text('description', 'Description', [], true),
  GenericFormBuilder.text('type', 'Type', [], true),
  GenericFormBuilder.text('targetId', 'Target Id', [], true),
  GenericFormBuilder.text('reporterUserId', 'reporterUserId', [], true),
  GenericFormBuilder.text('createdAt', 'Created At', [], true),
  GenericFormBuilder.text('statusDescription', 'Status Description', []),
  GenericFormBuilder.dropdown('reportStatus', 'Report Status', [], [
    { value: 'open', label: 'Open', selected: true },
    { value: 'closed', label: 'Closed' }
  ]),
];
