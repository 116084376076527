import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { GenericFormType, GenericFormComponent } from '../../generic-form/generic-form.component';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { UtilityService } from '../../core/utility.service';
import { ProductsService } from '../products.service';
import { TourDataConstraints, GigDataConstraints } from '../products.constraints';
import { EnumService } from '../../enums/enums.service';
import { DaysCalendarModel } from 'ngx-bootstrap/datepicker/models';

@Component({
    selector: 'app-product-data',
    templateUrl: './product-data.component.html'
})
export class ProductDataComponent implements OnInit {

    @ViewChild('genericForm', {static: false}) genericForm: GenericFormComponent;

    private tourDataRules: GenericFormType[] = [];
    private gigDataRules: GenericFormType[] = [];
    private days = ['monday', 'tuesday', 'wednesday', 'thursday', 'wednesday', 'friday', 'saturday', 'sunday'];

    entity: any;
    createInProgress: boolean;
    category: string;
    dataRules: GenericFormType[] = [];
    entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private route: ActivatedRoute,
        private toasterService: ToasterService,
        private location: Location,
        private utilityService: UtilityService,
        private entityService: ProductsService,
        private enumService: EnumService) {
        this.prepareTourDataRules();
        this.prepareEventDataRules();
    }

    async ngOnInit() {
        this.category = this.entityService.getName();
        // this.entity = {};
        this.reloadEntity()
            .then(() => {
                setTimeout(() => {
                    this.initForm();
                }, 100);
            });
    }

    initForm() {
        if (!this.entity.data) {
            return;
        }
        for (const key in this.entity.data) {
            if (this.entity.data.hasOwnProperty(key)) {
                const control = this.genericForm.myForm.get(key);
                if (control) {
                    control.setValue(this.entity.data[key]);
                }
            }
        }
        this.initDays(this.entity.data, this.genericForm.myForm);
    }

    async createEntity() {
        if (!this.validateEntity()) {
            console.log('form invalid');
            return;
        }

        console.log('form valid');

        try {
            this.createInProgress = true;
            this.entity.data = this.utilityService.fillEntity(this.genericForm.myForm, this.dataRules, this.entity.data);
            this.fillInDays(this.entity.data);
            await this.entityService.update(this.entity);
            this.reloadEntity();
            this.toasterService.pop('success', 'Entity updated');
        } catch (e) {
            console.error(e);
            this.toasterService.pop('error', 'Entity update failed.');
        } finally {
            this.createInProgress = false;
        }
    }

    onChange(e) {
        console.log(e);
    }

    reloadEntity() {
        const productId = this.route.parent.snapshot.paramMap.get('id');
        return this.entityService.getById(productId)
            .then((p) => {
                this.entity = p;
                if (this.entity.category === 'tour') {
                    this.dataRules = this.tourDataRules;
                } else {
                    this.dataRules = this.gigDataRules;
                }
                if (!this.entity.data) {
                    this.entity.data = {};
                }
                // this.entity.data = this.utilityService.fillEntity(this.genericForm.myForm, this.dataRules, this.entity.data);
                this.entityReady.next(true);
            });
    }

    closePanel() {
        // this.router.navigateByUrl('/' + this.category);
        this.location.back();
    }

    private validateEntity() {
        this.genericForm.myForm.markAsDirty();
        return this.genericForm.myForm.valid;
    }

    private prepareTourDataRules() {
        this.tourDataRules = TourDataConstraints;
        let tourTypesRes = null;
        let languagesRes = null;
        this.enumService.getByType('tour-types')
            .then((tourTypes) => {
                tourTypesRes = tourTypes;
            })
            .then(() => {
                return this.enumService.getByType('language-options');
            })
            .then((language) => {
                languagesRes = language;
            })
            .then(() => {
                const typeNode = this.tourDataRules.find((c) => c.name === 'type');
                if (typeNode) {
                    (typeNode as any).lookups = tourTypesRes.map((c, index) => {
                        return {
                            value: c.key,
                            label: c.value,
                            selected: false
                        };
                    });
                    (typeNode as any).lookups.unshift({
                        value: null,
                        label: '',
                        selected: true
                    });
                }
                const languageNode = this.tourDataRules.find((c) => c.name === 'language');
                if (languageNode) {
                    (languageNode as any).lookups = languagesRes.map((c, index) => {
                        return {
                            value: c.key,
                            label: c.value,
                            selected: false
                        };
                    });
                    (languageNode as any).lookups.unshift({
                        value: null,
                        label: '',
                        selected: true
                    });
                }
            });
    }

    private prepareEventDataRules() {
        this.gigDataRules = GigDataConstraints;
        let musicTypes = null;
        this.enumService.getByType('music-types')
            .then((mt) => {
                musicTypes = mt;
            })
            .then(() => {
                const musicNode = this.gigDataRules.find((c) => c.name === 'musicType');
                if (musicNode) {
                    (musicNode as any).lookups = musicTypes.map((c, index) => {
                        return {
                            value: c.key,
                            label: c.value,
                            selected: false
                        };
                    });
                    (musicNode as any).lookups.unshift({
                        value: null,
                        label: '',
                        selected: true
                    });
                }
            });
    }

    private fillInDays(entity) {
        entity.days = [];
        for (let i = 0; i < this.days.length; i++) {
            if (entity[this.days[i]]) {
                entity.days.push(this.days[i]);
            }
            try {
                delete entity[this.days[i]];
            } catch (err) {}
        }
    }

    private initDays(entity, form) {
        if (!entity.days || !entity.days.length) {
            return;
        }
        for (let i = 0; i < entity.days.length; i++) {
            const control = form.get(entity.days[i]);
            if (control) {
                control.setValue(true);
            }
        }
    }
}
