
import { mergeMap, map, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, RoutesRecognized, NavigationEnd, ActivatedRoute } from '@angular/router';
import { EventService } from '../event.service';
import { UserService } from '../../users/user.service';
import * as momentTZ from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { IInvoice } from '../../types';

@Component({
  selector: 'app-event-orders',
  templateUrl: './event-orders.component.html'
})
export class EventOrdersComponent implements OnInit {

  invoice: IInvoice;
  invoiceReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private eventService: EventService,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const eventId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      let invoice: IInvoice = await this.eventService.getEventInvoice(eventId);
      invoice.items.sort((a, b) => {
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;
        return 0;
      });
      this.invoice = invoice;
    } catch (err) {
      console.log(err);
    }
    this.invoiceReady.next(true);
  }

  formatItemCreatedAt(createdAt: string) {
    const time = momentTZ(createdAt).tz('America/New_York').format(environment.datTimeFormat);
    return time;
  }
}
