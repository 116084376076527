import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ReportService } from './report.service';
import { IEntityService } from '../entities/entity.service';

@Injectable()
export class ReportServiceResolver implements Resolve<IEntityService> {
  constructor(private reportService: ReportService) {}

  resolve(): IEntityService {
    return this.reportService;
  }
}
