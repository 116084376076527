import { NgModule } from '@angular/core';
import { FileUploadComponent } from '../file.upload/file.upload.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { LaddaModule } from 'angular2-ladda';

@NgModule({
    declarations: [
        FileUploadComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GenericFormModule,
        LaddaModule
    ],
    providers: [],
    exports: [FileUploadComponent]
})
export class FileUploadModule { }
