import { GenericFormType } from '../generic-form/generic-form.component';
import { Validators } from '@angular/forms';
import { GenericFormBuilder } from '../generic-form/generic-form.builder';
import { WeekDaysTwo } from '../shared/week.days';
import { HourSlots, HourSlotsTwo } from '../shared/hour.slots';

export const VenueCreateConstraints: GenericFormType[] = [
];

export const VenueUpdateConstraints: GenericFormType[] = [
  GenericFormBuilder.text('name', 'Name', [Validators.required]),
  GenericFormBuilder.dropdown('active', 'Visibility', [], [
    { value: 'ACTIVE', label: 'Visible', selected: true },
    { value: 'INACTIVE', label: 'Hidden' }
  ]),
  GenericFormBuilder.text('createdAt', 'Created At', [], true),
  // GenericFormBuilder.text('mainImage', 'Main Image', []),
  // GenericFormBuilder.text('coverImage', 'Cover Image', []),
  // GenericFormBuilder.dropdown('public', 'Public', [Validators.required], [
  //     { value: 'YES', label: 'Yes' },
  //     { value: 'NO', label: 'No' }
  //   ]),
  GenericFormBuilder.text('email', 'Email', []),
  // GenericFormBuilder.text('slug', 'Slug', []),
  GenericFormBuilder.text('description', 'Description', [Validators.required]),
  GenericFormBuilder.text('phoneNumber', 'Phone Number', [Validators.required]),
  // GenericFormBuilder.json('location', 'Location', [Validators.required]),
  GenericFormBuilder.text('siteUrl;', 'Site Url', []),
  GenericFormBuilder.text('neighborhood', 'Neighborhood', []),
  // GenericFormBuilder.json('gallery', 'Gallery', []),
  // GenericFormBuilder.json('workingHours', 'Working Hours', []),
  // GenericFormBuilder.json('features', 'Features', []),
  // GenericFormBuilder.json('categories', 'Categories', []),
  GenericFormBuilder.dropdown('isBookingEnabled', 'Booking Enabled', [], [
    { value: 'YES', label: 'Yes' },
    { value: 'NO', label: 'No' }
  ]),
  GenericFormBuilder.dropdown('isTakeoutEnabled', 'Takeout Enabled', [], [
    { value: 'YES', label: 'Yes' },
    { value: 'NO', label: 'No' }
  ]),
  GenericFormBuilder.text('attire', 'Attire', []),
  GenericFormBuilder.text('ambience', 'Ambience', []),
  GenericFormBuilder.text('priceRating', 'Price Rating', []),
  // GenericFormBuilder.text('ownerId', 'Owner Id', [], true),
  // GenericFormBuilder.text('noiseLevel', 'Noise Level', []),
  // GenericFormBuilder.text('tip', 'Tip', []),
  // GenericFormBuilder.json('geoJSON:', 'Geolocation-JSON', []),
  // GenericFormBuilder.text('seatingCapacity', 'Seating Capacity', []),
  // GenericFormBuilder.json('userIds', 'User Ids', []),
  // GenericFormBuilder.text('companyName', 'Company Name', []), temporary disabled
  // GenericFormBuilder.dropdown('eventAutoApproval', 'Event AutoApproval', [], [
  //   { value: 'YES', label: 'Yes' },
  //   { value: 'NO', label: 'No' }
  // ]),
  GenericFormBuilder.dropdown('autoActivate', 'Event AutoActivate', [], [
    { value: 'YES', label: 'Yes' },
    { value: 'NO', label: 'No' }
  ]),
  // GenericFormBuilder.text('distance', 'Distance', [], true),
  GenericFormBuilder.dropdown("studentDiscount", "Offers Student Discounts", [], [
    { value: "YES", label: "Yes" },
    { value: "NO", label: "No" },
  ]),
];

export const VenueSettingsConstraints = [
  GenericFormBuilder.text('surcharge', 'Surcharge (%)', [])
];

export const VenuePaymentConfigConstraints = [
  GenericFormBuilder.text('serviceFee', 'Organizer Reward (%)', [Validators.required]),
  GenericFormBuilder.dropdown('startDay', 'Start Day', [Validators.required], WeekDaysTwo),
  GenericFormBuilder.dropdown('endDay', 'End Day', [Validators.required], WeekDaysTwo),
  GenericFormBuilder.dropdown('startTime', 'Start Time', [Validators.required], HourSlots),
  GenericFormBuilder.dropdown('endTime', 'End Time', [Validators.required], HourSlotsTwo)
]

export const VenueLocationConstraints = [
  GenericFormBuilder.json('location', 'Location', [Validators.required])
];

export const VenueWorkingHoursConstraints = [
  GenericFormBuilder.json('workingHours', 'Working Hours', [])
];

export const VenueFeaturesAndCategoriesConstraints = [
  GenericFormBuilder.json('features', 'Features', []),
  GenericFormBuilder.json('categories', 'Categories', [])
];

export const VenueVouchersConstraints = [
  GenericFormBuilder.text('amount', 'Amount', [Validators.required]),
  GenericFormBuilder.dropdown('type', 'Type', [Validators.required], [
    { value: 'FIXED', label: 'Fixed', selected: true },
    { value: 'PERCENT', label: 'Percent' }
  ]),
  GenericFormBuilder.datepicker('validUntil', 'Valid Until', []),
  GenericFormBuilder.text('contact', 'Send To', []),
];

export const VenueTableNamesConstraints = [
  GenericFormBuilder.text('name', 'Table Name', [Validators.required])
];
