// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
  production: false,
  bdmApi: {
    host: 'https://api.s.cuculi.com',
  },
  posAPI: {
    host: 'https://clover-api-consumer-release.onrender.com',
    // host: 'http://localhost:3000',
    cloverDashboard: 'https://sandbox.dev.clover.com/dashboard/m/',
  },
  dateFormat: 'YYYY-MM-DD',
  datTimeFormat: 'YYYY-MM-DD hh:mm A',
};

