
import {mergeMap, map, filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, RoutesRecognized, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {

  sideContentCollapsed: BehaviorSubject<boolean>;
  page: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, ) {
    this.sideContentCollapsed = new BehaviorSubject<boolean>(false);
    this.subscribeOnRouteChange();
  }

  ngOnInit() {
  }

  togglSideContent() {
    this.sideContentCollapsed.next(!this.sideContentCollapsed.getValue());
  }

  private subscribeOnRouteChange(): void {
    this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data))
      .subscribe((event) => this.page = event['title']);
  }
}
