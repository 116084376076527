import { Injectable } from '@angular/core';
import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../core/automapper.service';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { environment } from '../../environments/environment';

@Injectable()
export class VoucherMapper implements IMapperRegistry {

  constructor(private automapper: AutomapperService) {
  }

  register() {

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('VoucherApi', 'VoucherModel')
        .forMember('code', (opts) => opts.sourceObject.code)
        .forMember('userId', (opts) => opts.sourceObject.userId)
        .forMember('userName', (opts) => opts.sourceObject.userName)
        .forMember('venueId', (opts) => opts.sourceObject.venueId)
        .forMember('venueName', (opts) => opts.sourceObject.venueName)
        .forMember('contact', (opts) => opts.sourceObject.contact)
        .forMember('amount', (opts) => opts.sourceObject.amount.toFixed(2))
        .forMember('type', (opts) => opts.sourceObject.type)
        .forMember('id', (opts) => opts.sourceObject.id)
        .forMember('createdAt', (opts) => momentTZ(opts.sourceObject.createdAt).tz('America/New_York').format(environment.datTimeFormat))
        .forMember('used', (opts) => {
          switch (opts.sourceObject.used) {
            case true: return 'used';
            case false: return 'available';
            default: return 'available';
          }
        })
        .forMember('validUntil', (opts) => {
          console.log(!!opts.sourceObject.validUntil, 'caadfskjfshjdf');
          switch (!!opts.sourceObject.validUntil) {
            case (true): return moment(opts.sourceObject.validUntil).tz('America/New_York').format(environment.datTimeFormat);
            case (false): return moment().add(1, 'year').tz('America/New_York').format(environment.datTimeFormat);
          }
        })
        .forMember('expired', (opts) => {
          switch (opts.sourceObject.validUntil) {
            case (moment().isAfter(opts.sourceObject.validUntil)): return 'Yes';
            case (moment().isBefore(opts.sourceObject.validUntil)): return 'No';
            default: return 'No';
          }
        });
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('VoucherModel', 'VoucherApi')
        .forMember('userId', (opts) => opts.sourceObject.userId)
        .forMember('venueId', (opts) => opts.sourceObject.venueId)
        .forMember('amount', (opts) => opts.sourceObject.amount)
        .forMember('type', (opts) => opts.sourceObject.type)
        .forMember('contact', (opts) => opts.sourceObject.contact)
        .forMember('validUntil', (opts) => moment(opts.sourceObject.validUntil).toISOString())
        .ignoreAllNonExisting();
    });
  }
}
