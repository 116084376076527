import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HumanizePipe } from './humanize.pipe';
import { PanelLoaderComponent } from './panel-loader.component';
import { DummyComponent } from './dummy.component';
import { ArraySortPipe } from './array.sort.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [HumanizePipe, PanelLoaderComponent, DummyComponent, ArraySortPipe],
  exports: [HumanizePipe, PanelLoaderComponent, DummyComponent, ArraySortPipe],
})
export class SharedModule { }
