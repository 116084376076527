
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html'
})
export class ReportComponent implements OnInit {

  report: any;
  userReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router,
    ) {
  }

  navigateToTarget() {
    switch (this.report.type) {
      case 'user':
        this.router.navigateByUrl(`users/${this.report.targetId}`);
        break;
      case 'event':
        this.router.navigateByUrl(`events/${this.report.targetId}`);
        break;
      case 'group':
        break;
    }
  }

  async ngOnInit() {
    const reportId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      this.report = await this.reportService.getById(reportId);
    } catch (err) {
      console.log(err);
    }
    this.userReady.next(true);
  }
}
