import { GenericFormType } from '../generic-form/generic-form.component';
import { Validators } from '@angular/forms';
import { GenericFormBuilder } from '../generic-form/generic-form.builder';

export const EventCreateConstraints: GenericFormType[] = [
];

export const EventStatusesLookup = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Canceled", value: "canceled" },
  { label: "Active", value: "active" },
  { label: "No-Show", value: "no-show" },
  { label: "Completed", value: "completed" },
  { label: "Paid", value: "paid" },
];

export const EventUpdateConstraints: GenericFormType[] = [
  GenericFormBuilder.text('name', 'Reservation Name', [Validators.required]),
  GenericFormBuilder.dropdown('type', 'Type', [], [
    { value: 'public', label: 'Public' },
    { value: 'private', label: 'Private' },
    { value: 'group', label: 'Group' }
  ]),
  GenericFormBuilder.dropdown('isPremiumTable', 'Premium', [], [
    { value: null, label: '', selected: true },
    { value: 'true', label: 'true' },
    { value: 'false', label: 'false' },
  ]),
  GenericFormBuilder.text('venueName', 'Restaurant', [], true),
  GenericFormBuilder.dropdown('eventStatus', 'Current Status', [Validators.required], EventStatusesLookup, false),
  GenericFormBuilder.text('startDate', 'Start Date', [], true),
  GenericFormBuilder.text('endDate', 'End Date', [], true),
  GenericFormBuilder.text('maxParticipants', 'Participants #', [], true),
  GenericFormBuilder.dropdown('active', 'Visibility', [], [
    { value: 'ACTIVE', label: 'Visible', selected: true },
    { value: 'INACTIVE', label: 'Hidden' }
  ]),
  GenericFormBuilder.text('email', 'Email', []),
  GenericFormBuilder.text('phone', 'Phone', []),
  GenericFormBuilder.dropdown('autoApproveOrders', 'Auto Approve Orders', [], [
    { value: 'YES', label: 'Yes' },
    { value: 'NO', label: 'No' }
  ]),

  GenericFormBuilder.text('specialRequest', 'Special Request', []),
  GenericFormBuilder.text('notes', 'Notes', [])
];
