import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { LaddaModule } from "angular2-ladda";
import { DataTablesModule } from 'angular-datatables';
import { AuthenticatedGuard } from "../core/authenticated.guard";
import { GenericFormModule } from "../generic-form/generic-form.module";
import { LayoutComponent } from "../layout/layout.component";
import { SharedModule } from "../shared/shared.module";
import { UserRoles } from "../users/user.roles";
import { SummeriesService } from "./summaries.service";
import { MonthlySummaryComponent } from "./summary/monthly-summary.component";

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        SharedModule,
        LaddaModule,
        GenericFormModule,
        DataTablesModule,
        RouterModule.forChild([{
            path: "summaries",
            component: LayoutComponent,
            data: {
                sideMenuConfig: {
                    route: '/summaries',
                    icon: 'glyphicon-folder-open',
                    label: 'Summaries',
                    category: 'summaries',
                    index: 5
                },
                access: [UserRoles.ADMIN]
            },
            children: [
                {
                    path: '',
                    component: MonthlySummaryComponent,
                    canActivate: [AuthenticatedGuard],
                    data: { title: "Summaries", category: "summaries" },
                },
            ]
        }])
    ],
    declarations: [MonthlySummaryComponent],
    providers: [SummeriesService]
})
export class SummariesModule { }