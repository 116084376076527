import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { EventService } from '../event/event.service';
import { VenueService } from '../venue/venue.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { DashboardCreateTableComponent } from './dashboard.create.table.component';
import { DashboardCreateOrdersComponent } from './dashboard.create.orders.component';
import { DashboardTableOrdersComponent } from './dashboard.table.orders.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    @Input() datatableReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    venues: any[];
    activeEvents: any[];
    selectedVenueId: string;
    bsModalRef: BsModalRef;
    closeInProgress: boolean;

    constructor(
        private eventService: EventService,
        private venueService: VenueService,
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router) { }

    async ngOnInit() {
        this.venues = await this.venueService.getAll();
        if (this.venues && !!this.venues) {
            this.selectedVenueId = this.venues[0].id;
            await this.reloadTables();
        }
    }

    async onVenueSelected() {
        this.reloadTables();
    }

    openCreateTable() {
        const initialState = {
            venue: this.venues.find((x) => x.id === this.selectedVenueId),
            onTableCreated: this.reloadTables.bind(this)
        };
        this.bsModalRef = this.modalService.show(DashboardCreateTableComponent, { initialState });
    }

    openCreateOrders(event) {
        const initialState = {
            event,
            venue: this.venues.find((x) => x.id === this.selectedVenueId),
        };
        this.bsModalRef = this.modalService.show(DashboardCreateOrdersComponent, { initialState });
        this.bsModalRef.setClass('modal-lg');
    }

    openTableOrders(event) {
        const initialState = {
            event
        };
        this.bsModalRef = this.modalService.show(DashboardTableOrdersComponent, { initialState });
        this.bsModalRef.setClass('modal-lg');
    }

    async closeTable(event) {
        const res = confirm(`Are you sure you want to close table ${event.name}`);
        if (res) {
            this.closeInProgress = true;
            await this.eventService.setEventAsPaid(event.id);
            await this.reloadTables();
            this.closeInProgress = false;
        }
    }

    private async reloadTables() {
        this.activeEvents = await this.eventService.getActive(this.selectedVenueId);
    }
}
