
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '../event.service';
import * as momentTZ from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { BsModalService } from 'ngx-bootstrap';
import { EventEntranceFeeInfoComponent } from './event-entrance-fee-info.component';
import { IEvent, IEventInfoModel } from '../../../app/types';
import { IEventEntranceCharge } from '../../../app/types/IEventEntranceCharge';
import { calculateTotalTicketPrice } from "../../utils/calculatePriceTicket";

@Component({
  selector: 'app-event-entrance-fees',
  templateUrl: './event-entrance-fees.component.html'
})
export class EventEntranceFeesComponent implements OnInit {

  entranceCharges: any[];
  event: IEvent;
  venueFee: number;
  totalCharges = {
    total: 0,
    payoutHost: 0,
    paymentConfig: {
      transactionFeeFixed : 0,
      transactionFeeRelative: 0,
    },
    ticketPrice: 0,
    absorbFees: false,
  };
  entranceChargesReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  calculateTotalTicketPrice = calculateTotalTicketPrice;
  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private modalService: BsModalService) {
  }

  async ngOnInit() {
    const eventId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      // in need of participant info - event info
      this.event = await this.eventService.getById(eventId);
      const getEventInfoRes: IEventInfoModel[] = await this.eventService.getEventsInfo(this.event.venueId, { id: this.event.id }, ['participants'], true);
      const eventInfo = getEventInfoRes[0];

      this.venueFee = this.event.entranceFee && typeof this.event.entranceFee.venueFee === "number" ? this.event.entranceFee.venueFee : null;
      const entranceCharges: IEventEntranceCharge[] = await this.eventService.getEventEntranceFees(eventId);
      const event = await this.eventService.getById(eventId);
      this.totalCharges.paymentConfig.transactionFeeFixed = event.paymentConfig.transactionFeeFixed / 100;
      this.totalCharges.paymentConfig.transactionFeeRelative = event.paymentConfig.transactionFeeRelative;
      this.totalCharges.absorbFees = event.entranceFee.absorbFees;
      this.totalCharges.ticketPrice = event.entranceFee.price;
      // match user id's form event to the participants in the event info
      const mappedClaimedTickets = entranceCharges.map(item => {
        item.charge.chargedAmount = item.charge.chargedAmount ? item.charge.chargedAmount / 100 : 0;
        // inc the total charges
        this.totalCharges.total += item.charge.chargedAmount;

        // this.totalCharges.payoutHost += (item.charge.chargedAmount - (item.charge.chargedAmount * this.totalCharges.paymentConfig.transactionFeeRelative  + this.totalCharges.paymentConfig.transactionFeeFixed)).toFixed(2);
        const userInfo: { userId: string, firstName: string, lastName: string }[] = [];

        if (!!item.claimedBy && !!eventInfo.participants) {
          // find participant and map id, first and last name
          for (const userId of item.claimedBy) {
            const claimedUser = eventInfo.participants.find((participant) => participant.id === userId);
            if (!claimedUser) {
              userInfo.push({ userId: "", firstName: "", lastName: "N/A" });
            } else {
              userInfo.push({ userId: claimedUser.id, firstName: claimedUser.firstName, lastName: claimedUser.lastName });
            }
          }
        }

        return {
          ...item,
          claimedBy: userInfo,
        }
      });

      this.entranceCharges = mappedClaimedTickets;
    } catch (err) {
      console.log(err);
    }
    this.entranceChargesReady.next(true);
  }

  formatItemCreatedAt(createdAt: string) {
    const time = momentTZ(createdAt).tz('America/New_York').format(environment.datTimeFormat);
    return time;
  }

  formatPlatformName(platform: string) {
    let plat = ""
    if (platform === "WEB") {
      plat = "(Web)"
    }
    if (platform === "MOBILE") {
      plat = "(Mobile App)"
    }
    return plat;
  }

  showEntranceChargeInfo(item) {
    console.log(item);
    const initialState = {
      entranceCharge: item,
    };
    const bsModalRef = this.modalService.show(EventEntranceFeeInfoComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  async saveVenueTicketFee() {
    let venueTicketFee = parseFloat((<HTMLInputElement>document.getElementById("venue-service-fee")).value);
    console.log("this is venue ticket fee " + venueTicketFee);
    if (isNaN(venueTicketFee) || venueTicketFee < 0) {
      alert("Please enter the valid number");
      return;
    }
    this.event.entranceFee.venueFee = venueTicketFee;
    this.event = await this.eventService.update(this.event);
    this.venueFee = this.event.entranceFee.venueFee;
  }

}
