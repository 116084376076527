import { Injectable } from '@angular/core';
import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../core/automapper.service';
import { UserApiRoles, UserModelRoles } from '../shared/user';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { environment } from '../../environments/environment';

@Injectable()
export class EventMapper implements IMapperRegistry {

  constructor(private automapper: AutomapperService) {
  }

  register() {

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('EventApi', 'EventModel')
        .forMember('id', (opts) => opts.sourceObject.id)
        .forMember('paymentConfig', (opts) => opts.sourceObject.paymentConfig)
        .forMember('status', (opts) => opts.sourceObject.status)
        .forMember('createdAt', (opts) => momentTZ(opts.sourceObject.createdAt).tz('America/New_York').format(environment.datTimeFormat))
        .forMember('ownerId', (opts) => opts.sourceObject.ownerId)
        .forMember('venueId', (opts) => opts.sourceObject.venueId)
        .forMember('name', (opts) => opts.sourceObject.name)
        .forMember('type', (opts) => opts.sourceObject.type)
        .forMember('isPremiumTable', (opts) => {
          switch (opts.sourceObject.isPremiumTable) {
            case true: return 'true';
            case false: return 'false';
            default: return 'UNKNOWN_VALUE';
          }
        })
        .forMember('email', (opts) => opts.sourceObject.email)
        .forMember('phone', (opts) => opts.sourceObject.phone)
        .forMember('notes', (opts) => opts.sourceObject.notes)
        .forMember('venueName', (opts) => opts.sourceObject.venueName)
        .forMember('venueImage', (opts) => opts.sourceObject.venueImage)
        .forMember('features', (opts) => opts.sourceObject.features)
        .forMember('categories', (opts) => opts.sourceObject.categories)
        .forMember('neighborhood', (opts) => opts.sourceObject.neighborhood)
        .forMember('maxParticipants', (opts) => opts.sourceObject.maxParticipants)
        .forMember('minParticipants', (opts) => opts.sourceObject.minParticipants)
        .forMember('startDate', (opts) => momentTZ(opts.sourceObject.startDate).tz('America/New_York').format(environment.datTimeFormat))
        .forMember('endDate', (opts) => momentTZ(opts.sourceObject.endDate).tz('America/New_York').format(environment.datTimeFormat))
        .forMember('participants', (opts) => opts.sourceObject.participants)
        .forMember('participantsCount', (opts) => opts.sourceObject.participants ? opts.sourceObject.participants.length : 0)
        .forMember('seatNumbers', (opts) => opts.sourceObject.seatNumbers)
        .forMember('invitedParticipants', (opts) => opts.sourceObject.invitedParticipants)
        .forMember('eventStatus', (opts) => opts.sourceObject.eventStatus)
        .forMember('orders', (opts) => opts.sourceObject.orders)
        .forMember('autoApproveOrders', (opts) => {
          switch (opts.sourceObject.autoApproveOrders) {
            case true: return 'YES';
            case false: return 'NO';
            default: return 'UNKNOWN_VALUE';
          }
        })
        .forMember('table', (opts) => opts.sourceObject.table)
        .forMember('waiterId', (opts) => opts.sourceObject.waiterId)
        .forMember('specialRequest', (opts) => opts.sourceObject.specialRequest)
        .forMember('menuOptions', (opts) => opts.sourceObject.menuOptions)
        .forMember('age', (opts) => opts.sourceObject.age)
        .forMember('gender', (opts) => opts.sourceObject.gender)
        .forMember('description', (opts) => opts.sourceObject.description)
        .forMember('splitEven', (opts) => {
          switch (opts.sourceObject.splitEven) {
            case true: return 'YES';
            case false: return 'NO';
            default: return 'UNKNOWN_VALUE';
          }
        })
        .forMember('active', (opts) => {
          switch (opts.sourceObject.active) {
            case true: return 'ACTIVE';
            case false: return 'INACTIVE';
            default: return 'UNKNOWN_ACTIVE_STATUS';
          }
        })
        .forMember('meeting', (opts) => opts.sourceObject.meeting)
        .forMember("entranceFee", (opts) => opts.sourceObject.entranceFee)
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('EventModel', 'EventApi')
        .forMember('id', (opts) => opts.sourceObject.id)
        .forMember('name', (opts) => opts.sourceObject.name)
        .forMember('type', (opts) => opts.sourceObject.type)
        .forMember('isPremiumTable', (opts) => {
          switch (opts.sourceObject.isPremiumTable) {
            case 'true': return true;
            case 'false': return false;
            default: return false;
          }
        })
        .forMember('email', (opts) => opts.sourceObject.email)
        .forMember('phone', (opts) => opts.sourceObject.phone)
        .forMember('notes', (opts) => opts.sourceObject.notes)
        .forMember('ownerId', (opts) => opts.sourceObject.ownerId)
        .forMember('neighborhood', (opts) => opts.sourceObject.neighborhood)
        .forMember('maxParticipants', (opts) => opts.sourceObject.maxParticipants)
        .forMember('minParticipants', (opts) => opts.sourceObject.minParticipants)
        .forMember('seatNumbers', (opts) => opts.sourceObject.seatNumbers)
        .forMember('table', (opts) => opts.sourceObject.table)
        .forMember('waiterId', (opts) => opts.sourceObject.waiterId)
        .forMember('specialRequest', (opts) => opts.sourceObject.specialRequest)
        .forMember('menuOptions', (opts) => opts.sourceObject.menuOptions)
        .forMember('age', (opts) => opts.sourceObject.age)
        .forMember('gender', (opts) => opts.sourceObject.gender)
        .forMember('description', (opts) => opts.sourceObject.description)
        .forMember('active', (opts) => {
          switch (opts.sourceObject.active) {
            case 'ACTIVE': return true;
            case 'INACTIVE': return false;
            default: return true;
          }
        })
        .forMember("entranceFee", (opts) => opts.sourceObject.entranceFee)
        .ignoreAllNonExisting();
    });
  }
}
