import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class EnumService {

  private enumCateogires: any = {};

  constructor(
    private apiService: ApiService) { }

  getAll(): Promise<any[]> {
    return this.apiService.get('/enums/types', [])
      .then((users: any[]) => {
        return users;
      });
  }

  async getByType(type: string) {
    if (this.enumCateogires[type]) {
      return this.enumCateogires[type];
    }
    this.enumCateogires[type] = await this.apiService.get(`/enums/${type}`, []);
    return this.enumCateogires[type];
  }

  async getByTypes(types: string[]) {
    const payload = {types};
    let response;
    try {
      response = await this.apiService.post('/enums/types', payload, []);
    } catch (error) {
      throw error;
    }
    return response;
  }
}
