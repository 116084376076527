import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { UserRoles } from '../users/user.roles';

@Component({
  template: ''
})
export class LandingComponent implements OnInit {

  defaultRoutes: Array<{ role: string, path: string }> = [{
    role: UserRoles.ADMIN,
    path: '/users'
  }, {
    role: UserRoles.VENDOR,
    path: '/venues'
  }];

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit() {
    const user = this.authService.getCurrentUser();
    if (!user) {
      this.router.navigateByUrl('/login');
    } else {
      const route = this.defaultRoutes.find(x => x.role === user.role);
      if (route) {
        this.router.navigateByUrl(route.path);
      } else {
        this.authService.logout();
        this.router.navigateByUrl('/login');
      }
    }
  }
}
