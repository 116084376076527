
import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { VenueService } from '../venue.service';
import { GenericFormBuilder } from '../../generic-form/generic-form.builder';
import { Validators } from '@angular/forms';
import { GenericFormComponent } from '../../generic-form/generic-form.component';

@Component({
  selector: 'app-venue-location',
  templateUrl: './venue-location.component.html'
})
export class VenueLocationComponent implements OnInit {

  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;

  venue: any;
  venueReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  locationRules = [
    GenericFormBuilder.json('location', 'Location', [Validators.required]),
  ];

  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const venueId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      this.venue = await this.venueService.getById(venueId);
      setTimeout(() => {
        const locationControl = this.genericForm.myForm.get('location');
        if (locationControl) {
          locationControl.setValue(this.venue.location);
        }
      });
    } catch (err) {
      console.log(err);
    }
    this.venueReady.next(true);
  }
}
