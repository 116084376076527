import { Injectable } from '@angular/core';
import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../core/automapper.service';
import { UserApiRoles, UserModelRoles } from '../shared/user';
import * as moment from 'moment';
import { environment } from '../../environments/environment';

@Injectable()
export class UserReportMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {

      this.automapper.register((mapperCfg) => {
        mapperCfg
          .createMap('UserReportApi', 'UserReportModel')
          .forMember('id', (opts) => opts.sourceObject.id)
          .forMember('reporterUserId', (opts) => opts.sourceObject.reporterUserId)
          .forMember('description', (opts) => opts.sourceObject.description)
          .forMember('reporterEmail', (opts) => opts.sourceObject.reporterEmail)
          .forMember('reportedEmail', (opts) => opts.sourceObject.reportedEmail)
          .forMember('reporterName', (opts) => opts.sourceObject.reporterName)
          .forMember('reportedName', (opts) => opts.sourceObject.reportedName)
          .forMember('type', (opts) => opts.sourceObject.type)
          .forMember('createdAt', (opts) => opts.sourceObject.createdAt)
          .forMember('targetId', (opts) => opts.sourceObject.targetId)
          .forMember('reportStatus', (opts) => opts.sourceObject.reportStatus)
          .forMember('statusDescription', (opts) => opts.sourceObject.statusDescription)
          .ignoreAllNonExisting();
      });

      this.automapper.register((mapperCfg) => {
        mapperCfg
          .createMap('UserReportModel', 'UserReportApi')
          .forMember('id', (opts) => opts.sourceObject.id)
          .forMember('reporterUserId', (opts) => opts.sourceObject.reporterUserId)
          .forMember('description', (opts) => opts.sourceObject.description)
          .forMember('reporterEmail', (opts) => opts.sourceObject.reporterEmail)
          .forMember('reportedEmail', (opts) => opts.sourceObject.reportedEmail)
          .forMember('reporterName', (opts) => opts.sourceObject.reporterName)
          .forMember('reportedName', (opts) => opts.sourceObject.reportedName)
          .forMember('type', (opts) => opts.sourceObject.type)
          .forMember('targetId', (opts) => opts.sourceObject.targetId)
          .forMember('reportStatus', (opts) => opts.sourceObject.reportStatus)
          .forMember('statusDescription', (opts) => opts.sourceObject.statusDescription)
          .forMember('reportStatus', (opts) => {
            switch (opts.sourceObject.reportStatus) {
              case true: return 'open';
              case false: return 'closed';
            }
          })
          .ignoreAllNonExisting();
      });
    }
}


