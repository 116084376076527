import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { ToasterService } from "angular2-toaster";
import { BehaviorSubject, Subject } from "rxjs";
import { ITableColumn } from "../../entities/ITableColumn";
import { IEntityService } from "../../entities/entity.service";
import { GenericFormComponent } from "../../generic-form/generic-form.component";
import { VenueSettingsConstraints, VenuePaymentConfigConstraints } from "../venue.constraints";
import { VenueService } from "../venue.service";
import * as moment from "moment-timezone";
import { AutomapperService } from "../../core/automapper.service";
import { last } from "rxjs/operators";

@Component({
  selector: 'app-venue-settings',
  templateUrl: './venue-settings.component.html'
})
export class VenueSettingsComponent implements OnInit {

  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;

  venue: any;
  settings: any;
  paymentConfigs: any[] = [];
  paymentConfigData: any;
  columns: ITableColumn[];
  dtOptions: any;

  createNewVisible: boolean;
  createInProgress: boolean;
  deleteInProgress: boolean;

  service: IEntityService;
  venueReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dtTrigger: Subject<any> = new Subject();
  datatableReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  settingsRules = VenueSettingsConstraints;
  paymentConfigRules = VenuePaymentConfigConstraints;


  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute,
    private AutomapperService: AutomapperService,
    private toasterService: ToasterService,
  ) { }

  async ngOnInit() {
    this.service = this.route.parent.snapshot.data.service;
    this.createNewVisible = false;
    this.createInProgress = false;
    this.deleteInProgress = false;
    this.paymentConfigData = {};
    const venueId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      this.venue = await this.venueService.getById(venueId);
      this.settings = await this.venueService.getVenueSettings(venueId);
      this.settings.surcharge = !!this.settings.surcharge ? this.settings.surcharge * 100 : 0;
      if (!this.settings.paymentConfigs) {
        this.settings.paymentConfigs = [];
      }
    } catch (err) {
      console.error(err);
    }
    this.loadPaymentConfigsData();
    this.venueReady.next(true);
    // setTimeout(() => {
    //   this.initForm();
    // }, 600);
  }

  // initForm() {
  //   for (const key in this.settings) {
  //     if (this.settings.hasOwnProperty(key)) {
  //       const control = this.genericForm.myForm.get(key);
  //       if (control) {
  //         control.setValue(this.settings[key]);
  //       }
  //     }
  //   }
  // }

  addNewRule() {
    this.createNewVisible = true;
  }

  close() {
    this.createNewVisible = false;
  }

  async createNewRule() {
    if (!this.validateCreate()) {
      return false;
    }
    this.paymentConfigData = this.genericForm.toObject();
    this.paymentConfigData.timeZone = "America/New_York";
    let mappedConfigData: any = this.AutomapperService.map('VenuePaymentConfigModel', 'VenuePaymentConfigApi', this.paymentConfigData);
    const todaysDate = moment().format("YYYY-MM-DD");
    if (!this.dayAndTimeValidation(mappedConfigData, todaysDate)) {
      return false;
    }
    if (!this.overlapValidation(mappedConfigData, todaysDate)) {
      this.toasterService.pop('error', 'Some of your rules overlap with rules you already have!');
      return false;
    }
    try {
      this.createInProgress = true;
      await this.venueService.updateVenueSettings(this.venue.id, mappedConfigData, this.settings);
      this.toasterService.pop('success', 'Settings updated');
      await this.ngOnInit();
    } catch (err) {
      console.error(err);
      this.toasterService.pop('error', 'Commission rule create failed.');
    } finally {
      this.createInProgress = false;
    }
  }

  async deleteRule(rule: any, index: number) {
    const result = confirm(`Are you sure you want to delete this rule: ${rule.startDay}-${rule.endDay} / ${rule.startTime}-${rule.endTime} (${rule.serviceFee}%)?`)
    if (result) {
      try {
        this.deleteInProgress = true;
        await this.venueService.deleteCommissionRule(this.venue.id, index, this.settings);
        this.toasterService.pop('success', 'Commission Rule deleted');
        await this.ngOnInit();
      } catch (error) {
        console.error(error);
        this.toasterService.pop('error', 'Could not delete Commission Rule');
      } finally {
        this.deleteInProgress = false;
      }
    }
  }

  private validateCreate() {
    this.genericForm.myForm.markAsDirty();
    return this.genericForm.myForm.valid;
  }

  private dayAndTimeValidation(mappedConfigData: any, todaysDate: string) {
    let startTime = todaysDate + mappedConfigData.startTime;
    let endTime = todaysDate + mappedConfigData.endTime;
    if (mappedConfigData.startDay > mappedConfigData.endDay) {
      this.toasterService.pop('error', 'Start Day cannot be after End Day');
      return false;
    }
    if (startTime > endTime) {
      this.toasterService.pop('error', 'Start Time cannot be after End Time');
      return false;
    }
    return true;
  }

  private overlapValidation(mappedConfigData: any, todaysDate: string) {
    const timeZone = mappedConfigData.timeZone;
    const startTime = moment.tz(`${todaysDate} ${mappedConfigData.startTime.slice(1, 13)}`, timeZone);
    const endTime = moment.tz(`${todaysDate} ${mappedConfigData.endTime.slice(1, 13)}`, timeZone);
    for (let i = 0; i < this.settings.paymentConfigs.length; i++) {
      if (timeZone === this.settings.paymentConfigs[i].timeZone) {
        if (this.settings.paymentConfigs[i].endDay < mappedConfigData.startDay || this.settings.paymentConfigs[i].startDay > mappedConfigData.endDay) {
          continue;
        }
        const payConfStartTime = moment.tz(`${todaysDate} ${this.settings.paymentConfigs[i].startTime.slice(1, 13)}`, timeZone);
        const payConfEndTime = moment.tz(`${todaysDate} ${this.settings.paymentConfigs[i].endTime.slice(1, 13)}`, timeZone);
        if (payConfEndTime.isSameOrBefore(startTime) || payConfStartTime.isSameOrAfter(endTime)) {
          continue;
        }
        return false;
      }
      const payConfStartTime = moment.tz(`${todaysDate} ${this.settings.paymentConfigs[i].startTime.slice(1, 13)}`, this.settings.paymentConfigs[i].timeZone);
      const payConfEndTime = moment.tz(`${todaysDate} ${this.settings.paymentConfigs[i].endTime.slice(1, 13)}`, this.settings.paymentConfigs[i].timeZone);
      const startTimeWithInputZone = payConfStartTime.clone().tz(timeZone);
      const endTimeWithInputZone = payConfEndTime.clone().tz(timeZone);
      const oldZoneDayDiff = moment(payConfStartTime.format("YYYY-MM-DD")).startOf("day");
      const newZoneDayDiff = moment(startTimeWithInputZone.format("YYYY-MM-DD")).startOf("day");
      const dayDiff = oldZoneDayDiff.diff(newZoneDayDiff, "days");
      let firstDay = this.settings.paymentConfigs[i].startDay;
      let lastDay = this.settings.paymentConfigs[i].endDay;
      if (dayDiff !== 0) {
        firstDay -= dayDiff;
        lastDay -= dayDiff;
      }
      if (firstDay === 0 && lastDay === 0) {
        firstDay = 7;
        lastDay = 7;
      }
      if (firstDay === 8 && lastDay === 8) {
        firstDay = 1;
        lastDay = 1;
      }
      if (firstDay === 7 && lastDay !== firstDay) {
        if (mappedConfigData.startDay !== 7 && mappedConfigData.lastDay !== 7) {
          continue;
        }
        if (endTimeWithInputZone.isSameOrBefore(startTime) || startTimeWithInputZone.isSameOrAfter(endTime)) {
          continue;
        }
        return false;
      }
      if (lastDay === 8 && firstDay !== lastDay) {
        if (mappedConfigData.startDay !== 1 && mappedConfigData.lastDay !== 1) {
          continue;
        }
        if (endTimeWithInputZone.isSameOrBefore(startTime) || startTimeWithInputZone.isSameOrAfter(endTime)) {
          continue;
        }
        return false;
      }
      if (lastDay < mappedConfigData.startDay || firstDay > mappedConfigData.endDay) {
        continue;
      }
      if (endTimeWithInputZone.isSameOrBefore(startTime) || startTimeWithInputZone.isSameOrAfter(endTime)) {
        continue;
      }
      return false;
    }
    return true;
  }

  private async loadPaymentConfigsData() {
    this.columns = this.getTableColumns();
    this.dtOptions = this.getTableOptions();

    this.paymentConfigs = this.AutomapperService.map('VenueApi', 'VenuePaymentConfigModel', this.settings.paymentConfigs);
    const self = this;
    setTimeout(() => {
      self.render();
    }, 0)
  }

  private render() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        this.setDatatableReady();
      });
    } else {
      this.dtTrigger.next();
      this.setDatatableReady();
    }
  }

  private setDatatableReady() {
    setTimeout(() => {
      this.datatableReady.next(true);
    }, 500);
  }

  getTableOptions(): any {
    return {
      order: [[0, 'desc']],
    };
  }

  getTableColumns(): ITableColumn[] {
    return [
      {
        index: 0,
        name: 'serviceFee',
        type: 'text',
        label: 'Organizer Reward (%)',
        hidden: false,
      },
      {
        index: 1,
        name: 'startDay',
        type: 'text',
        label: 'Start Day',
        hidden: false,
      },
      {
        index: 2,
        name: 'endDay',
        type: 'text',
        label: 'End Day',
        hidden: false,
      },
      {
        index: 3,
        name: 'startTime',
        type: 'text',
        label: 'Start Time',
        hidden: false,
      },
      {
        index: 4,
        name: 'endTime',
        type: 'text',
        label: 'End Time',
        hidden: false,
      }
    ]
  }
}