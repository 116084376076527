import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { SharedModule } from '../shared/shared.module';
import { MomentModule } from 'angular2-moment';
import { LaddaModule } from 'angular2-ladda';
import { FileUploadModule } from '../file.upload/file.upload.module';
import { HappyHourModule } from '../happy-hour/happy-hour.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule, TimepickerModule } from 'ngx-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EntitiesModule } from '../entities/entities.module';
import { DashboardComponent } from './dashboard.component';
import { UserRoles } from '../users/user.roles';
import { LayoutComponent } from '../layout/layout.component';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { DashboardCreateTableComponent } from './dashboard.create.table.component';
import { DashboardCreateOrdersComponent } from './dashboard.create.orders.component';
import { DashboardTableOrdersComponent } from './dashboard.table.orders.component';
import {NgxPrintModule} from 'ngx-print';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MomentModule,
        LaddaModule,
        GenericFormModule,
        FileUploadModule,
        HappyHourModule,
        MultiSelectModule,
        DropdownModule,
        FormsModule,
        ReactiveFormsModule,
        TabsModule,
        NgxIntlTelInputModule,
        TimepickerModule,
        DataTablesModule,
        EntitiesModule,
        UiSwitchModule.forRoot({
            size: 'medium'
        }),
        DragDropModule,
        RouterModule.forRoot([{
            path: '',
            component: LayoutComponent,
            data: {
                sideMenuConfig: {
                    route: '/dashboard',
                    icon: 'glyphicon glyphicon-th',
                    label: 'Dashboard',
                    category: 'dashboard',
                    index: 1
                },
                access: [UserRoles.VENDOR]
            },
            children: [{
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [AuthenticatedGuard],
                data: { category: 'dashboard', title: 'Dashboard' }
            }]
        }]),
        NgxPrintModule
    ],
    declarations: [DashboardComponent, DashboardCreateTableComponent, DashboardCreateOrdersComponent, DashboardTableOrdersComponent],
    entryComponents: [DashboardCreateTableComponent, DashboardCreateOrdersComponent, DashboardTableOrdersComponent],
    providers: []
})
export class DashboardModule {
    constructor() {
    }
}
