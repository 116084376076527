import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PosThirdPartyService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.posAPI.host;
  }

  loginClover(data: cloverCredentials) {
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.host + "/auth", data, {}).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error.error);
        }
      );
    });
  }

  getMerchantData(merchant_id: string): Promise<{
    merchantId: string;
    venueId: string;
  }> {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(this.host + "/auth" + "/postdata/" + merchant_id, {})
        .subscribe(
          (response) => {
            console.log("Response", Response);
            resolve(response);
          },
          (error) => {
            reject(error.error);
          }
        );
    });
  }
}

export type cloverCredentials = {
  client_id: string;
  code: string;
  employee_id: string;
  merchant_id: string;
  venueIds?: string[];
};
