import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../users/user.service';
import { Router } from '@angular/router';
import { PromoCodesService } from './promo-codes.service';

@Component({
  selector: 'app-promo-codes',
  templateUrl: './promo-codes.component.html',
  styleUrls: ['./promo-codes.component.scss']
})
export class PromoCodesComponent implements OnInit {

  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  codes: any[];
  users: any[];
  Filteredcodes: any[];
  filteredUsers: any[];
  selectedItemId: string = null;
  selectedUser: string = null;
  userPromoCodes: any[];
  activeTab: string = 'allCodes';
  searchallCodes: string = '';
  searchallUserCodes: string = '';

  constructor(private procoCodeServices: PromoCodesService, private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.loadEntitiesData();
  }

  private async loadEntitiesData() {
    this.entityReady.next(false);
    this.codes = await this.procoCodeServices.getAllPromoCodes();
    this.users = await this.userService.getAll();
    this.Filteredcodes = this.codes;
    this.filteredUsers = this.users;
    this.entityReady.next(true);
  }

  toggleUsers(item: any): void {
    if (this.selectedItemId === item.id) {
        this.selectedItemId = null;
    } else {
        this.selectedItemId = item.id;
    }
  }

  async toggleShowCodes(item: any) {
    if (this.selectedUser === item.id) {
      this.selectedUser = null;
    } else {
      this.userPromoCodes = await this.procoCodeServices.getPromoCodesById(item.id);
      this.selectedUser = item.id;
    }
  }

  switchTab(tab: string) {
    this.activeTab = tab;
  }

  async filterAllCodes() {
    this.Filteredcodes = this.codes.filter(item => new RegExp(this.searchallCodes, 'i').test(item.promoCode));
  }

  async filterAllUsers() {
    this.filteredUsers = this.users.filter(item => new RegExp(this.searchallUserCodes, 'i').test(item.email));
  }

}
