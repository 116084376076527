import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesService } from './pages.service';
import { EntityListComponent } from '../entities/entity-list/entity-list.component';
import { PagesServiceResolver } from './pages.service.resolver';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { EntityEditComponent } from '../entities/entity-edit/entity-edit.component';
import { EntityCreateComponent } from '../entities/entity-create/entity-create.component';
import { JSONEditorModule } from 'ngx-jsoneditor';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { UserRoles } from '../users/user.roles';

@NgModule({
  imports: [
    CommonModule,
    JSONEditorModule,
    RouterModule.forChild([{
      path: 'pages',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/pages',
          icon: 'glyphicon-list-alt',
          label: 'Pages',
          category: 'pages',
          index: 1
        },
        access: [UserRoles.ADMIN]
      },
      children: [
        {
          path: '',
          component: EntityListComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: PagesServiceResolver },
          data: { category: 'pages', title: 'Pages' }
        },
        {
          path: 'new',
          component: EntityCreateComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: PagesServiceResolver },
          data: { category: 'pages', title: 'Create Page' }
        },
        {
          path: ':id',
          component: EntityEditComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: PagesServiceResolver },
          data: {
            category: 'pages',
            title: 'Page Info',
          }
        }
      ]
    }])
  ],
  declarations: [],
  providers: [PagesService, PagesServiceResolver]
})
export class PagesModule {
  constructor() {
  }
}
