import { Injectable } from '@angular/core';
import { AutomapperService } from '../core/automapper.service';
import { IMapperRegistry } from './mapper.registry';

@Injectable()
export class WaiterMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('WaiterApi', 'WaiterModel')
                .forMember('id', (opts) => opts.mapFrom('id'))
                .forMember('firstName', (opts) => opts.mapFrom('firstName'))
                .forMember('lastName', (opts) => opts.mapFrom('lastName'))
                .forMember('venueId', (opts) => opts.mapFrom('venueId'));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
            .createMap('WaiterCreateForm', 'WaiterModel')
            .forMember('id', (opts) => opts.mapFrom('id'))
            .forMember('firstName', (opts) => opts.mapFrom('firstName'))
            .forMember('lastName', (opts) => opts.mapFrom('lastName'))
            .forMember('venueId', (opts) => opts.mapFrom('venueId'));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
            .createMap('WaiterModel', 'WaiterApi')
            .forMember('id', (opts) => opts.mapFrom('id'))
            .forMember('firstName', (opts) => opts.mapFrom('firstName'))
            .forMember('lastName', (opts) => opts.mapFrom('lastName'))
            .forMember('venueId', (opts) => opts.mapFrom('venueId'));
        });
    }
}
