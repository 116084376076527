import { GenericFormType } from '../generic-form/generic-form.component';
import { Validators } from '@angular/forms';

export const PagesCreateConstraints: GenericFormType[] = [
  {
    type: 'control', name: 'name', label: 'Page Name (required)', inputType: 'text',
    placeHolder: 'Page Name', validators: [Validators.required]
  },
  {
    type: 'control', name: 'type', label: 'Type (required)', inputType: 'dropdown',
    lookups: [
      { value: '', label: 'Please select type', selected: true },
      { value: 'page', label: 'Page' },
    ], validators: [Validators.required]
  },
  {
    type: 'control', name: 'title', label: 'Page Title', inputType: 'text',
    placeHolder: 'Page Title'
  },
  {
    type: 'control', name: 'description', label: 'Page Description', inputType: 'text',
    placeHolder: 'Page Description'
  },
  {
    type: 'control', name: 'keywords', label: 'Page Keywords', inputType: 'text',
    placeHolder: 'Page Keywords'
  },
  {
    type: 'control', name: 'data', label: 'Page Data (required)', inputType: 'json', validators: [Validators.required]
  }
];

export const PagesUpdateConstraints: GenericFormType[] = [
  {
    type: 'control', name: 'name', label: 'Page Name (required)', inputType: 'text',
    placeHolder: 'Page Name', validators: [Validators.required]
  },
  {
    type: 'control', name: 'type', label: 'Type (required)', inputType: 'dropdown',
    lookups: [
      { value: '', label: 'Please select type'},
      { value: 'page', label: 'Page' },
    ], validators: [Validators.required]
  },
  {
    type: 'control', name: 'title', label: 'Page Title', inputType: 'text',
    placeHolder: 'Page Title'
  },
  {
    type: 'control', name: 'description', label: 'Page Description', inputType: 'text',
    placeHolder: 'Page Description'
  },
  {
    type: 'control', name: 'keywords', label: 'Page Keywords', inputType: 'text',
    placeHolder: 'Page Keywords'
  },
  {
    type: 'control', name: 'data', label: 'Page Data (required)', inputType: 'json', validators: [Validators.required]
  }
];
