import { Validators } from '@angular/forms';
import { HourSlots } from '../../shared/hour.slots';
import { GenericFormBuilder } from '../../generic-form/generic-form.builder';
import { GenericFormControl, GenericFormType, Lookup } from '../../generic-form/generic-form.component';

export const addonCategoryLookUps: Lookup[] = [
  { label: "Toppings", value: "Toppings" },
  { label: "Vegetables", value: "Vegetables" },
  { label: "Extras", value: "Extras" },
  { label: "Extra Sauce", value: "Extra Sauce" },
  { label: "Meats", value: "Meats" },
  { label: "Meat", value: "Meat" },
  { label: "Style", value: "Style" },
  { label: "Spicy", value: "Spicy" },
  { label: "Pasta", value: "Pasta" },
  { label: "Noodle", value: "Noodle" },
  { label: "Drink", value: "Drink"},
  { label: "Coffee or Tea", value: "Coffee or Tea" },
  { label: "Cheese", value: "Cheese" },
  { label: "Sauce", value: "Sauce" },
  { label: "Starter", value: "Starter" },
  { label: "Entree", value: "Entree" },
  { label: "Dessert", value: "Dessert" },
  { label: "Salad", value: "Salad" },
  { label: "Add-on", value: "Add-on" },
  { label: "Temperature", value: "Temperature" },
];

export const MenuItemOptionConstraints: GenericFormControl[] = [
  GenericFormBuilder.text('name', 'Portion', []),
  GenericFormBuilder.dropdown('type', 'Type', [], [
    { label: "Glass", value: "glass" },
    { label: "Bottle", value: "bottle" },
    { label: "Cup", value: "cup" }
  ], false, null, (form) => form.species === "drink", 'inline', true),
  GenericFormBuilder.number('total', 'Price', [Validators.required], false, null, '', '0.01'),
];

export const MenuItemAddonConstraints: GenericFormControl[] = [
  GenericFormBuilder.text('addonName', 'Addon', []),
  GenericFormBuilder.number('addonTotal', 'Price', [], false, null, '', '0.01'),
  GenericFormBuilder.text('id', 'ID', [], true, () => false),
];

export const MenuItemAddonsCategoryConstraints: GenericFormControl[] = [
  GenericFormBuilder.autocomplete('addonsLabel', 'Addons Category Title', [], addonCategoryLookUps, (text) => Promise.resolve(!text ? [] : addonCategoryLookUps.filter((ac) => ac.value.toLowerCase().includes(text.toLowerCase()))), null, false, null, null, null, 'Addons Category'),
  GenericFormBuilder.array('addons', '', [
    GenericFormBuilder.group('menuItemAddon', null, MenuItemAddonConstraints, null, 'inline')
  ], null, true),
  GenericFormBuilder.number('minAddonQuantity', 'Min addon quantity per addon category', [], false, (form) => form.includeAddons === true, 'inline', '1', true),
  GenericFormBuilder.number('maxAddonQuantity', 'Max addon quantity per addon category', [], false, (form) => form.includeAddons === true, 'inline', '1', true),
]

export const TimeFrameConstraints: GenericFormControl[] = [
  GenericFormBuilder.dropdown('day', 'Day', [], [
    { label: 'Select day', value: null },
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' }
  ], true),
  GenericFormBuilder.dropdown('start', 'From', [], HourSlots.slice(0), true),
  GenericFormBuilder.dropdown('end', 'To', [], HourSlots.slice(0), true)
];

export const MenuItemCreateConstraints: GenericFormType[] = [
  GenericFormBuilder.dropdown('type', 'Type', [Validators.required], [], false),
  GenericFormBuilder.text('name', 'Name', [Validators.required], false, null),
  GenericFormBuilder.switch('marketPrice', 'Market Price', null),
  GenericFormBuilder.switch('alcohol', 'Alcohol', null),
  GenericFormBuilder.dropdown('species', 'Food/Drink', [], [
    { label: 'Food', value: 'food' },
    { label: 'Drink', value: 'drink' }], false, null),
  GenericFormBuilder.text('optionsLabel', 'Portions Label', null, false, null, 'inline', '', 'Select Portion (default)'),
  GenericFormBuilder.array('options', 'Portions', [
    GenericFormBuilder.group('menuItemOption', null, MenuItemOptionConstraints, null, 'inline')
  ], null, true),
  GenericFormBuilder.switch('includeAddons', 'Include Addons', null),
  // tslint:disable-next-line:max-line-length
  GenericFormBuilder.array('addonsCategories', 'Addons Options', [
    GenericFormBuilder.group('addonsCategory', null, MenuItemAddonsCategoryConstraints, null, 'row')
  ], (field) => field.includeAddons === true, true, 'block'),
  GenericFormBuilder.array('workingHours', 'Happy Hours', [
    GenericFormBuilder.group('happyHourTimeFrames', null, TimeFrameConstraints, null, 'inline')
  ], (field) => field.type === 'happyHour', false),
  GenericFormBuilder.textarea('description', 'Description', []),
];

export const MenuItemUpdateConstraints: GenericFormType[] = [
  GenericFormBuilder.dropdown('type', 'Type', [Validators.required], [], false),
  GenericFormBuilder.text('name', 'Name', [Validators.required]),
  GenericFormBuilder.switch('marketPrice', 'Market Price', null),
  GenericFormBuilder.switch('alcohol', 'Alcohol', null),
  GenericFormBuilder.dropdown('species', 'Food/Drink', [], [
    { label: 'Food', value: 'food' },
    { label: 'Drink', value: 'drink' }], false, null),
  GenericFormBuilder.text('optionsLabel', 'Portions Label', null, false, null, 'inline', '', 'Select Portion (default)'),
  GenericFormBuilder.array('options', 'Portions', [
    GenericFormBuilder.group('menuItemOption', null, MenuItemOptionConstraints, null, 'inline')
  ], null, true),
  GenericFormBuilder.switch('includeAddons', 'Include Addons', null),
  // tslint:disable-next-line:max-line-length
  GenericFormBuilder.array('addonsCategories', 'Addons Options', [
    GenericFormBuilder.group('addonsCategory', null, MenuItemAddonsCategoryConstraints, null, 'row')
  ], (field) => field.includeAddons === true, true, 'block'),
  GenericFormBuilder.array('workingHours', 'Happy Hours', [
    GenericFormBuilder.group('happyHourTimeFrames', null, TimeFrameConstraints, null, 'inline')
  ], (field) => field.type === 'happyHour', false),
  GenericFormBuilder.textarea('description', 'Description', []),
];

export const MenuTypeSpeciesMap: any = {
  'Chef\'s menu': { type: 'food', label: 'Food' },
  'Soft/Juice': { type: 'drink', label: 'Drink' },
  'Happy Hour': { type: 'food', label: 'Food' },
  Brunch: { type: 'food', label: 'Food' },
  Lunch: { type: 'food', label: 'Food' },
  Dinner: { type: 'food', label: 'Food' },
  Drinks: { type: 'drink', label: 'Drink' },
  Wine: { type: 'bottle', label: 'Bottle' },
  Cocktail: { type: 'drink', label: 'Drink' },
  Liquor: { type: 'bottle', label: 'Bottle' },
  default: { type: 'food', label: 'Food' },
};
