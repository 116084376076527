import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PostService } from './post.service';
import { IEntityService } from '../entities/entity.service';

@Injectable()
export class PostServiceResolver implements Resolve<IEntityService> {
    constructor(private postService: PostService) { }

    resolve(): IEntityService {
        return this.postService;
    }
}
