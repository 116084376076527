import { Component, OnInit, inject } from '@angular/core';
import { HappyHourService } from '../happy-hour.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { HappyHourCreateComponent } from '../happy-hour.create/happy-hour.create.component';
import { HourSlots } from '../../shared/hour.slots';
import { HappyHourEditComponent } from '../happy-hour.edit/happy-hour.edit.component';

@Component({
  selector: 'app-happy-hour-list-component',
  templateUrl: './happy-hour.list.component.html',
})
export class HappyHourlistComponent implements OnInit {

  // provided by modal client
  venue: any;
  loadMenus: any;

  happyHours: any[];

  constructor(
    private happyhourService: HappyHourService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) { }

  async ngOnInit() {
    await this.loadHappyHours();
  }

  async loadHappyHours() {
    this.happyHours = await this.happyhourService.getVenueHappyHours(this.venue.id);
  }

  cancel() {
    this.bsModalRef.hide();
  }

  editHappyHour(hh: any) {
    console.log('EDIT HAPPY HOUR', hh);
    this.bsModalRef.hide();
    const initialState = {
      venue: this.venue,
      happyHour: hh,
      loadMenus: this.loadMenus,
    };
    this.bsModalRef = this.modalService.show(HappyHourEditComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
  }

  async removeHappyHour(hh: any) {
    console.log('REMOVE HAPPY HOUR', hh);
    await this.happyhourService.delete(hh);
    await this.loadHappyHours();
  }

  openHappyHourCreateModal() {
    this.bsModalRef.hide();
    const initialState = {
      venue: this.venue,
    };
    this.bsModalRef = this.modalService.show(HappyHourCreateComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
  }

  getHour(timeStamp: string): string {
    const hour = HourSlots.find((x) => x.value === timeStamp);
    if (hour) {
      return hour.label;
    }
    return timeStamp;
  }
}
