
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { VenueService } from '../venue.service';

@Component({
  selector: 'app-venue-gallery',
  templateUrl: './venue-gallery.component.html'
})
export class VenueGalleryComponent implements OnInit {

  venue: any;
  venueReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const venueId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      this.venue = await this.venueService.getById(venueId);
    } catch (err) {
      console.log(err);
    }
    this.venueReady.next(true);
  }
}
