
import { mergeMap, map, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, RoutesRecognized, NavigationEnd, ActivatedRoute } from '@angular/router';
import { EventService } from '../event.service';
import { UserService } from '../../users/user.service';
import * as momentTZ from 'moment-timezone';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-event-orders',
  templateUrl: './event-payments.component.html'
})
export class EventPaymentsComponent implements OnInit {

  invoice: any[];
  invoiceReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private eventService: EventService,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const eventId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      this.invoice = await this.eventService.getEventInvoice(eventId);
    } catch (err) {
      console.log(err);
    }
    this.invoiceReady.next(true);
  }

  formatPaymentDate(date: string) {
    const time = momentTZ(date).tz('America/New_York').format(environment.datTimeFormat);
    return time;
  }
}
