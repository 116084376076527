import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IEntityService } from '../entities/entity.service';
import { EnumService } from './enums.service';

@Injectable()
export class EnumsServiceResolver implements Resolve<IEntityService> {
  constructor(private enumService: EnumService) {}

  resolve(): IEntityService {
    return this.enumService;
  }
}
