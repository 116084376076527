import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { BehaviorSubject } from 'rxjs';
import { GenericFormComponent, GenericFormType } from '../../generic-form/generic-form.component';
import { UtilityService } from '../../core/utility.service';
import { ProductsService } from '../products.service';
import { ProductAddonsConstraints } from '../products.constraints';

@Component({
  selector: 'app-product-addons',
  templateUrl: './product-addons.component.html'
})
export class ProductAddonsComponent implements OnInit {

  @ViewChild('genericForm', {static: false}) genericForm: GenericFormComponent;

  entity: any;
  enumOptions: any[] = [];
  createInProgress: boolean;
  updateRules: GenericFormType[];
  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tabs: any[] = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private productService: ProductsService) { }

  ngOnInit() {
    this.updateRules = ProductAddonsConstraints;
    this.reloadEntity()
      .then(() => {
        setTimeout(() => {
          this.entityReady.next(true);
        }, 1500);
      });
  }

  async saveChanges() {
    if (!this.validateEntity()) {
      return;
    }

    try {
      this.createInProgress = true;
      const newAddOn = {
        name: this.genericForm.myForm.get('name').value,
        description: this.genericForm.myForm.get('description').value,
        price: this.genericForm.myForm.get('price').value
      };
      this.entity.addOns.push(newAddOn);
      this.entity = await this.productService.update(this.entity);
      this.reloadEntity()
        .then(() => {
            this.entityReady.next(true);
            this.genericForm.myForm.get('name').setValue('');
            this.genericForm.myForm.get('description').setValue('');
            this.genericForm.myForm.get('price').setValue('');
            this.genericForm.myForm.markAsPristine();
        });
      this.toasterService.pop('success', 'Entity saved successfully');
    } catch (e) {
      console.error(e);
      this.toasterService.pop('error', 'Entity save failed');
    } finally {
      this.createInProgress = false;
    }
  }

  tabClicked(index) {
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].active = (i === index);
    }
  }

  getCurrentTab(): number {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].active) {
        return i;
      }
    }
    return -1;
  }

  reloadEntity() {
    this.entityReady.next(false);
    const id: string = this.route.parent.snapshot.paramMap.get('id');
    return this.productService.getById(id)
      .then((entity) => {
        this.entity = entity;
        if (!this.entity.addOns) {
            this.entity.addOns = [];
        }
      });
  }

  onChange(test) {
    console.log(test);
  }

  closePanel() {
    this.router.navigateByUrl('/products');
  }

  async deleteAddOn(option) {
    const result = confirm('Are you sure you want to delete an entity');
    if (result) {
      const addOn = this.entity.addOns.find((x) => x.name === option.name );
      if (addOn) {
        const index = this.entity.addOns.indexOf(addOn);
        this.entity.addOns.splice(index, 1);
        await this.productService.update(this.entity);
        this.reloadEntity()
        .then(() => {
            this.entityReady.next(true);
        });
      }
      this.toasterService.pop('success', 'Entity successfully deleted');
    }
  }

  private validateEntity() {
    return this.genericForm.myForm.valid;
  }
}
