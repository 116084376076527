import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { VenueService } from './venue.service';
import { IEntityService } from '../entities/entity.service';

@Injectable()
export class VenueServiceResolver implements Resolve<IEntityService> {
  constructor(private venueService: VenueService) {}

  resolve(): IEntityService {
    return this.venueService;
  }
}
