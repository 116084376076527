import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { ITableColumn } from '../entities/ITableColumn';
import { GenericFormType } from '../generic-form/generic-form.component';
import { ApiService } from '../core/api.service';
import { AutomapperService } from '../core/automapper.service';
import { IAmbassadorDataReport } from '../users/user-ambassador/user-ambassadorData';

@Injectable({
  providedIn: 'root'
})
export class BansService {

  constructor(
    private ApiService: ApiService,
    private AutomapperService: AutomapperService) { }
  
  getName(): string {
    return 'bans';
  }

  getAll(): Promise<any[]> {
    return this.ApiService.get('/admin/users', [])
    .then((users: any[]) => {
      const filteredUsers = users.filter(user => user.active === false);
      const res = this.AutomapperService.mapArray('UserApi', 'UserModel', filteredUsers);
      return res;
    })
  }

  getAllDeleted(): Promise<any[]> {
    return this.ApiService.get('/admin/deletedUsers', [])
    .then((users: any[]) => {
      const res = this.AutomapperService.mapArray('UserApi', 'UserModel', users);
      return res;
    })
  }

  getOne(query: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  find(query: any): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  getByEmails(emails: any): Promise<any[]> {
    return this.ApiService.post('/users/search/email', {
      emails
    }, [])
      .then((users: any[]) => {
        return users;
      });
  }

  getById(id: string): Promise<any> {
    return this.ApiService.get('/admin/users/' + id, [])
      .then((user) => {
        const userMaped = this.AutomapperService.map('UserApi', 'UserModel', user);
        return userMaped;
      });
  }

  getDeleteById(id: string): Promise<any> {
    return this.ApiService.get('/admin/deletedUsers/' + id, [])
      .then((user) => {
        const userMaped = this.AutomapperService.map('UserApi', 'UserModel', user);
        return userMaped;
      });
  }

  create(entity: any): Promise<any> {
    return this.ApiService.post('/admin/users', entity, []);
  }

  update(entity: any): Promise<any> {
    const entityMaped: any = this.AutomapperService.map('UserModel', 'UserApi', entity);
    return this.ApiService.put('/users/' + entityMaped.id, entityMaped, []);
  }

  delete(entity: any): Promise<boolean> {
    return this.ApiService.delete('/users/' + entity.id, []);
  }

  changePassword(entity: any, userId: string): Promise<any> {
    return this.ApiService.put(`/users/${userId}/admin-password-change`, entity, []);
  }

  getAmbassadorData(ambassadorId: string, dateFrom: string, dateTo: string): Promise<IAmbassadorDataReport[]> {
    return this.ApiService.post(
      `/user/ambassador-data`,
      { ambassadorId, dateFrom: dateFrom, dateTo: dateTo },
      [],
    );
  }

  getTableColumns(): ITableColumn[] {
    return [{
      index: 0,
      type: 'text',
      name: 'email',
      label: 'Email',
      hidden: false
    }, {
      index: 1,
      type: 'text',
      name: 'fullName',
      label: 'Name',
      hidden: false
    }, {
      index: 2,
      type: 'date',
      name: 'createdAt',
      label: 'Created At',
      hidden: false
    }, {
      index: 3,
      type: 'select',
      options: ['ADMIN', 'REGULAR', 'VENDOR', 'TEMPORARY'],
      name: 'role',
      label: 'Role',
      hidden: false
    }, {
      index: 4,
      type: 'select',
      options: ['Visible', 'Hidden'],
      name: 'active',
      label: 'Visibility',
      hidden: false
    }, {
      index: 5,
      type: "text",
      name: "isStudent",
      label: "Is Student",
      hidden: false,
    }, {
      index: 6,
      type: "text",
      name: "referredBy",
      label: "Referred by",
      hidden: false,
    }];
  }

  getTableOptions(): any {
    return {
      order: [[2, 'desc']],
    };
  }

  getCreateConstraints(): GenericFormType[] {
    return ;
  }
  getUpdateConstraints(): GenericFormType[] {
    return ;
  }
}
