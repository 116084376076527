import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

import { AuthService } from '../../core/auth.service';
import { VenueService } from '../venue.service';
import { WaiterCreateComponent } from './waiter-create.component';

@Component({
    selector: 'app-waiters-list',
    templateUrl: './waiters-list.component.html',
    styleUrls: ['./waiters-list.component.scss']
})
export class WaitersListComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private venueService: VenueService,
        private route: ActivatedRoute,
        private modalService: BsModalService
    ) { }

    currentUser: any;
    venue: any;
    waiters: any;
    bsModalRef: BsModalRef;

    async ngOnInit() {
        this.currentUser = await this.authService.getCurrentUser();
        const id: string = this.route.parent.snapshot.paramMap.get('id');
        this.venue = await this.venueService.getById(id);
        this.getAllWaiters();
    }

    async getAllWaiters() {
        this.waiters = await this.venueService.getAllWaiters(this.venue.id);
    }

    openAddNewWaiterModal() {
        const initialState = {
            venue: this.venue,
            onClaimed: this.getAllWaiters.bind(this)
        };
        this.bsModalRef = this.modalService.show(WaiterCreateComponent, { initialState });
        this.bsModalRef.setClass('modal-lg');
    }

    async deleteWaiter(waiter: any) {
        const res = await confirm(`Are you sure you want to delete  waiter ${waiter.firstName} ${waiter.lastName}?`);
        if (res) {
            await this.venueService.deleteWaiter(waiter.id);
            await this.getAllWaiters();
        }
    }

}
