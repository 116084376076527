import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../shared/shared.module';
import { LaddaModule } from 'angular2-ladda';
import { MomentModule } from 'angular2-moment';
import { JSONEditorModule } from 'ngx-jsoneditor';
import { EntityListComponent } from './entity-list/entity-list.component';
import { EntityEditComponent } from './entity-edit/entity-edit.component';
import { EntityCreateComponent } from './entity-create/entity-create.component';
import { EntityListFiltersComponent } from './entity-list/entity-list.filters.component';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { GenericFormModule } from '../generic-form/generic-form.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LaddaModule,
    DataTablesModule,
    MomentModule,
    JSONEditorModule,
    BsDatepickerModule,
    GenericFormModule
  ],
  declarations: [EntityListComponent, EntityEditComponent, EntityListFiltersComponent, EntityCreateComponent],
  exports: [EntityListComponent, EntityEditComponent, EntityListFiltersComponent, EntityCreateComponent]
})
export class EntitiesModule { }
