import { ParamMap } from '@angular/router';
import { Injectable } from '@angular/core';

import { IEntityService } from '../entities/entity.service';
import { ApiService } from '../core/api.service';
import { GenericFormType } from '../generic-form/generic-form.component';
import { CreateProductConstraints, UpdateProductConstraints } from './products.constraints';
import { EnumService } from '../enums/enums.service';
import { ITableColumn } from '../entities/ITableColumn';

@Injectable()
export class ProductsService implements IEntityService {

  private createProductRules = [];

  constructor(
    private ApiService: ApiService,
    private enumService: EnumService) {
    this.prepareConstraints();
  }

  getName(): string {
    return 'products';
  }

  getAll(routeParams?: ParamMap): Promise<any[]> {
    const type = routeParams.get('type');
    return this.ApiService.get(`/product?category=${type}`, []);
  }

  getByVendorAndType(vendor: string, type: string): Promise<any[]> {
    return this.ApiService.get(`/product?vendor=${vendor}`, []);
  }

  getOne(query: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  find(query: any): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  getById(id: string): Promise<any> {
    return this.ApiService.get('/product/' + id, [])
      .then((product) => {
        product._title = product.name;
        return product;
      });
  }

  create(entity: any): Promise<any> {
    entity.orderable = !!entity.orderable;
    return this.ApiService.post('/product', entity, []);
  }

  update(entity: any): Promise<any> {
    delete entity.createdby;
    delete entity.createdAt;
    delete entity.status;
    delete entity._title;
    entity.orderable = !!entity.orderable;
    if (entity.price && !entity.price.value) {
      delete entity.price;
    }
    if (entity.discount && !entity.discount.value) {
      delete entity.discount;
    }
    return this.ApiService.put('/product/' + entity.id, entity, []);
  }

  delete(entity: any): Promise<boolean> {
    return this.ApiService.delete('/product/' + entity.id, []);
  }

  getTableColumns(): ITableColumn[] {
    return [{
      index: 0,
      type: 'text',
      name: 'name',
      label: 'Name',
      hidden: false
    }, {
      index: 1,
      type: 'text',
      name: 'category',
      label: 'Category',
      hidden: false
    }, {
      index: 2,
      type: 'text',
      name: 'orderable',
      label: 'Orderable',
      hidden: false
    }, {
      index: 3,
      type: 'text',
      name: 'vendor',
      label: 'Vendor',
      hidden: false
    }];
  }

  getTableOptions(): any {
    return {};
  }

  getCreateConstraints(): GenericFormType[] {
    return this.createProductRules;
  }
  getUpdateConstraints(): GenericFormType[] {
    return UpdateProductConstraints;
  }

  private prepareConstraints() {
    this.createProductRules = CreateProductConstraints;
    let categoriesRes = null;
    let vendorsRes = null;
    this.enumService.getByType('product-categories')
      .then((cat) => {
        categoriesRes = cat;
      })
      .then((vendors) => {
        vendorsRes = vendors;
      })
      .then(() => {
        const categoryNode = this.createProductRules.find((c) => c.name === 'category');
        if (categoryNode) {
          (categoryNode as any).lookups = categoriesRes.map((c, index) => {
            return {
              value: c.key,
              label: c.value,
              selected: index === 0
            };
          });
        }
        const vendorNode = this.createProductRules.find((c) => c.name === 'vendor');
        if (vendorNode) {
          for (let i = 0; i < vendorsRes.length; i++) {
            (vendorNode as any).lookups.push({
              value: vendorsRes[i].id,
              label: `${vendorsRes[i].name} (${vendorsRes[i].type.toUpperCase()})`
            });
          }
        }
      });
  }
}
