import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VenueService } from '../venue/venue.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file.upload.component.html'
})
export class FileUploadComponent implements OnInit {

  @Input() title: string;
  @Input() multiple?: boolean;
  @Input() preview?: boolean;
  @Input() autoUpload?: boolean;
  @Input() venue?: any;
  @Output() venueChange = new EventEmitter();

  formDocumentUpload: FormGroup;
  files: any[];
  filesData: FormData[];
  base64Array: (string | ArrayBuffer)[];
  fileUploaded: boolean;
  updateInProgress: boolean;
  fileNames: string[];

  constructor(
    private fb: FormBuilder,
    private venueService: VenueService,
  ) { }

  async ngOnInit() {
    this.fileUploaded = false;
    this.updateInProgress = false;
    this.formDocumentUpload = this.fb.group({
      document: [null, Validators.required]
    });
    this.multiple = this.multiple ? this.multiple : false;
    this.preview = this.preview ? this.preview : false;
    this.autoUpload = this.autoUpload ? this.autoUpload : false;
    this.files = [];
    this.filesData = [];
    this.base64Array = [];
    this.fileNames = [];
  }

  async onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      const fd = new FormData();
      this.files.push(event.target.files[0]);
      fd.append('file', event.target.files[0]);
      this.filesData.push(fd);
      this.fileUploaded = true;
      if (this.preview) {
        this.readThis(event.target);
        this.fileNames.push(event.target.files[0].name);
      }
      if (this.autoUpload) {
        this.autoUploadImage();
      }
    }
  }

  async autoUploadImage() {
    this.updateInProgress = true;
    await this.venueService.uploadPhoto(this.venue.id, this.filesData[0]);
    this.venueChange.emit();
    this.updateInProgress = false;
    this.files = [];
    this.filesData = [];
    this.base64Array = [];
    this.fileNames = [];
  }

  removeFile(index) {
    this.filesData.splice(index, 1);
    this.files.splice(index, 1);
    this.base64Array.splice(index, 1);
    this.fileNames.splice(index, 1);
    this.fileUploaded = false;
  }

  private readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.base64Array.push(myReader.result);
    };

    myReader.readAsDataURL(file);
  }
}
