import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { EventService } from '../event/event.service';
import { GenericFormBuilder } from '../generic-form/generic-form.builder';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic-form.component';
import { VenueService } from '../venue/venue.service';

@Component({
    selector: 'app-dashboard-create-table',
    templateUrl: './dashboard.create.table.component.html'
})
export class DashboardCreateTableComponent implements OnInit {

    @ViewChild('createTableForm', { static: false }) createTableForm: GenericFormComponent;

    createRules: GenericFormType[] = [
        GenericFormBuilder.text('table', 'Table', [Validators.required], false, null),
    ];
    venue: any;
    createInProgress: boolean;
    onTableCreated: (event) => {};

    constructor(
        public bsModalRef: BsModalRef,
        private eventService: EventService) { }

    async ngOnInit() {
    }

    async create() {
        if (!this.createTableForm.isValid()) {
            return;
        }
        try {
            this.createInProgress = true;
            const data = this.createTableForm.toObject();
            const newEvent = await this.eventService.createSitAndEatEvent(this.venue.id, data.table, null);
            this.createInProgress = false;
            if (!!this.onTableCreated) {
                this.onTableCreated(newEvent);
            }
            this.bsModalRef.hide();
        } catch (err) {
            this.createInProgress = false;
        }
    }

    cancel() {
        this.bsModalRef.hide();
    }
}
