import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { IEntityService } from '../entity.service';
import { BehaviorSubject } from 'rxjs';
import { GenericFormComponent, GenericFormType } from '../../generic-form/generic-form.component';
import { UtilityService } from '../../core/utility.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../users/user.service';

@Component({
  selector: 'app-entity-edit',
  templateUrl: './entity-edit.component.html'
})
export class EntityEditComponent implements OnInit, AfterViewInit {

  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;

  entity: any;
  entityName: string;
  createInProgress: boolean;
  category: string;
  imageSyncInProgress: boolean;
  entityService: IEntityService;
  updateRules: GenericFormType[];
  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  instance: any = {};
  tabs: any[] = [];

  // modal for bans users
  modalOpen: boolean = false;
  deleteForm: FormGroup;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private userService: UserService
    ) { }

  ngOnInit() {
    this.entityService = this.route.snapshot.data.service;
    this.category = this.entityService.getName();
    this.updateRules = this.entityService.getUpdateConstraints();
    this.reloadEntity()
      .then(() => {
        this.loadTabs();
        setTimeout(() => {
          this.initForm();
          this.entityReady.next(true);
        }, 1500);
      });
    this.deleteForm = this.formBuilder.group({
      banReason: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    this.initForm();
  }

  async saveChanges() {
    if (!this.validateEntity()) {
      return;
    }

    try {
      this.createInProgress = true;
      this.entity = this.utilityService.fillEntity(this.genericForm.myForm, this.updateRules, this.entity);
      this.entity = await this.entityService.update(this.entity, this.route.snapshot.paramMap);
      this.toasterService.pop('success', 'Entity saved successfully');
    } catch (e) {
      console.error(e);
      this.toasterService.pop('error', 'Entity save failed');
    } finally {
      this.createInProgress = false;
    }
  }

  tabClicked(index) {
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].active = (i === index);
    }
  }

  getCurrentTab(): number {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].active) {
        return i;
      }
    }
    return -1;
  }

  reloadEntity() {
    this.entityReady.next(false);
    const entityId: string = this.route.snapshot.paramMap.get('id');
    return this.entityService.getById(entityId)
      .then((entity) => {
        this.entity = entity;
        this.entityName = entity.name;
        // this.entityReady.next(true);
      });
  }

  initForm() {
    for (const key in this.entity) {
      if (this.entity.hasOwnProperty(key)) {
        const control = this.genericForm.myForm.get(key);
        if (control) {
          control.setValue(this.entity[key]);
        }
      }
    }
  }

  onChange(test) {
    console.log(test);
  }

  closePanel() {
    this.router.navigateByUrl('/' + this.category);
  }

  async deleteEntity() {
    const result = confirm('Are you sure you want to delete an entity');
    if (result) {
      try {
        await this.entityService.delete(this.entity);
        this.closePanel();
        this.toasterService.pop('success', 'Entity successfully deleted');
      } catch (error) {
        this.toasterService.pop('error', error.message);
      }
    }
  }

  openModal() {
    const result = confirm('Are you sure you want to delete an entity');
    if(result) {
      this.modalOpen = true;
    }
  }

  closeModal() {
    this.modalOpen = false;
  }

  async guardar() {
    this.closeModal();
      try {
        await this.userService.newDelete(this.entity, this.deleteForm.controls['banReason'].value);
        this.toasterService.pop('success', 'Entity successfully deleted');
        this.closePanel();
        
      } catch (error) {
        this.toasterService.pop('error', error.message);
      }
  }

  private validateEntity() {
    return this.genericForm.myForm.valid;
  }

  private loadTabs() {
    this.tabs = [];
    this.tabs.push({
      path: '',
      label: 'General',
      active: this.router.url === ('/' + this.category + '/' + this.entity.id),
    });
    if (this.route.snapshot.data.tabs && this.route.snapshot.data.tabs.length) {
      for (let i = 0; i < this.route.snapshot.data.tabs.length; i++) {
        const tab = this.route.snapshot.data.tabs[i];
        if (this.route.snapshot.data.shouldDisplayTab && !this.route.snapshot.data.shouldDisplayTab(this.entity, tab.path)) {
          continue;
        }
        tab.active = this.router.url === ('/' + this.category + '/' + this.entity.id + '/' + tab.path);
        this.tabs.push(tab);
      }
    }
  }
}
