import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToasterService } from 'angular2-toaster';
import { BehaviorSubject, Subject } from 'rxjs';
import { UtilityService } from '../../core/utility.service';
import { IEntityService } from '../../entities/entity.service';
import { ITableColumn } from '../../entities/ITableColumn';
import { GenericFormComponent } from '../../generic-form/generic-form.component';
import { VenueTableNamesConstraints } from '../venue.constraints';
import { VenueService } from '../venue.service';

@Component({
  selector: 'app-venue-table-names',
  templateUrl: './venue-table-names.component.html'
})
export class VenueTableNamesComponent implements OnInit {

  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;

  venue: any;
  tableNames: string[];
  tableNameData: any;
  columns: ITableColumn[];
  dtOptions: any;
  createNewVisible: boolean;
  createInProgress: boolean;
  deleteInProgress: boolean;
  caretUp: boolean;

  service: IEntityService;
  venueReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dtTrigger: Subject<any> = new Subject();
  datatableReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tableNamesRules = VenueTableNamesConstraints;

  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private utilityService: UtilityService
  ) { }

  async ngOnInit() {
    this.service = this.route.parent.snapshot.data.service;
    this.createNewVisible = false;
    this.createInProgress = false;
    this.deleteInProgress = false;
    this.caretUp = false;
    this.tableNameData = {};
    const venueId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      this.venue = await this.venueService.getById(venueId);
    } catch (err) {
      console.error(err);
    }
    this.loadTableNamesData();
    this.venueReady.next(true);
  }

  createNew() {
    this.createNewVisible = true;
  }

  close() {
    this.createNewVisible = false;
  }

  sortTableNames() {
    const backwards = [];
    this.tableNames.forEach(tn => backwards.unshift(tn));
    this.tableNames = backwards;
    this.caretUp = !this.caretUp;
  }

  async createTableName() {
    if (!this.validateCreate()) {
      return false;
    }
    try {
      this.createInProgress = true;
      this.tableNameData = this.utilityService.fillEntity(this.genericForm.myForm, this.tableNamesRules, this.tableNameData);
      const payload = {
        options: [...this.tableNames, this.tableNameData.name]
      };
      await this.venueService.updateTableNames(this.venue.id, payload);
      this.toasterService.pop('success', 'New Table Name created');
      await this.ngOnInit();
    } catch (err) {
      console.error(err);
      this.toasterService.pop('error', 'Failed to create new Table Name');
    } finally {
      this.createInProgress = false;
    }
  }

  async deleteTableName(tableName: string) {
    const result = confirm(`Are you sure you want to delete this table name ( ${tableName} )?`);
    if (result) {
      try {
        this.deleteInProgress = true;
        const index = this.tableNames.indexOf(tableName);
        this.tableNames.splice(index, 1);
        await this.venueService.updateTableNames(this.venue.id, { options: this.tableNames });
        this.toasterService.pop('success', 'Table Name deleted');
      } catch (err) {
        console.error(err);
        this.toasterService.pop('error', 'Table Name delete failed');
      } finally {
        this.deleteInProgress = false;
      }
    }
  }

  private async loadTableNamesData() {
    this.columns = this.getTableColumns();
    const venueTables = await this.venueService.getTableNames(this.venue.id);
    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    this.tableNames = venueTables.options.sort(collator.compare);
  }

  private validateCreate() {
    this.genericForm.myForm.markAsDirty();
    return this.genericForm.myForm.valid;
  }

  getTableColumns(): ITableColumn[] {
    return [
      {
        index: 0,
        name: 'tableName',
        type: 'text',
        label: 'Table Name',
        hidden: false,
      }
    ];
  }
}
