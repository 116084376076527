import { IMapperRegistry } from './mapper.registry';
import { Injectable } from '@angular/core';
import { AutomapperService } from '../core/automapper.service';

@Injectable()
export class MenuMapper implements IMapperRegistry {
    constructor(private automapper: AutomapperService) { }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('MenuApi', 'MenuModel')
                .forMember('type', (opts) => opts.mapFrom('type'))
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('disabled', (opts) => opts.mapFrom('disabled'))
                .forMember('priority', (opts) => opts.mapFrom('priority'))
                .forMember('startDate', (opts) => opts.mapFrom('workingHours'))
                .forMember('categories', (opts) => opts.sourceObject.categories.sort((x, y) => {
                    return x.position - y.position;
                }).map((c) => {
                    return {
                        name: c.name,
                        description: c.description ? c.description : null,
                        label: c.label,
                        position: c.position,
                        items: this.automapper.mapArray('MenuItemApi', 'MenuItemModel', c.items),
                    };
                }))
                .ignoreAllNonExisting();
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('MenuModel', 'MenuApi')
                .forMember('venueId', (opts) => opts.mapFrom('venueId'))
                .forMember('type', (opts) => opts.mapFrom('type'))
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('disabled', (opts) => opts.mapFrom('disabled'))
                .forMember('priority', (opts) => opts.mapFrom('priority'))
                .forMember('startDate', (opts) => opts.mapFrom('startDate'))
                .forMember('categories', (opts) => opts.sourceObject.categories.map((x) => {
                    return {
                        name: x.name,
                        description: x.description ? x.description : null,
                        position: x.position,
                        items: x.items.map((y) => y.id)
                    };
                }))
                .ignoreAllNonExisting();
        });
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('MenuModel', 'MenuCategoryEditForm')
                .forMember('venueId', (opts) => opts.mapFrom('venueId'))
                .forMember('type', (opts) => opts.mapFrom('type'))
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('disabled', (opts) => opts.mapFrom('disabled'))
                .forMember('priority', (opts) => opts.mapFrom('priority'))
                .forMember('day', (opts) => {
                    const day = {
                        startDay: null,
                        endDay: null
                    };
                    const startDate = opts.sourceObject.startDate ? opts.sourceObject.startDate : null;
                    if (startDate) {
                        day.startDay = startDate.day;
                        day.endDay = startDate.dayUntil;
                    }
                    return [day];
                })
                .forMember('time', (opts) => {
                    const time = {
                        start: null,
                        end: null
                    };
                    const startTime = opts.sourceObject.startDate ? opts.sourceObject.startDate : null;
                    if (startTime) {
                        time.start = startTime.start;
                        time.end = startTime.end;
                    }
                    return [time];
                })
                .forMember('categories', (opts) => opts.sourceObject.categories.map((x) => {
                    return {
                        name: x.name,
                        position: x.position,
                        items: x.items.map((y) => y.id)
                    };
                }))
                .ignoreAllNonExisting();
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('MenuCategoryEditForm', 'MenuModel')
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('priority', (opts) => opts.mapFrom('priority'))
                .forMember('disabled', (opts) => opts.mapFrom('disabled'))
                .forMember('startDate', (opts) => {
                    const startDate = {
                        day: null,
                        dayUntil: null,
                        start: null,
                        end: null
                    };
                    const dayForm = opts.sourceObject.day && opts.sourceObject.day.length ? opts.sourceObject.day[0] : null;
                    if (dayForm) {
                        startDate.day = dayForm.startDay;
                        startDate.dayUntil = dayForm.endDay;
                    }
                    const timeForm = opts.sourceObject.time && opts.sourceObject.time.length ? opts.sourceObject.time[0] : null;
                    if (timeForm) {
                        startDate.start = timeForm.start;
                        startDate.end = timeForm.end;
                    }
                    return startDate;
                })
                .ignoreAllNonExisting();
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('MenuModel', 'MenuCategoryCopyForm')
                .forMember('name', (opts) => opts.mapFrom('name'))
                .ignoreAllNonExisting();
        });
    }
}
