import { Injectable } from '@angular/core';

import { IEntityService } from '../entities/entity.service';
import { ApiService } from '../core/api.service';
import { AutomapperService } from '../core/automapper.service';
import { GenericFormType } from '../generic-form/generic-form.component';
// import { UserCreateConstraints, UserUpdateConstraints } from './user.constraints';
import { ITableColumn } from '../entities/ITableColumn';
import { UserReportUpdateConstraints } from './report.constraints';
@Injectable()
export class ReportService implements IEntityService {

  constructor(
    private ApiService: ApiService,
    private AutomapperService: AutomapperService) { }

  getName(): string {
    return 'reports';
  }

  getAll(): Promise<any[]> {
    return this.ApiService.get('/user-reports', [])
      .then((reports: any[]) => {
        return reports.map((item) => {
          return {
            ... item,
            id: item.id,
            description: item.description,
            reporterEmail: item.reporterEmail,
            reportedEmail: `${item.reportedEmail}`,
          };
        });
      });
  }

  getOne(query: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  find(query: any): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  getById(id: string): Promise<any> {
    return this.ApiService.get('/user-reports/' + id, [])
      .then((userReport) => {
        const userReportMapped = this.AutomapperService.map('UserReportApi', 'UserReportModel', userReport);
        return userReportMapped;
      });
  }

  create(entity: any): Promise<any> {
    return this.ApiService.post('/admin/users', entity, []);
  }

  update(entity: any): Promise<any> {
    const entityMaped: any = this.AutomapperService.map('UserReportModel', 'UserReportApi', entity);
    return this.ApiService.put('/user-reports/' + entityMaped.id, entityMaped, []);
  }

  delete(entity: any): Promise<boolean> {
    return this.ApiService.delete('/users/' + entity.id, []);
  }

  getTableColumns(): ITableColumn[] {
    return [{
      index: 0,
      type: 'text',
      name: 'type',
      label: 'Type',
      hidden: false
    }, {
      index: 1,
      type: 'text',
      name: 'reporterEmail',
      label: 'Reporter Email',
      hidden: false
    }, {
      index: 2,
      type: 'text',
      name: 'reportedEmail',
      label: 'Reported Email',
      hidden: false
    }, {
      index: 3,
      type: 'text',
      name: 'reportedName',
      label: 'Reported Name',
      hidden: false
    }, {
      index: 4,
      type: 'text',
      name: 'description',
      label: 'Description',
      hidden: false
    }, {
      index: 5,
      type: 'date-range',
      name: 'createdAt',
      label: 'Created At',
      hidden: false
    },
  ];
  }

  getCustomTabs(entity) {
    if (entity.type === 'user') {

    }
    if (entity.type === 'event') {

    }
  }

  getTableOptions(): any {
    return {
      order: [[5, 'desc']],
    };
  }

  getCreateConstraints(): GenericFormType[] {
    return;
  }
  getUpdateConstraints(): GenericFormType[] {
    return UserReportUpdateConstraints;
  }
}
