import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportService } from './report.service';
import { EntityListComponent } from '../entities/entity-list/entity-list.component';
import { ReportServiceResolver } from './report.service.resolver';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { EntityEditComponent } from '../entities/entity-edit/entity-edit.component';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { SharedModule } from '../shared/shared.module';
import { ReportComponent } from './report-component/report.component';
import { UserRoles } from '../users/user.roles';

const shouldDisplayTab = (entity, path) => {
  return entity.type === path;
};

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([{
      path: 'reports',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/reports',
          icon: 'glyphicon-ban-circle',
          label: 'Reports',
          category: 'reports',
          index: 4
        },
        access: [UserRoles.ADMIN]
      },
      children: [
        {
          path: '',
          component: EntityListComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: ReportServiceResolver },
          data: { category: 'reports', title: 'Reports', disableCreateEntity: true }
        },
        {
          path: ':id',
          component: EntityEditComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: ReportServiceResolver },
          data: {
            category: 'reports',
            title: 'User Report Info',
            shouldDisplayTab: shouldDisplayTab,
            tabs: [{
              path: 'user',
              label: 'User Report'
            },
            {
              path: 'event',
              label: 'Event Report'
            }]
          },
          children: [{
              path: 'user',
              component: ReportComponent,
              canActivate: [AuthenticatedGuard],
              data: {category: 'reports', title: 'User Report' }
            },
            {
              path: 'event',
              component: ReportComponent,
              canActivate: [AuthenticatedGuard],
              data: { category: 'report', title: 'Event Report' }
            }],
        }
      ]
    }])
  ],
  declarations: [
    ReportComponent,
  ],
  providers: [ReportService, ReportServiceResolver],
})
export class ReportModule {}
