import { Component, Injectable, OnInit, ViewChild } from "@angular/core";
import { GenericFormComponent, GenericFormType } from "../../generic-form/generic-form.component";
import { cloneDeep } from 'lodash';
import { createVenueChargeConstraints } from "./venue-charges.constraints";
import { BsModalRef } from "ngx-bootstrap";
import { VenueService } from "../venue.service";
import { UtilityService } from "../../core/utility.service";

@Component({
    selector: 'app-create-venue-charge',
    templateUrl: './create-venue-charge.component.html'
})

@Injectable()
export class CreateVenueChargeComponent implements OnInit {
    @ViewChild('chargeForm', { static: false }) chargeForm: GenericFormComponent;
    createRules: GenericFormType[] = cloneDeep(createVenueChargeConstraints);
    createInProgress: boolean = false;
    venue: any;
    fetchCharges: any;

    constructor(
        public bsModalRef: BsModalRef,
        private venueService: VenueService,
        private utilityService: UtilityService
    ) {

    }
    async ngOnInit() {

    }

    cancel() {
        this.createRules = cloneDeep(createVenueChargeConstraints);
        this.bsModalRef.hide();
    }

    async create() {
        if (!this.chargeForm.isValid()) {
            return;
        }
        if (confirm(`Are you sure you want to charge ${this.venue.name} for $${this.chargeForm.myForm.get("amount").value}?`)) {
            this.createInProgress = true;
            let charge: any = {};
            this.utilityService.fillEntity(this.chargeForm.myForm, this.createRules, charge);
            charge = await this.venueService.chargeVenue(this.venue.id, charge);
            this.createInProgress = false;
            await this.fetchCharges();
            this.bsModalRef.hide()
        }
        return;
    }
}