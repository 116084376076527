import { Component, OnInit, Output, ViewChild, EventEmitter } from "@angular/core";
import { GenericFormComponent, GenericFormType } from "../../../app/generic-form/generic-form.component";
import { cloneDeep } from 'lodash';
import { PostCreateConstraints } from "../../../app/post/post.constraints";
import { BsModalRef } from "ngx-bootstrap";
import { FileUploadComponent } from "../../../app/file.upload/file.upload.component";
import { VenueService } from "../venue.service";
import { UtilityService } from "../../../app/core/utility.service";

@Component({
    selector: 'app-venue-posts',
    templateUrl: './venue-post-create.component.html'
})
export class VenuePostCreateComponent implements OnInit {

    @ViewChild('postForm', { static: false }) postForm: GenericFormComponent;
    @ViewChild('fileUpload', { static: false }) fileUpload: FileUploadComponent;
    createRules: GenericFormType[] = cloneDeep(PostCreateConstraints);
    createInProgress: boolean = false;
    venue: any;
    fetchPosts: any;

    constructor(
        public bsModalRef: BsModalRef,
        private venueService: VenueService,
        private utilityService: UtilityService
    ) {

    }
    async ngOnInit() {

    }

    cancel() {
        this.createRules = cloneDeep(PostCreateConstraints);
        this.bsModalRef.hide();
    }

    async create() {
        if (!this.postForm.isValid()) {
            return;
        }
        this.createInProgress = true;
        let post: any = {};
        post = this.utilityService.fillEntity(this.postForm.myForm, this.createRules, post);
        post.createdBy = {
            creatorId: this.venue.id,
            type: "venue"
        };
        post = await this.venueService.createVenuePost(post);
        if (this.fileUpload.fileUploaded && this.fileUpload.filesData && this.fileUpload.filesData.length) {
            post = await this.venueService.uploadVenuePostImage(post.id, this.fileUpload.filesData[0]);
        }
        this.createInProgress = false;
        await this.fetchPosts();
        this.bsModalRef.hide()
    }
}