import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { IPremiumSubscription } from './IPremiumSubscription';

@Injectable({
  providedIn: 'root'
})
export class PremiumSubscriptionsService {

  constructor(private ApiService: ApiService, ) { }

  async getAllSubscriptions(): Promise<IPremiumSubscription[]> {
    return this.ApiService.get('/premium/users', [])
    .then((subscriptions: IPremiumSubscription[]) => {
      const res = subscriptions;
      return res;
    })
  }

  getSubscriptionByEmail(email: string): Promise<IPremiumSubscription[]> {
    return this.ApiService.get(`/premium/users/${email}`, [])
    .then((subscription: IPremiumSubscription[]) => {
      const res = subscription;
      return res;
    })
  }

}
