import { Component, OnInit } from '@angular/core';
import { BansService } from '../bans.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-bans-info',
  templateUrl: './bans-info.component.html',
  styleUrls: ['./bans-info.component.scss']
})
export class BansInfoComponent implements OnInit {

  service: any;
  user: any;
  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private bansService: BansService, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit() {
    this.getUserInfo();
  }

  private async getUserInfo () {
    this.entityReady.next(false);
    const entityId: string = this.route.snapshot.paramMap.get('id');
    if (this.route.snapshot.paramMap.get('type') === 'bansInfo') {
      this.user = await this.bansService.getById(entityId);
    }else{
      this.user = await this.bansService.getDeleteById(entityId);
    }
    // this.user = await this.bansService.getById(entityId);
    this.entityReady.next(true);
  }

  closePanel() {
    this.router.navigateByUrl('/bans');
  }

}
