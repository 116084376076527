import { Component, Input, Output } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { GenericFormType, GenericFormComponent, GenericFormControl } from './generic-form.component';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-generic-input-row',
  templateUrl: './generic-input-row.component.html'
})
export class GenericInputRowComponent {
  // tslint:disable-next-line:no-input-rename
  @Input('field') field;
  // tslint:disable-next-line:no-input-rename
  @Input('formGroup') formGroup;

  @Input('myForm') myForm;

  @Input("isFormValidated") isFormValidated: boolean;

  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();

  toRaw(myForm?: GenericFormType[]): any {
    const form = myForm ? myForm : this.formGroup;
    const raw = {};
    Object.keys(form.controls).forEach(key => {
      const value = form.get(key).value;
      raw[key] = value;
    });
    return raw;
  }

  async search(event: any, field: string) {
    const autocompleteField = this.field as GenericFormControl;
    if (autocompleteField && autocompleteField.search) {
      autocompleteField.filteredLookups = await autocompleteField.search(event.query);
    }
  }

  valueChanged() {
    const autocompleteField = this.field as GenericFormControl;
    const rawValue = this.toRaw();
    if (autocompleteField && autocompleteField.onChange) {
      autocompleteField.onChange(rawValue.address.value);
    }
    this.dataChange.emit(rawValue);
  }

  displayControlError(fieldLabel: string, errors: ValidationErrors): string {
    const errorTypes = Object.keys(errors);
    for (let e = 0; e < errorTypes.length; e++) {
      if (!!errorTypes[e] && !!GenericInputRowComponent.controlErrorHandlers[errorTypes[e]]) {
        return GenericInputRowComponent.controlErrorHandlers[errorTypes[e]](fieldLabel, errors[errorTypes[e]]);
      }
    }
  }

  static controlErrorHandlers = {
    required: (label: string, error: any): string => {
      console.log(error);
      return `${label} is required`;
    },
    requiredTrue: (label: string, error: any): string => {
      console.log(error);
      return `${label} is required`;
    },
    min: (label: string, error: any): string => {
      console.log(error);
      return `Min number for ${label} is ${error.min}`;
    },
    max: (label: string, error: any): string => {
      console.log(error);
      return `Min number for ${label} is ${error.max}`;
    },
    email: (label: string, error: any): string => {
      console.log(error);
      return `${label} must be a valid email`;
    },
    minlength: (label: string, error: any): string => {
      console.log(error);
      return `${label} minimum length is ${error.requiredLength}`;
    },
    maxlength: (label: string, error: any): string => {
      console.log(error);
      return `${label} maximum length is ${error.requiredLength}`;
    },
    pattern: (label: string, error: any): string => {
      console.log(error);
      return `${label} doesn't match the pattern ${error.requiredPattern}`;
    },
    richEditorTextLength: (label: string, error: any): string => {
      console.log(error);
      return `${label} maximum length is ${error.requiredLength}`;
    },
    richEditorNumberOfMedia: (label: string, error: any): string => {
      console.log(error);
      return `${label} maximum embedded media is ${error.requiredNumber}`;
    }
  }
}
