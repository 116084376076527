import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import {
  GenericFormComponent,
  GenericFormType,
} from "../../generic-form/generic-form.component";
import { UserService } from "../user.service";
import { IAmbassadorData, IAmbassadorDataReport } from "./user-ambassadorData";

@Component({
  selector: "app-user-ambassador",
  templateUrl: "./user-ambassador.component.html",
})
export class UserAmbassadorComponent implements OnInit {
  @ViewChild("genericForm", { static: false })
  genericForm: GenericFormComponent;

  user: any = null;
  ambassadorDataReport: IAmbassadorDataReport[] = [];
  numberOfCodes: number;
  dateInterval: GenericFormType[];
  userReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  moment = moment;
  fetchRequested: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  async ngOnInit() {
    const userId: string = this.route.parent.snapshot.paramMap.get("id");
    try {
      this.user = await this.userService.getById(userId);
      this.dateInterval = this.userService.getAmbassadorDataConstraints();
    } catch (err) {
      console.log(err);
    }
    this.userReady.next(true);
  }

  async getCodeTotal() {
    if (this.genericForm.myForm.valid || !this.user || !this.user.id) {
      this.fetchRequested = true;
      this.ambassadorDataReport = await this.userService.getAmbassadorData(
        this.user.id,
        this.genericForm.myForm.value.fromDate.toISOString(),
        this.genericForm.myForm.value.toDate.toISOString()
      );
      this.fetchRequested = false;
    }
  }
}
