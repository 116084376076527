import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JSONEditorModule } from 'ngx-jsoneditor';
import { SharedModule } from '../shared/shared.module';
import { LaddaModule } from 'angular2-ladda';
import { DataTablesModule } from 'angular-datatables';
import { MomentModule } from 'angular2-moment';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { ProductServiceResolver } from './product.service.resolver';
import { ProductsService } from './products.service';
import { LayoutComponent } from '../layout/layout.component';
import { EntityListComponent } from '../entities/entity-list/entity-list.component';
import { EntityCreateComponent } from '../entities/entity-create/entity-create.component';
import { EntityEditComponent } from '../entities/entity-edit/entity-edit.component';
import { ProductDataComponent } from './product-data.component/product-data.component';
import { ProductPriceComponent } from './product-price.component/product-price.component';
import { ProductAddonsComponent } from './product-addons.component/product-addons.component';

@NgModule({
  imports: [
    FormsModule,
    JSONEditorModule,
    CommonModule,
    SharedModule,
    LaddaModule,
    ReactiveFormsModule,
    DataTablesModule,
    MomentModule,
    GenericFormModule,
    RouterModule.forChild([{
      path: 'products', component: LayoutComponent, children: [
        {
          path: '',
          redirectTo: '/products/t/tour',
          pathMatch: 'full'
        },
        {
          path: 't/:type',
          component: EntityListComponent,
          resolve: { service: ProductServiceResolver },
          data: { category: 'products', title: 'Products' }
        },
        {
          path: 'new',
          component: EntityCreateComponent,
          resolve: { service: ProductServiceResolver },
          data: { category: 'products', title: 'Create Product' }
        },
        {
          path: ':id',
          component: EntityEditComponent,
          resolve: { service: ProductServiceResolver },
          data: {
            category: 'products',
            title: 'Product Info',
            tabs: [{
              path: 'data',
              label: 'Info & Schedule'
            },
            {
              path: 'price',
              label: 'Price'
            },
            {
              path: 'add-ons',
              label: 'Add-Ons'
            }]
          },
          children: [{
            path: 'data',
            component: ProductDataComponent,
            data: { category: 'products', title: 'Product Info' }
          },
          {
            path: 'price',
            component: ProductPriceComponent,
            data: { category: 'products', title: 'Product Info' }
          },
          {
            path: 'add-ons',
            component: ProductAddonsComponent,
            data: { category: 'products', title: 'Product Info' }
          }]
        }
      ]
    }])
  ],
  declarations: [ProductDataComponent, ProductPriceComponent, ProductAddonsComponent],
  providers: [ProductsService, ProductServiceResolver]
})
export class ProductsModule {
}
