// (item.charge.chargedAmount - ((item.charge.chargedAmount * totalCharges.paymentConfig.transactionFeeRelative)  + totalCharges.paymentConfig.transactionFeeFixed)).toFixed(2)

const calculateTotalTicketPrice = (
  absorbFees: boolean,
  ticketPrice: number,
  serviceFee: number,
  serviceFeeComission: number,
): number => {
  let total;
  if (absorbFees) {
    total = (ticketPrice - ((ticketPrice * serviceFeeComission) + serviceFee)).toFixed(2);
  } else {
    total = ticketPrice.toFixed(2);
  }

  return total;
};

export { calculateTotalTicketPrice };
