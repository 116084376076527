import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { VenueService } from '../venue.service';
import { DataTableDirective } from 'angular-datatables';
import { GenericFormComponent } from '../../generic-form/generic-form.component';
import { VenueVouchersConstraints } from '../venue.constraints';
import { ITableColumn } from '../../entities/ITableColumn';
import { Subject } from 'rxjs';
import { IEntityService } from '../../entities/entity.service';
import { ToasterService } from 'angular2-toaster';
import { UtilityService } from '../../core/utility.service';

@Component({
  selector: 'app-venue-vouchers',
  templateUrl: './venue-vouchers.component.html'
})
export class VenueVouchersComponent implements OnInit {

  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;

  venue: any;
  vouchers: any[] = [];
  voucherData: any;
  columns: ITableColumn[];
  dtOptions: any;
  createNewVisible: boolean;
  createInProgress: boolean;
  deleteInProgress: boolean;
  emailInProgress: boolean;

  service: IEntityService;
  venueReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dtTrigger: Subject<any> = new Subject();
  datatableReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  vouchersRules = VenueVouchersConstraints;

  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private utilityService: UtilityService
  ) { }

  async ngOnInit() {
    this.service = this.route.parent.snapshot.data.service;
    this.createNewVisible = false;
    this.createInProgress = false;
    this.deleteInProgress = false;
    this.emailInProgress = false;
    this.voucherData = {};
    const venueId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      this.venue = await this.venueService.getById(venueId);
    } catch (err) {
      console.error(err);
    }
    this.loadVouchersData();
    this.venueReady.next(true);
  }

  async deleteVoucher(voucher) {
      const result = confirm(`Are you sure you want to delete this voucher ( ${voucher.code} )?`);
      if (result) {
        try {
          this.deleteInProgress = true;
          await this.venueService.deleteVoucher(voucher);
          this.toasterService.pop('success', 'Voucher deleted');
          await this.ngOnInit();
        } catch (err) {
          console.error(err);
          this.toasterService.pop('error', 'Voucher delete failed');
        } finally {
          this.deleteInProgress = false;
        }
      }
  }

  async sendVoucherEmail(voucher) {
    const result = confirm(`Are you sure you want to send this voucher to  email?`);
    if (result) {
      try {
        this.emailInProgress = true;
        await this.venueService.sendVoucherEmail(voucher.id);
        this.toasterService.pop('success', 'Voucher email sent');
        await this.ngOnInit();
      } catch (err) {
        console.error(err);
        this.toasterService.pop('error', 'Voucher email failed');
      } finally {
        this.emailInProgress = false;
      }
    }
}

  async createVoucher() {
    if (!this.validateCreate()) {
      return false;
    }
    try {
      this.createInProgress = true;
      this.voucherData = this.utilityService.fillEntity(this.genericForm.myForm, this.vouchersRules, this.voucherData);
      this.voucherData.venueId = this.venue.id;
      await this.venueService.createVoucher(this.voucherData);
      this.toasterService.pop('success', 'Voucher created');
      await this.ngOnInit();
    } catch (err) {
      console.error(err);
      this.toasterService.pop('error', 'Voucher create failed.');
    } finally {
      this.createInProgress = false;
    }
  }

  createNew() {
    this.createNewVisible = true;
  }

  close() {
    this.createNewVisible = false;
  }

  private async loadVouchersData() {
    this.columns = this.getTableColumns();
    this.dtOptions = this.getTableOptions();

    this.vouchers = await this.venueService.getVouchers(this.venue.id);
    const self = this;
    setTimeout(() => {
      self.render();
    }, 0);
  }

  private render() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        this.setDatatableReady();
      });
    } else {
      this.dtTrigger.next();
      this.setDatatableReady();
    }
  }

  private setDatatableReady() {
    setTimeout(() => {
      this.datatableReady.next(true);
    }, 500);
  }

  private validateCreate() {
    this.genericForm.myForm.markAsDirty();
    return this.genericForm.myForm.valid;
  }

  getTableOptions(): any {
    return {
      order: [[0, 'desc']],
    };
  }

  getTableColumns(): ITableColumn[] {
    return [
      {
        index: 0,
        name: 'code',
        type: 'text',
        label: 'Code',
        hidden: false,
      },
      {
        index: 1,
        name: 'amount',
        type: 'text',
        label: 'Amount',
        hidden: false,
      },
      {
        index: 2,
        name: 'type',
        type: 'text',
        label: 'Type',
        hidden: false,
      },
      {
        index: 3,
        name: 'userName',
        type: 'text',
        label: 'Redeemed By',
        hidden: false,
      },
      {
        index: 4,
        name: 'used',
        type: 'text',
        label: 'Status',
        hidden: false,
      },
      {
        index: 5,
        name: 'validUntil',
        type: 'text',
        label: 'Valid until',
        hidden: false,
      },
      {
        index: 6,
        name: 'expired',
        type: 'text',
        label: 'Expired',
        hidden: false,
      },
      {
        index: 7,
        name: 'contact',
        type: 'text',
        label: 'Email',
        hidden: false,
      },
    ];
  }
}
