import { Injectable } from '@angular/core';

import { IEntityService } from '../entities/entity.service';
import { ApiService } from '../core/api.service';
import { AutomapperService } from '../core/automapper.service';
import { GenericFormType } from '../generic-form/generic-form.component';
import { VenueCreateConstraints, VenueUpdateConstraints } from './venue.constraints';
import { ITableColumn } from '../entities/ITableColumn';
import { AuthService } from '../core/auth.service';
import { UserRoles } from '../users/user.roles';

@Injectable()
export class VenueService implements IEntityService {

  constructor(
    private ApiService: ApiService,
    private AutomapperService: AutomapperService,
    private AuthService: AuthService) { }

  getName(): string {
    return 'venues';
  }

  getVenuesName(): Promise<any> {
    const user = this.AuthService.getCurrentUser();
    const url = `/user/${user.id}/venues`;
    return this.ApiService.get(url, [])
      .then((venues: any[]) => {
        const mapedVenues = [];
        venues.forEach(venue => {
          mapedVenues.push({
            id: venue.id,
            name: venue.name
          });
        });
        return mapedVenues;
      });
  }

  getAll(): Promise<any[]> {
    const user = this.AuthService.getCurrentUser();
    const url = user && user.role === UserRoles.VENDOR ? `/user/${user.id}/venues` : '/admin/venue';
    return this.ApiService.get(url, [])
      .then((venues: any[]) => {
        const mapedVenues = [];
        venues.forEach(venue => {
          mapedVenues.push(this.AutomapperService.map('VenueApi', 'VenueModel', venue));
        });
        return mapedVenues;
      });
  }

  getOne(query: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  find(query: any): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  getById(id: string): Promise<any> {
    return this.ApiService.get('/admin/venue/' + id, [])
      .then((venue) => {
        const venueMaped = this.AutomapperService.map('VenueApi', 'VenueModel', venue);
        return venueMaped;
      });
  }

  getVenueSettings(venueId: string): Promise<any> {
    return this.ApiService.get(`/venue/${venueId}/settings`, [])
  }

  updateVenueSettings(venueId: string, newRule: any, venueSettings: any): Promise<any> {
    venueSettings.paymentConfigs.push(newRule);
    const payload = {
      surcharge: venueSettings.surcharge ? venueSettings.surcharge / 100 : 0,
      paymentConfigs: venueSettings.paymentConfigs
    }
    return this.ApiService.put(`/venue/${venueId}/settings`, payload, []);
  }

  deleteCommissionRule(venueId: string, index: number, venueSettings: any) {
    venueSettings.paymentConfigs.splice(index, 1);
    const payload = {
      surcharge: venueSettings.surcharge ? venueSettings.surcharge / 100 : 0,
      paymentConfigs: venueSettings.paymentConfigs
    }
    return this.ApiService.put(`/venue/${venueId}/settings`, payload, []);
  }

  create(entity: any): Promise<any> {
    return this.ApiService.post('/venue', entity, []);
  }

  update(entity: any): Promise<any> {
    const entityMaped: any = this.AutomapperService.map('VenueModel', 'VenueApi', entity);
    return this.ApiService.put('/venue/' + entityMaped.id, entityMaped, []);
  }

  delete(entity: any): Promise<boolean> {
    // currently disabled
    // return this.ApiService.delete('/venue/' + entity.id, []);
    return;
  }

  getEvents(venueId: string): Promise<any[]> {
    return this.ApiService.get(`/event?venueId=${venueId}`, []);
  }

  getAudits(venueId: string, query = {}): Promise<any[]> {
    let queryString = '';
    Object.keys(query).forEach(item => queryString += `&${item}=${query[item]}`);
    return this.ApiService.get(`/audit?venueId=${venueId}${queryString}`, []);
  }

  getVouchers(venueId: string): Promise<any[]> {
    return this.ApiService.get(`/venue/${venueId}/voucher`, [])
      .then((vouchers: any[]) => {
        const vouchersMapped = [];
        vouchers.forEach(voucher => {
          vouchersMapped.push(this.AutomapperService.map('VoucherApi', 'VoucherModel', voucher));
        });
        return vouchersMapped;
      });
  }

  createVoucher(entity: any): Promise<any> {
    const voucherMapped: any = this.AutomapperService.map('VoucherModel', 'VoucherApi', entity);
    return this.ApiService.post('/voucher', voucherMapped, []);
  }

  deleteVoucher(entity: any): Promise<boolean> {
    return this.ApiService.delete(`/voucher/${entity.id}`, []);
  }

  sendVoucherEmail(voucherId: string): Promise<any> {
    return this.ApiService.post(`/voucher/${voucherId}/mail`, {}, []);
  }

  getVenueHappyHours(venueId): Promise<any[]> {
    return this.ApiService.get(`/venue/${venueId}/happy-hour`, [])
      .then((data: any[]) => {
        return this.AutomapperService.mapArray('HappyHourApi', 'HappyHourModel', data);
      });
  }

  async getVenuePosts(query: any): Promise<any[]> {
    const posts = await this.ApiService.post(`/posts`, query, []);
    return this.AutomapperService.mapArray("PostApi", "PostModel", posts);
  }

  async createVenuePost(entity: any): Promise<any> {
    const post = await this.ApiService.post("/post", entity, []);
    return this.AutomapperService.map("PostApi", "PostModel", post);
  }

  async uploadVenuePostImage(postId: string, payload: any): Promise<any> {
    return await this.ApiService.post('/post/' + postId + "/image", payload, [])
      .then((post) => {
        return this.AutomapperService.map("PostApi", "PostModel", post);
      });
  }

  async getVenueCharges(venueId: string): Promise<any[]> {
    const charges = await this.ApiService.get("/payment/venue/" + venueId + "/charge", []);
    return this.AutomapperService.mapArray("VenueChargeApi", "VenueChargeModel", charges);
  }

  async chargeVenue(venueId: string, payload): Promise<any> {
    return await this.ApiService.post("/payment/venue/" + venueId + "/charge", payload, []);
  }

  async uploadPhoto(venueId: string, payload: any): Promise<any> {
    const url = `/venue/${venueId}/gallery`;
    let response;
    try {
      response = await this.ApiService.post(url, payload, []);
    } catch (error) {
      throw error;
    }
    return response;
  }

  getTableNames(venueId: string): Promise<any> {
    return this.ApiService.get(`/venue/${venueId}/table-names`, []);
  }

  updateTableNames(venueId: string, payload: any): Promise<any> {
    return this.ApiService.post(`/venue/${venueId}/table-names`, payload, []);
  }

  createWaiter(venueId: string, data: any): Promise<any> {
    const payload = this.AutomapperService.map('WaiterModel', 'WaiterApi', data);
    return this.ApiService.post(`/venue/${venueId}/waiter`, payload, [])
      .then((res) => {
        return this.AutomapperService.map('WaiterApi', 'WaiterModel', res);
      });
  }

  getAllWaiters(venueId: string): Promise<any[]> {
    return this.ApiService.get(`/venue/${venueId}/waiter`, [])
      .then((res) => {
        return this.AutomapperService.mapArray('WaiterApi', 'WaiterModel', res);
      });
  }

  deleteWaiter(waiterId: string): Promise<boolean> {
    return this.ApiService.delete(`/waiter/${waiterId}`, [])
      .then((res) => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  getTableColumns(): ITableColumn[] {
    return [{
      index: 0,
      name: 'id',
      type: 'text',
      label: 'ID',
      hidden: false
    }, {
      index: 1,
      type: 'text',
      name: 'name',
      label: 'Name',
      hidden: false
    }, {
      index: 2,
      type: 'text',
      name: 'isClaimed',
      label: 'Is Claimed',
      hidden: false
    }, {
      index: 3,
      type: 'date-range',
      name: 'address',
      label: 'Address',
      hidden: false
    }, {
      index: 4,
      type: 'boolean',
      name: 'active',
      label: 'Active',
      hidden: false
    }, {
      index: 5,
      type: "text",
      name: "studentDiscount",
      label: "Offers Student Discount",
      hidden: false
    }];
  }

  getTableOptions(): any {
    return {
      order: [[0, 'desc']],
    };
  }

  getCreateConstraints(): GenericFormType[] {
    return VenueCreateConstraints;
  }
  getUpdateConstraints(): GenericFormType[] {
    return VenueUpdateConstraints;
  }
}
