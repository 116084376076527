
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { VenueService } from '../venue.service';

@Component({
  selector: 'app-venue-events',
  templateUrl: './venue-events.component.html'
})
export class VenueEventsComponent implements OnInit {

  events: any[];
  eventsReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const venueId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      this.events = await this.venueService.getEvents(venueId);
    } catch (err) {
      console.log(err);
    }
    this.eventsReady.next(true);
  }
}
