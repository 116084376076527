import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import { AutomapperService } from "../core/automapper.service";
import { IMapperRegistry } from "./mapper.registry";

@Injectable()
export class VenueSettingsMapper implements IMapperRegistry {

  constructor(private automapper: AutomapperService) {

  }

  register() {
    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('VenueApi', 'VenuePaymentConfigModel')
        .forMember('serviceFee', (opts) => opts.sourceObject.serviceFee * 100)
        .forMember('startTime', (opts) => {
          const start = moment(`${opts.sourceObject.startTime}`, 'hh:mm a').toISOString();
          return moment(start).format('hh:mm a');
        })
        .forMember('endTime', (opts) => {
          const start = moment(`${opts.sourceObject.endTime}`, 'hh:mm a').toISOString();
          return moment(start).format('hh:mm a');
        })
        .forMember('startDay', (opts) => this.indexToDay(opts.sourceObject.startDay))
        .forMember('endDay', (opts) => this.indexToDay(opts.sourceObject.endDay))
        .forMember('timeZone', (opts) => opts.sourceObject.timeZone)
    })

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('VenuePaymentConfigModel', 'VenuePaymentConfigApi')
        .forMember('serviceFee', (opts) => +opts.sourceObject.serviceFee / 100)
        .forMember('startDay', (opts) => +opts.sourceObject.startDay)
        .forMember('endDay', (opts) => +opts.sourceObject.endDay)
        .forMember('startTime', (opts) => `${opts.sourceObject.startTime}.000Z`)
        .forMember('endTime', (opts) => opts.sourceObject.endTime)
        .forMember('timeZone', (opts) => opts.sourceObject.timeZone)
    })
  }

  // leaving these 2 functions for time being if we need to revert current implemention of mapping for BE (7.5.2021.)
  private formatNYtoUTC(day: number, time: string, type: string) {
    if (day === 7) {
      day = 0;
    }
    const hours = time.slice(1, 3);
    const minutes = time.slice(4, 6);
    const momentSetter = moment().tz('America/New_York').day(day);
    momentSetter.set({ hour: +hours, minute: +minutes, second: 0, millisecond: 0 });
    if (type === 'day') {
      return this.dayToIndex(momentSetter.utc().format('dddd'));
    }
    if (type === 'time') {
      return `T${momentSetter.utc().format('HH:mm')}:00.000Z`;
    }
  }

  private formatUTCtoNY(day: number, time: string, type: string) {
    if (day === 7) {
      day = 0;
    }
    const hours = time.slice(1, 3);
    const minutes = time.slice(4, 6);
    const momentSetter = moment().utc().day(day);
    momentSetter.set({ hour: +hours, minute: +minutes, second: 0, millisecond: 0 });
    if (type === 'day') {
      return this.dayToIndex(momentSetter.tz('America/New_York').format('dddd'));
    }
    if (type === 'time') {
      return `T${momentSetter.tz('America/New_York').format('HH:mm')}:00.000Z`;
    }
  }

  private indexToDay(index: number) {
    switch (index) {
      case 1: return 'Monday';
      case 2: return 'Tuesday';
      case 3: return 'Wednesday';
      case 4: return 'Thursday';
      case 5: return 'Friday';
      case 6: return 'Saturday';
      case 7: return 'Sunday';
    }
  }

  private dayToIndex(day: string) {
    switch (day) {
      case 'Monday': return 1;
      case 'Tuesday': return 2;
      case 'Wednesday': return 3;
      case 'Thursday': return 4;
      case 'Friday': return 5;
      case 'Saturday': return 6;
      case 'Sunday': return 7;
    }
  }
}