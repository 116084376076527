import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { EventService } from '../event/event.service';
import { VenueService } from '../venue/venue.service';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
    selector: 'app-dashboard-table-orders',
    templateUrl: './dashboard.table.orders.component.html'
})
export class DashboardTableOrdersComponent implements OnInit {

    event: any;
    invoice: any;

    constructor(
        public bsModalRef: BsModalRef,
        private eventService: EventService) { }

    async ngOnInit() {
        this.invoice = await this.eventService.getEventInvoice(this.event.id);
    }

    formatItemCreatedAt(createdAt: string) {
        const time = moment(createdAt).format('DD/MM/YYYY');
        return time;
    }

    cancel() {
        this.bsModalRef.hide();
    }

    getItemsTotal() {
        let total = 0;
        this.invoice.items.forEach((x) => {
            total += x.price;
        });
        return total.toFixed(2);
    }

    deleteOrder(item: any) {
        const res = confirm(`Are you sure you want to delete ${item.name}?`);
        if (res) {
            this.eventService.deleteOrder(item.orderId)
                .then(async () => {
                    this.invoice = await this.eventService.getEventInvoice(this.event.id);
                });
        }
    }
}
