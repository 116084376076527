import { NgModule } from '@angular/core';
import { UserMapper } from './user.mapper';
import { EventMapper } from './event.mapper';
import { VenueMapper } from './venue.mapper';
import { VoucherMapper } from './voucher.mapper';
import { UserReportMapper } from './userReport.mapper';
import { MenuMapper } from './menu.mapper';
import { MenuItemMapper } from './menu-item.maper';
import { HappyHourMapper } from './happy-hour.mapper';
import { WorkingWeekMapper } from './working-week.mapper';
import { WaiterMapper } from './waiter.mapper';
import { VenueSettingsMapper } from './venue-settings.mapper';
import { PostMapper } from './post.mapper';
import { VenueChargesMapper } from './venue-charges.mapper';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        UserMapper,
        EventMapper,
        VenueMapper,
        VoucherMapper,
        UserReportMapper,
        MenuMapper,
        MenuItemMapper,
        HappyHourMapper,
        WorkingWeekMapper,
        WaiterMapper,
        VenueSettingsMapper,
        PostMapper,
        VenueChargesMapper
    ],
})
export class MappersModule {

    constructor(
        private userMapper: UserMapper,
        private eventMapper: EventMapper,
        private venueMapper: VenueMapper,
        private voucherMapper: VoucherMapper,
        private userReportMapper: UserReportMapper,
        private menuMapper: MenuMapper,
        private menuItemMapper: MenuItemMapper,
        private happyHourMapper: HappyHourMapper,
        private workingWeekMapper: WorkingWeekMapper,
        private waiterMapper: WaiterMapper,
        private venueSettingsMapper: VenueSettingsMapper,
        private postMapper: PostMapper,
        private venueChargesMapper: VenueChargesMapper,
    ) {
        this.userMapper.register();
        this.eventMapper.register();
        this.venueMapper.register();
        this.voucherMapper.register();
        this.userReportMapper.register();
        this.menuMapper.register();
        this.menuItemMapper.register();
        this.happyHourMapper.register();
        this.workingWeekMapper.register();
        this.waiterMapper.register();
        this.venueSettingsMapper.register();
        this.postMapper.register();
        this.venueChargesMapper.register();
    }
}
