import { GenericFormType } from '../generic-form/generic-form.component';
import { Validators } from '@angular/forms';
import { GenericFormBuilder } from '../generic-form/generic-form.builder';

export const UserCreateConstraints: GenericFormType[] = [
  GenericFormBuilder.text('firstName', 'First Name (required)', [Validators.required]),
  GenericFormBuilder.text('lastName', 'Last Name (required)', [Validators.required]),
  GenericFormBuilder.text('email', 'Email (required)', [Validators.required]),
  GenericFormBuilder.password('password', 'Password (required)', [Validators.required]),
  GenericFormBuilder.dropdown('role', 'Role', [], [
    { value: 'ADMIN', label: 'Admin', selected: true },
    { value: 'REGULAR', label: 'Regular' },
    { value: 'VENDOR', label: 'Vendor' },
    { value: 'POTENTIAL', label: 'Potential' },
    { value: 'GUEST', label: 'Guest' },
    { value: 'TEMPORARY', label: 'Temporary' }
  ]),
  GenericFormBuilder.text('phone', 'Phone', []),
  GenericFormBuilder.dropdown('gender', 'Gender', [], [
    { value: null, label: '', selected: true },
    { value: 'male', label: 'Male', },
    { value: 'female', label: 'Female' },
    { value: 'nonbinary', label: 'Nonbinary', }
  ]),
];

export const UserPasswordConstraints: GenericFormType[] = [
  GenericFormBuilder.password('newPassword', 'New Password (required)', [Validators.required]),
  GenericFormBuilder.password('repeatNewPassword', 'Repeat New Password (required)', [Validators.required]),
];

export const UserUpdateConstraints: GenericFormType[] = [
  GenericFormBuilder.text('firstName', 'First Name (required)', [Validators.required]),
  GenericFormBuilder.text('lastName', 'Last Name (required)', [Validators.required]),
  GenericFormBuilder.text('email', 'Email (required)', [Validators.required]),
  GenericFormBuilder.dropdown('role', 'Role', [], [
    { value: 'ADMIN', label: 'Admin', selected: true },
    { value: 'REGULAR', label: 'Regular' },
    { value: 'VENDOR', label: 'Vendor' },
    { value: 'POTENTIAL', label: 'Potential' },
    { value: 'GUEST', label: 'Guest' },
    { value: 'TEMPORARY', label: 'Temporary' }
  ]),
  GenericFormBuilder.text('createdAt', 'Created At', [], true),
  GenericFormBuilder.text('phone', 'Phone', []),
  GenericFormBuilder.dropdown('phoneNumberVerified', 'Phone Number Verified', [], [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ]),
  GenericFormBuilder.text('credit', 'Credit', []),
  GenericFormBuilder.text('birthDay', 'Birthday', []),
  GenericFormBuilder.text('referredBy', 'Referred By', [], true),
  GenericFormBuilder.text('ssn', 'Social Security number', []),
  GenericFormBuilder.text('homeNeighborhood', 'Home Neighborhood', []),
  GenericFormBuilder.text('workNeighborhood', 'Work Neighborhood', []),
  GenericFormBuilder.dropdown('gender', 'Gender', [], [
    { value: null, label: '', selected: true },
    { value: 'male', label: 'Male', },
    { value: 'female', label: 'Female' },
    { value: 'nonbinary', label: 'Nonbinary', }
  ]),
  GenericFormBuilder.text('relationshipStatus', 'Relationship Status', []),
  GenericFormBuilder.text('occupation', 'Occupation', []),
  GenericFormBuilder.dropdown('active', 'Visibility', [], [
    { value: 'Visible', label: 'Visible' },
    { value: 'Hidden', label: 'Hidden' }
  ]),
];

export const VenueOwnerConstraints: GenericFormType[] = [
  GenericFormBuilder.text('firstName', 'First Name', [], true),
  GenericFormBuilder.text('lastName', 'Last Name', [], true),
  GenericFormBuilder.text('email', 'Email', [], true),
];

export const AmbassadorCodesConstraints: GenericFormType[] = [
  GenericFormBuilder.datepicker('fromDate', "From", [Validators.required]),
  GenericFormBuilder.datepicker('toDate', "To", [Validators.required]),
];
