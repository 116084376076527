import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { IEntityService } from '../entities/entity.service';
import { ApiService } from '../core/api.service';
import { GenericFormType } from '../generic-form/generic-form.component';
import { ITableColumn } from '../entities/ITableColumn';

@Injectable()
export class EnumService implements IEntityService {

  private enumCateogires: any = {};

  constructor(
    private ApiService: ApiService) { }

  getName(): string {
    return 'enums';
  }

  getAll(): Promise<any[]> {
    return this.ApiService.get('/enums/types', [])
      .then((users: any[]) => {
        return users;
      });
  }

  getOne(query: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  find(query: any): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  getById(id: string): Promise<any> {
    return this.ApiService.get('/enums/' + id, []);
  }

  create(entity: any): Promise<any> {
    return this.ApiService.post('/enums/' + entity.type, entity, []);
  }

  update(entity: any): Promise<any> {
    return this.ApiService.put('/enums/' + entity.id, entity, []);
  }

  delete(entity: any): Promise<boolean> {
    return this.ApiService.delete('/enums/' + entity.id, []);
  }

  getTableColumns(): ITableColumn[] {
    return [{
      index: 0,
      name: 'index',
      type: 'text',
      label: 'ID',
      hidden: false
    }, {
      index: 1,
      type: 'text',
      name: 'id',
      label: 'Name',
      hidden: false
    }];
  }

  getTableOptions(): any {
    return {};
  }

  getCreateConstraints(): GenericFormType[] {
    return [{
      type: 'control', name: 'key', label: 'Key', inputType: 'text',
      placeHolder: 'Key', validators: [Validators.required]
    },
    {
      type: 'control', name: 'value', label: 'Value', inputType: 'text',
      placeHolder: 'Value', validators: [Validators.required]
    }];
  }
  getUpdateConstraints(): GenericFormType[] {
    return [];
  }

  async getByType(type: string) {
    if (this.enumCateogires[type]) {
      return this.enumCateogires[type];
    }
    this.enumCateogires[type] = await this.ApiService.get(`/enums/${type}`, []);
    return this.enumCateogires[type];
  }
}
