
import {filter} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { EnumService } from '../enums/enums.service';
import { User } from '../shared/user';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './layout.navigation.component.html'
})
export class NavigationComponent implements OnInit {
  @Input() thinNavigation: BehaviorSubject<boolean>;

  activeCategory: string;
  categoryOpened: string;
  sideMenuConfig: any[] = [];
  currentUser: User;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService) { }

  async ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    this.getSideMenuConfig(this.router.config);
    this.setCurrentCategory(this.activatedRoute);
    setTimeout(() => {
    }, 2000);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.setCurrentCategory(this.activatedRoute);
    });
  }

  isMenuCategoryActive(category: string) {
    return this.activeCategory === category;
  }

  openCategory(category: string) {
    if (this.categoryOpened === category) {
      this.categoryOpened = null;
    } else {
      this.categoryOpened = category;
    }
  }

  closeCategory() {
    this.categoryOpened = null;
  }

  closeOpenedCategory() {
    this.categoryOpened = null;
  }

  private setCurrentCategory(route: ActivatedRoute) {
    while (route.firstChild) {
      route = route.firstChild;
    }
    this.activeCategory = route.snapshot.data['category'];
  }

  private getSideMenuConfig(routes: any[]): void {
    routes.forEach((route) => {
      if (route.data && route.data.sideMenuConfig) {
        if (route.data.access && route.data.access.length && route.data.access.indexOf(this.currentUser.role) !== -1) {
          this.sideMenuConfig.push(route.data.sideMenuConfig);
        } else if (!route.data.access || !route.data.access.length) {
          this.sideMenuConfig.push(route.data.sideMenuConfig);
        }
      }
    });
    this.sideMenuConfig.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
  }
}
