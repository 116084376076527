import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPremiumSubscription } from './IPremiumSubscription';
import { PremiumSubscriptionsService } from './premium-subscriptions.service';

@Component({
  selector: 'app-premium-subscriptions',
  templateUrl: './premium-subscriptions.component.html',
  styleUrls: ['./premium-subscriptions.component.scss']
})
export class PremiumSubscriptionsComponent implements OnInit {

  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  subscriptions: IPremiumSubscription[];
  users: any[];
  FilteredSubs: any[];
  filteredUsers: any[];
  selectedUser: string = null;
  searchAllSubs: string = '';

  constructor(private premiumService: PremiumSubscriptionsService) { }

  ngOnInit() {
    this.loadEntitiesData();
  }

  private async loadEntitiesData() {
    this.entityReady.next(false);
    this.subscriptions = await this.premiumService.getAllSubscriptions();
    this.FilteredSubs = this.subscriptions;
    this.entityReady.next(true);
  }

  async toggleShowExtra(item: any) {
    if (this.selectedUser === item.customerId) {
      this.selectedUser = null;
    } else {
      this.selectedUser = item.customerId;
    }
  }

  async filterAllSubs() {
    this.FilteredSubs = this.subscriptions.filter(item => new RegExp(this.searchAllSubs, 'i').test(item.userEmail));
  }

}
