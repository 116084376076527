import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { BehaviorSubject } from "rxjs";
import { ITableColumn } from "../../entities/ITableColumn";
import { postDataColumns } from "../../post/post.constraints";
import { VenueService } from "../venue.service";
import { VenuePostCreateComponent } from "./venue-post-create.component";

@Component({
    selector: 'app-venue-posts',
    templateUrl: './venue-posts.component.html'
})
export class VenuePostsComponent implements OnInit {

    venue: any;
    posts: any[];
    datatableReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    searchForm: FormGroup;
    columns: ITableColumn[] = postDataColumns;
    dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
    };
    showCreateButton = true;
    bsModalRef: BsModalRef;

    constructor(
        private venueService: VenueService,
        private route: ActivatedRoute,
        private modalService: BsModalService,
    ) {
    }
    async ngOnInit() {
        const id: string = this.route.parent.snapshot.paramMap.get('id');
        this.venue = await this.venueService.getById(id);
        await this.fetchPosts();
        this.fillTableData();
    }

    async fetchPosts() {
        this.posts = await this.venueService.getVenuePosts({ query: { "createdBy.creatorId": this.venue.id } });
    }

    fillTableData() {
        setTimeout(() => {
            this.datatableReady.next(true);
        }, 500);
    }

    async createNew() {
        const initialState = {
            venue: this.venue,
            fetchPosts: this.fetchPosts.bind(this)
        };
        this.bsModalRef = this.modalService.show(VenuePostCreateComponent, { initialState });
        this.bsModalRef.setClass('modal-lg');
    }
}
