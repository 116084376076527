import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { GenericFormBuilder } from '../../generic-form/generic-form.builder';
import { GenericFormType } from '../../generic-form/generic-form.component';

@Component({
  selector: 'app-event-entrance-fee-info-component',
  templateUrl: './event-entrance-fee-info.component.html',
})
export class EventEntranceFeeInfoComponent implements OnInit {

  entranceCharge: any;
  entranceFeeRules: GenericFormType[];

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  async ngOnInit() {
    this.entranceCharge = this.mapEntranceFeeData(this.entranceCharge);
    this.entranceFeeRules = [GenericFormBuilder.json('data', null, [], this.entranceCharge)];
  }

  cancel() {
    this.bsModalRef.hide();
  }

  private mapEntranceFeeData(entranceCharge: any): any {
    return {
      id: entranceCharge.id,
      userId: entranceCharge.userId,
      eventId: entranceCharge.eventId,
      description: entranceCharge.charge.description,
      amount: entranceCharge.charge.chargedAmount,
      stripeId: entranceCharge.charge.id,
      cardBrand: entranceCharge.charge.cardBrand,
      cardLast4: entranceCharge.charge.cardLast4,
      ticketCode: entranceCharge.charge.code,
      delivery: entranceCharge.shippingInfo,
      // shippingState: entranceCharge.shippingInfo && entranceCharge.shippingInfo.length ? entranceCharge.shippingInfo[0].state : '',
      // shippingZip: entranceCharge.shippingInfo && entranceCharge.shippingInfo.length ? entranceCharge.shippingInfo[0].zipCode : '',
      // shippingAddress: entranceCharge.shippingInfo && entranceCharge.shippingInfo.length ? entranceCharge.shippingInfo[0].address : '',
      // tslint:disable-next-line:max-line-length
      // shippingApartment: entranceCharge.shippingInfo && entranceCharge.shippingInfo.length ? entranceCharge.shippingInfo[0].apartment : '',
      // shippingPhone: entranceCharge.shippingInfo && entranceCharge.shippingInfo.length ? entranceCharge.shippingInfo[0].phone : '',
      // items: entranceCharge.shippingInfo && entranceCharge.shippingInfo.length ? entranceCharge.shippingInfo[0].items : [],
      // tslint:disable-next-line:max-line-length
      participateInOnlineEvent: entranceCharge.shippingInfo && entranceCharge.shippingInfo.length ? entranceCharge.shippingInfo.interestedInVirtualEvent : ''
    };
  }
}
