import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-generic-group',
    templateUrl: './generic-group.component.html'
})
export class GenericGroupComponent {
    @Input('field') field;

    @Input('formGroup') formGroup;

    @Output() dataChange: EventEmitter<any> = new EventEmitter<any>()

    toRaw(): any {
        const raw = {};
        Object.keys(this.formGroup.controls).forEach(key => {
            const value = this.formGroup.get(key).value;
            raw[key] = value;
        });
        return raw;
    }

    formChanged() {
        const rawValues = this.toRaw();
        this.dataChange.emit(rawValues)
    }

}