import { Injectable } from '@angular/core';
import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../core/automapper.service';
import { UserApiRoles, UserModelRoles } from '../shared/user';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { environment } from '../../environments/environment';

@Injectable()
export class PostMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('PostApi', 'PostModel')
                .forMember('creatorName', (opts) => !!opts.sourceObject.createdBy && !!opts.sourceObject.createdBy.name ? opts.sourceObject.createdBy.name : "")
                .forMember('creatorType', (opts) => !!opts.sourceObject.createdBy && !!opts.sourceObject.createdBy.type ? opts.sourceObject.createdBy.type : "")
                .forMember('content', (opts) => opts.sourceObject.content)
                .forMember('mainImage', (opts) => opts.sourceObject.mainImage ? opts.sourceObject.mainImage : null)
                .forMember('createdAt', (opts) => momentTZ(opts.sourceObject.createdAt).tz('America/New_York').format('YYYY-MM-DD ( a hh:mm )'))
                .forMember('eventStartDate', (opts) => opts.sourceObject.eventStartDate ? momentTZ(opts.sourceObject.eventStartDate).tz('America/New_York').format('MM/DD/YYYY') : null)
                .forMember('id', (opts) => opts.sourceObject.id)
                .forMember('createdAt', (opts) => momentTZ(opts.sourceObject.createdAt).tz('America/New_York').format('YYYY-MM-DD ( a hh:mm )'))
                .forMember('createdBy', (opts) => opts.sourceObject.createdBy)
                .forMember('title', (opts) => opts.sourceObject.title ? opts.sourceObject.title : "")
                .ignoreAllNonExisting();
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('PostModel', 'PostApi')
                .forMember('content', (opts) => opts.sourceObject.content)
                .forMember('mainImage', (opts) => opts.sourceObject.mainImage ? opts.sourceObject.mainImage : null)
                .forMember('eventStartDate', (opts) => opts.sourceObject.eventStartDate ? momentTZ(opts.sourceObject.eventStartDate).tz('America/New_York').toISOString() : null)
                .forMember('id', (opts) => opts.sourceObject.id)
                .forMember('createdBy', (opts) => opts.sourceObject.createdBy)
                .forMember('title', (opts) => opts.sourceObject.title ? opts.sourceObject.title : null)
                .ignoreAllNonExisting();
        });
    }
}
