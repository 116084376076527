
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '../event.service';
import { UserService } from '../../users/user.service';

@Component({
  selector: 'app-event-participants',
  templateUrl: './event-participants.component.html'
})
export class EventParticipantsComponent implements OnInit {

  participants: any[];
  invitations: any[];
  waitlist: any[];
  dataReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private eventService: EventService,
    private userService: UserService,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const eventId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      const event = await this.eventService.getById(eventId);
      // tslint:disable-next-line:max-line-length
      const reservationInfo = await this.eventService.getEventsInfo(event.venueId, { id: event.id }, ['orders', 'participants', 'waitlist', 'invitedParticipants'], true);
      this.invitations = reservationInfo[0].invitedParticipants || [];
      this.participants = reservationInfo[0].participants;
      this.waitlist = reservationInfo[0].waitlist || [];
    } catch (err) {
      console.log(err);
    }
    this.dataReady.next(true);
  }
}
