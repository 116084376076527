import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionsService } from './subscriptions.service';
import { SharedModule } from '../shared/shared.module';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { UserRoles } from '../users/user.roles';
import { SubscriptionsComponent } from './subscriptions.component';
import { AuthenticatedGuard } from '../core/authenticated.guard';



@NgModule({
  declarations: [SubscriptionsComponent],
  providers: [SubscriptionsService],
  imports: [
    CommonModule,
    SharedModule,
    LaddaModule,
    GenericFormModule,
    FormsModule,
    RouterModule.forChild([{
      path: 'subscriptions',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/subscriptions',
          icon: 'glyphicon-usd',
          label: 'Subscriptions',
          category: 'subscriptions',
          index: 8
        },
        access: [UserRoles.ADMIN]
      },
      children: [
        {
          path: '',
          component: SubscriptionsComponent,
          canActivate: [AuthenticatedGuard],
          data: { title: 'Subscriptions', category: 'subscriptions',  }
        },
      ]
    }])
  ],
})
export class SubscriptionsModule { }
