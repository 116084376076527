import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { AutomapperService } from '../core/automapper.service';

@Injectable()
export class HappyHourService {

  constructor(
    private apiService: ApiService,
    private automapperService: AutomapperService) {}

  getVenueHappyHours(venueId): Promise<any[]> {
    return this.apiService.get(`/venue/${venueId}/happy-hour`, [])
      .then((data: any[]) => {
        return this.automapperService.mapArray<any>('HappyHourApi', 'HappyHourModel', data);
      });
  }

  create(data: any): Promise<any> {
    const payload = this.automapperService.map('HappyHourModel', 'HappyHourApi', data);
    return this.apiService.post('/happy-hour', payload, [])
      .then((res: any) => {
        return this.automapperService.map<any>('HappyHourApi', 'HappyHourModel', res);
      });
  }

  update(data: any): Promise<any> {
    const payload = this.automapperService.map('HappyHourModel', 'HappyHourApi', data);
    return this.apiService.put(`/happy-hour/${data.id}`, payload, [])
      .then((res: any) => {
        return this.automapperService.map<any>('HappyHourApi', 'HappyHourModel', res);
      });
  }

  delete(data: any): Promise<any> {
    return this.apiService.delete(`/happy-hour/${data.id}`, [])
      .then((res: any) => {
        return this.automapperService.map<any>('HappyHourApi', 'HappyHourModel', res);
      });
  }

  async uploadPhoto(hhId: string, payload: any): Promise<any> {
    const url = `/happy-hour/${hhId}/image`;
    let response;
    try {
      response = await this.apiService.post(url, payload, []);
    } catch (error) {
      throw error;
    }
    return response;
  }
}
