import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostService } from './post.service';
import { EntityListComponent } from '../entities/entity-list/entity-list.component';
import { PostServiceResolver } from './post.service.resolver';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { EntityEditComponent } from '../entities/entity-edit/entity-edit.component';
import { DummyComponent } from '../shared/dummy.component';
import { EntityCreateComponent } from '../entities/entity-create/entity-create.component';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { SharedModule } from '../shared/shared.module';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { UserRoles } from '../users/user.roles';
import { PostImageComponent } from './post-image/post-image.component';
import { FileUploadModule } from '../file.upload/file.upload.module';
import { FileUploadComponent } from '../file.upload/file.upload.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LaddaModule,
        FileUploadModule,
        GenericFormModule,
        RouterModule.forChild([{
            path: 'posts',
            component: LayoutComponent,
            data: {
                sideMenuConfig: {
                    route: '/posts',
                    icon: 'glyphicon-th-list',
                    label: 'Posts',
                    category: 'posts',
                    index: 6
                },
                access: [UserRoles.ADMIN]
            },
            children: [
                {
                    path: '',
                    component: EntityListComponent,
                    canActivate: [AuthenticatedGuard],
                    resolve: { service: PostServiceResolver },
                    data: { category: 'posts', title: 'Posts' }
                },
                {
                    path: ':id',
                    component: EntityEditComponent,
                    canActivate: [AuthenticatedGuard],
                    resolve: { service: PostServiceResolver },
                    data: {
                        category: 'posts',
                        title: 'Post Info',
                        tabs: [{
                            path: 'image',
                            label: 'Image'
                        }]
                    },
                    children: [{
                        path: 'image',
                        component: PostImageComponent,
                        canActivate: [AuthenticatedGuard],
                        data: { category: 'image', title: 'Post main image' }
                    },
                    ]
                }
            ]
        }])
    ],
    declarations: [PostImageComponent],
    providers: [PostService, PostServiceResolver]
})
export class PostsModule { }
