import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormComponent } from './generic-form.component';
import { GenericInputRowComponent } from './generic-input-row.component';
import { BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { SpinnerModule } from 'primeng/spinner';
import { MultiSelectModule } from 'primeng/multiselect';
import { JSONEditorModule } from 'ngx-jsoneditor';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CoreModule } from '../core/core.module';
import { GenericGroupComponent } from './generic-group.component';
import { GenericArrayComponent } from './generic-array.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LaddaModule,
    BsDatepickerModule,
    AutoCompleteModule,
    DropdownModule,
    InputTextModule,
    CheckboxModule,
    SpinnerModule,
    JSONEditorModule,
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(13, 212, 12)',
      checkedLabel: 'ON',
      uncheckedLabel: 'OFF'
    }),
    NgxIntlTelInputModule,
    BsDatepickerModule,
    MultiSelectModule,
    TimepickerModule,
    EditorModule
  ],
  declarations: [GenericInputRowComponent, GenericGroupComponent, GenericArrayComponent, GenericFormComponent],
  exports: [GenericInputRowComponent, GenericGroupComponent, GenericArrayComponent, GenericFormComponent]
})
export class GenericFormModule { }
