import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventService } from './event.service';
import { EntityListComponent } from '../entities/entity-list/entity-list.component';
import { EventServiceResolver } from './event.service.resolver';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { EntityEditComponent } from '../entities/entity-edit/entity-edit.component';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { EventParticipantsComponent } from './event-participants/event-participants.component';
import { UserService } from '../users/user.service';
import { EventOrdersComponent } from './event-orders/event-orders.component';
import { EventPaymentsComponent } from './event-payments/event-payments.component';
import { SharedModule } from '../shared/shared.module';
import { EventEntranceFeesComponent } from './event-entrance-fees/event-entrance-fees.component';
import { UserRoles } from '../users/user.roles';
import { EventEntranceFeeInfoComponent } from './event-entrance-fees/event-entrance-fee-info.component';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { EventMeetingInfoComponent } from './event-meeting/event-meeting-info.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GenericFormModule,
    RouterModule.forChild([{
      path: 'events',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/events',
          icon: 'glyphicon glyphicon-earphone',
          label: 'Reservations',
          category: 'events',
          index: 2
        },
        access: [UserRoles.ADMIN]
      },
      children: [
        {
          path: '',
          component: EntityListComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: EventServiceResolver },
          data: { category: 'events', title: 'Reservations' , disableCreateEntity: true}
        },
        {
          path: ':id',
          component: EntityEditComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: EventServiceResolver },
          data: {
            category: 'events',
            title: 'Reservation Info',
            tabs: [{
              path: 'participants',
              label: 'Participants'
            }, {
              path: 'orders',
              label: 'Orders'
            }, {
              path: 'payments',
              label: 'Payments'
            }, {
              path: 'entrance-charges',
              label: 'Tickets'
            }, {
              path: 'online-event',
              label: 'Online Event'
            }]
          },
          children: [{
            path: 'participants',
            component: EventParticipantsComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'events', title: 'Reservation Participants' }
          }, {
            path: 'orders',
            component: EventOrdersComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'events', title: 'Reservation Orders' }
          }, {
            path: 'payments',
            component: EventPaymentsComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'events', title: 'Reservation Payments' }
          }, {
            path: 'entrance-charges',
            component: EventEntranceFeesComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'events', title: 'Reservation Entrance Charges' }
          }, {
            path: 'online-event',
            component: EventMeetingInfoComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'events', title: 'Reservation Online Event' }
          }]
        }
      ]
    }])
  ],
  // tslint:disable-next-line:max-line-length
  declarations: [EventParticipantsComponent, EventOrdersComponent, EventPaymentsComponent, EventEntranceFeesComponent, EventEntranceFeeInfoComponent, EventMeetingInfoComponent],
  entryComponents: [EventEntranceFeeInfoComponent],
  providers: [EventService, EventServiceResolver, UserService]
})
export class EventsModule {}
