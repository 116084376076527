import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../users/user.service';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserPasswordConstraints } from '../user.constraints';
import { ToasterService } from 'angular2-toaster';
import { GenericFormType, GenericFormComponent } from '../../generic-form/generic-form.component';
import { UtilityService } from '../../core/utility.service';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html'
})
export class UserPasswordComponent implements OnInit {

  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;

  user: any;
  changePasswordInProgress: boolean;
  passwordData: any;
  passwordRules: GenericFormType[];
  userReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private userService: UserService,
    private toasterService: ToasterService,
    private utilityService: UtilityService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    const userId: string = this.route.parent.snapshot.paramMap.get('id');
    this.passwordRules = UserPasswordConstraints;
    this.changePasswordInProgress = false;
    this.passwordData = {};
    try {
      this.user = await this.userService.getById(userId);
    } catch (err) {
      console.log(err);
    }
    this.userReady.next(true);
  }

  async changePassword() {
    if (!this.validateCreate()) {
      return false;
    }
    if (this.genericForm.myForm.value.newPassword !== this.genericForm.myForm.value.repeatNewPassword) {
      this.toasterService.pop('error', 'Passwords do not match');
      return false;
    }
    try {
      this.changePasswordInProgress = true;
      this.passwordData = this.utilityService.fillEntity(this.genericForm.myForm, this.passwordRules, this.passwordData);
      this.passwordData.userId = this.user.id;
      await this.userService.changePassword(this.passwordData, this.user.id);
      this.toasterService.pop('success', 'Password successfully changed');
      await this.ngOnInit();
    } catch (err) {
      console.log(err);
      this.toasterService.pop('error', 'Password change failed');
    } finally {
      this.changePasswordInProgress = false;
    }
  }

  private validateCreate() {
    this.genericForm.myForm.markAsDirty();
    return this.genericForm.myForm.valid;
  }
}
