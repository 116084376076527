import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { SummaryTypes } from "../summaries.constraints";
import { SummeriesService } from "../summaries.service";

@Component({
    selector: "app-monthly-summary",
    templateUrl: "./monthly-summary.component.html"
})
export class MonthlySummaryComponent implements OnInit {

    monthOptions: any[] = [];
    periodOptions: any[] = [];
    selectedMonth: any;
    selectedPeriod: any;
    generateVenueInProgress = false;
    generateUserInProgress = false;
    generateCuculiInProgress = false;
    generateOrganizerInProgress = false;
    timeZone = "America/New_York";
    summary: any;
    summaryTypes = SummaryTypes;
    currentSummaryType: string;
    columnHeaders: Array<any>;
    dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
    }

    constructor(
        private SummariesService: SummeriesService
    ) { }

    ngOnInit() {
        this.initMonthOptions();
        this.initPeriodOptions();
    }

    async generateSummary(type: string) {
        this[`generate${type}InProgress`] = true;
        this.summary = null;
        this.currentSummaryType = type;
        this.summary = await this.apiCall(null, type === SummaryTypes.USER ? this.selectedPeriod : this.selectedMonth, type);
        const rows = this.summary.csv.split("\n");
        this.columnHeaders = rows[1].split(",");
        this[`generate${type}InProgress`] = false;
    }

    downloadFile() {
        if (!this.summary || !this.summary.csv || !this.currentSummaryType) {
            return;
        }
        const filename = `${this.SummariesService.formatDate()}-${this.currentSummaryType.toLowerCase()}${this.currentSummaryType !== SummaryTypes.USER ? "-monthly" : ""}-summary-for-${this.SummariesService.formatDate(this.selectedMonth, "MM-YYYY")}.csv`;
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(this.summary.csv));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    initMonthOptions() {
        const firstDate = moment("2020-01-15").tz(this.timeZone).endOf("month").endOf("day");
        const lastDate = moment.tz(this.timeZone).date(0).endOf("day");
        const iterations = lastDate.diff(firstDate, "months");
        for (let i = 0; i <= iterations; i++) {
            const current = moment(lastDate).subtract(i, "month");
            this.monthOptions.push({
                label: current.format("MM/YYYY"),
                value: current.toISOString(),
            })
        }
        this.selectedMonth = this.monthOptions[0].value;
    }

    initPeriodOptions() {
        this.periodOptions = [
            {
                label: "Last Month",
                value: moment.tz(this.timeZone).subtract(1, "month").startOf("month").toISOString(),
            },
            {
                label: "Last 3 months",
                value: moment.tz(this.timeZone).subtract(4, "month").startOf("month").toISOString(),
            },
            {
                label: "Last 6 months",
                value: moment.tz(this.timeZone).subtract(7, "months").startOf("month").toISOString(),
            },
            {
                label: "Last 12 months",
                value: moment.tz(this.timeZone).subtract(13, "months").startOf("month").toISOString(),
            },
            {
                label: "All time",
                value: moment("2018-01-15").tz(this.timeZone).startOf("year").toISOString(),
            }
        ];
        this.selectedPeriod = this.periodOptions[0].value;
    }

    async apiCall(ids: string[], date: string, type: string) {
        if (type === SummaryTypes.ORGANIZER) {
            return await this.SummariesService.generateOrganizerMonthlySummary(ids, date);
        }
        if (type === SummaryTypes.VENUE) {
            return await this.SummariesService.generateVenueMonthlySummary(ids, date);
        }
        if (type === SummaryTypes.CUCULI) {
            return await this.SummariesService.generateCuculiMonthlySummary(date);
        }
        if (type === SummaryTypes.USER) {
            const period = this.periodOptions.find((p) => p.value === date);
            return await this.SummariesService.generateUserSummary(date, !!period && period.label === "All time" ? moment().toISOString() : null);
        }
    }

    mapSummaryLine(line: any) {
        if (this.currentSummaryType === SummaryTypes.ORGANIZER) {
            return this.SummariesService.organizerMappedLine(line);
        }
        if (this.currentSummaryType === SummaryTypes.VENUE) {
            return this.SummariesService.venueMappedLine(line);
        }
        if (this.currentSummaryType === SummaryTypes.CUCULI) {
            return this.SummariesService.cuculiMappedLine(line);
        }
        if (this.currentSummaryType === SummaryTypes.USER) {
            return this.SummariesService.userMappedLine(line);
        }
        return [];
    }
}
