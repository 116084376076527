import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable()
export class AuthenticatedGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      console.log('user => ', this.authService.user);
      console.log('route => ', next);

      if (this.authService.user) {
        if (next.data && next.data.access && next.data.access.length && next.data.access.indexOf(this.authService.user.role) === -1) {
          this.authService.logout();
          this.router.navigateByUrl('login');
          return false;
        }
        // if (state.url === '/') {
        //   this.router.navigateByUrl('users');
        // }
        return true;
      }
      this.router.navigateByUrl('login');
      return false;
  }
}
