import { Validators } from "@angular/forms";
import { ITableColumn } from "../../entities/ITableColumn";
import { GenericFormBuilder } from "../../generic-form/generic-form.builder";
import { GenericFormType, Lookup } from "../../generic-form/generic-form.component";
import * as moment from "moment";

export const VenueChargeDataColumns: ITableColumn[] = [
    {
        index: 0,
        type: "text",
        name: "createdAt",
        label: "Charged At",
        hidden: false,
    },
    {
        index: 1,
        type: "text",
        name: "chargeDate",
        label: "Charge for (Month)",
        hidden: false,
    },
    {
        index: 2,
        type: "text",
        name: "chargeType",
        label: "Charge Type",
        hidden: false,
    },
    {
        index: 3,
        type: "number",
        name: "amount",
        label: "Amount ($)",
        hidden: false,
    },
    {
        index: 4,
        type: "text",
        name: "description",
        label: "Description",
        hidden: false,
    }
];

export const ChargeTypes: Lookup[] = [
    { label: "Subscription", value: "subscription" },
    { label: "Refund", value: "refund" },
    { label: "Other", value: "other" },
];

const getMonthLookUps = (): Lookup[] => {
    const lookups: Lookup[] = [{ label: "", value: null }];
    const timeZone = "America/New_York";
    const earliest = moment.tz("2021-01-01", timeZone).startOf("month");
    const latest = moment.tz(timeZone).startOf("month");
    const iterations = latest.diff(earliest, "month");
    for (let i = 0; i <= iterations; i++) {
        const current = moment(latest).subtract(i, "month");
        lookups.push({
            label: current.format("MMMM, YYYY"),
            value: current.toISOString(),
        })
    }
    return lookups;
}

export const createVenueChargeConstraints: GenericFormType[] = [
    GenericFormBuilder.dropdown("chargeDate", "Charge Month", [], getMonthLookUps()),
    GenericFormBuilder.dropdown("chargeType", "Charge Type", [], ChargeTypes),
    GenericFormBuilder.number("amount", "Amount", [Validators.required], false, null, null, "0.01"),
    GenericFormBuilder.textarea("description", "Description", []),
];
