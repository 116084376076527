import { Validators } from '@angular/forms';
import { GenericFormBuilder } from '../generic-form/generic-form.builder';
import { GenericFormControl, GenericFormType } from '../generic-form/generic-form.component';
import { HourSlots } from '../shared/hour.slots';

export const HappyHourDayConstraints: GenericFormControl[] = [
    GenericFormBuilder.dropdown('startDay', 'Start Day', [], [
        { label: 'Select day', value: null },
        { label: 'Monday', value: 'monday' },
        { label: 'Tuesday', value: 'tuesday' },
        { label: 'Wednesday', value: 'wednesday' },
        { label: 'Thursday', value: 'thursday' },
        { label: 'Friday', value: 'friday' },
        { label: 'Saturday', value: 'saturday' },
        { label: 'Sunday', value: 'sunday' }
    ]),
    GenericFormBuilder.dropdown('endDay', 'End Day', [], [
        { label: 'Select day', value: null },
        { label: 'Monday', value: 'monday' },
        { label: 'Tuesday', value: 'tuesday' },
        { label: 'Wednesday', value: 'wednesday' },
        { label: 'Thursday', value: 'thursday' },
        { label: 'Friday', value: 'friday' },
        { label: 'Saturday', value: 'saturday' },
        { label: 'Sunday', value: 'sunday' }
    ])
];

export const HappyHourTimeConstraints: GenericFormControl[] = [
    GenericFormBuilder.dropdown('start', 'From', [], HourSlots.slice(0)),
    GenericFormBuilder.dropdown('end', 'To', [], HourSlots.slice(0))
];

export const HappyHourConstraints: GenericFormType[] = [
    GenericFormBuilder.text('name', 'Name', [Validators.required]),
    GenericFormBuilder.dropdown('species', 'Food/Drink', [], [
        { label: 'Food', value: 'food' },
        { label: 'Drink', value: 'drink' },
        { label: 'Food & Drink', value: 'food-and-drink' }], false, null),
    GenericFormBuilder.array('day', 'Days', [
        GenericFormBuilder.group('startDate', null, HappyHourDayConstraints, null, 'inline')
    ]),
    GenericFormBuilder.array('time', 'Hours', [
        GenericFormBuilder.group('time', null, HappyHourTimeConstraints, null, 'inline')
    ])
];
