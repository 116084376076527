import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import * as Faker from 'Faker';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[entityListFilters]',
  templateUrl: './entity-list.filters.component.html'
})
export class EntityListFiltersComponent implements OnInit {
  @Input() filters: any[] = [];
  @Input() dtElement: DataTableDirective;
  @Input() datatableReady: BehaviorSubject<boolean>;
  @Input() filterDates: any = {};
  filterUsageMap: any = {};
  instanceHash: string;

  bsConfig: Partial<BsDatepickerConfig>;

  constructor() {
    this.instanceHash = Faker.Helpers.randomNumber(100000) + '';
  }

  ngOnInit() {
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-blue',
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.datatableReady.subscribe((value) => {
      if (value) {
        this.activateTextFilters();
        this.activateDateRangeFilters();
        this.activateSelectFilters();
      }
    });
    this.filterUsageMap = this.filters.map(() => false);
  }

  onDatePickerChanged(event, index) {
    this.filterDates[index] = event;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  clearTextInput(index: number) {
    const selector = `filter-${this.instanceHash}-${index}`;
    const input = $(`[data-filter-input=${selector}]`);
    input.val('');
    input.trigger('keyup');
  }

  clearDateRangeInput(index: number) {
    const selector = `filter-${this.instanceHash}-${index}`;
    const input = $(`[data-filter-input=${selector}]`);
    input.val('');
    this.onDatePickerChanged(null, index);
  }

  private activateTextFilters() {
    const component = this;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function (index) {
        const column = this;
        $('input', this.footer()).on('keyup change', function (x, y, z) {
          const keyword = this['value'];
          component.filterUsageMap[index] = !!keyword;
          if (column.search() !== keyword) {
            column
              .search(keyword)
              .draw();
          }
        });
      });
    });
  }

  private activateDateRangeFilters() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      const dateRangeFilters = this.filters.filter((f) => {
        return f.type === 'date-range';
      });
      for (let i = 0; i < dateRangeFilters.length; i++) {
        const index = dateRangeFilters[i].index;
        const dateValue = new Date(data[index]);
        if (this.filterDates[index] && this.filterDates[index].length === 2) {
          if (dateValue < this.filterDates[index][0] || dateValue > this.filterDates[index][1]) {
            return false;
          }
        }
      }
      return true;
    });
  }

  private activateSelectFilters() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('select', this.footer()).on('change', function () {
          if (that.search() !== this['value']) {
            that
              .search(this['value'])
              .draw();
          }
        });
      });
    });
  }
}
