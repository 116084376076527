import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EventService } from './event.service';
import { IEntityService } from '../entities/entity.service';

@Injectable()
export class EventServiceResolver implements Resolve<IEntityService> {
  constructor(private eventService: EventService) {}

  resolve(): IEntityService {
    return this.eventService;
  }
}
