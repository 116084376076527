import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserServiceResolver } from '../users/user.service.resolver';
import { LandingComponent } from '../login/landing.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: '**', component: PageNotFoundComponent, data: { title: 'Page Not Found' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [PageNotFoundComponent],
  providers: [UserServiceResolver]
})
export class AppRoutingModule {}
