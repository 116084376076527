import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PagesService } from './pages.service';
import { IEntityService } from '../entities/entity.service';

@Injectable()
export class PagesServiceResolver implements Resolve<IEntityService> {
  constructor(private pagesService: PagesService) {}

  resolve(): IEntityService {
    return this.pagesService;
  }
}
