import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { BehaviorSubject } from 'rxjs';
import { GenericFormComponent, GenericFormType } from '../../generic-form/generic-form.component';
import { UtilityService } from '../../core/utility.service';
import { EnumService } from '../enums.service';

@Component({
  selector: 'app-enum-edit',
  templateUrl: './enum-edit.component.html'
})
export class EnumEditComponent implements OnInit, AfterViewInit {

  @ViewChild('genericForm', {static: false}) genericForm: GenericFormComponent;

  entity: any;
  enumOptions: any[] = [];
  createInProgress: boolean;
  category: string;
  updateRules: GenericFormType[];
  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tabs: any[] = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private utilityService: UtilityService,
    private enumService: EnumService) { }

  ngOnInit() {
    this.category = this.enumService.getName();
    this.updateRules = this.enumService.getCreateConstraints();
    this.reloadEntity()
      .then(() => {
        this.loadTabs();
        setTimeout(() => {
          this.initForm();
          this.entityReady.next(true);
        }, 1500);
      });
  }

  ngAfterViewInit() {
    this.initForm();
  }

  async saveChanges() {
    if (!this.validateEntity()) {
      return;
    }

    try {
      this.createInProgress = true;
      this.entity.key = this.genericForm.myForm.get('key').value;
      this.entity.value = this.genericForm.myForm.get('value').value;
      this.entity = await this.enumService.create(this.entity);
      this.reloadEntity()
        .then(() => {
            this.entityReady.next(true);
        });
      this.toasterService.pop('success', 'Entity saved successfully');
    } catch (e) {
      console.error(e);
      this.toasterService.pop('error', 'Entity save failed');
    } finally {
      this.createInProgress = false;
    }
  }

  tabClicked(index) {
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].active = (i === index);
    }
  }

  getCurrentTab(): number {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].active) {
        return i;
      }
    }
    return -1;
  }

  reloadEntity() {
    this.entityReady.next(false);
    const enumType: string = this.route.snapshot.paramMap.get('type');
    this.entity = {
        type: enumType,
        key: '',
        value: ''
    };
    return this.enumService.getById(enumType)
      .then((options) => {
        this.enumOptions = options;
        this.entityReady.next(true);
      });
  }

  initForm() {
    for (const key in this.entity) {
      if (this.entity.hasOwnProperty(key)) {
        const control = this.genericForm.myForm.get(key);
        if (control) {
          control.setValue(this.entity[key]);
        }
      }
    }
  }

  onChange(test) {
    console.log(test);
  }

  closePanel() {
    this.router.navigateByUrl('/' + this.category);
  }

  async deleteEnumOption(option) {
    const result = confirm('Are you sure you want to delete an entity');
    if (result) {
      await this.enumService.delete(option);
      this.reloadEntity()
        .then(() => {
            this.entityReady.next(true);
        });
      this.toasterService.pop('success', 'Entity successfully deleted');
    }
  }

  private validateEntity() {
    return this.genericForm.myForm.valid;
  }

  private loadTabs() {
    this.tabs = [];
    this.tabs.push({
      path: '',
      label: 'General',
      active: this.router.url === ('/' + this.category + '/' + this.entity.id),
    });
    if (this.route.snapshot.data.tabs && this.route.snapshot.data.tabs.length) {
      for (let i = 0; i < this.route.snapshot.data.tabs.length; i++) {
        const tab = this.route.snapshot.data.tabs[i];
        tab.active = this.router.url === ('/' + this.category + '/' + this.entity.id + '/' + tab.path);
        this.tabs.push(tab);
      }
    }
  }
}
