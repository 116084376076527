
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '../event.service';
import { GenericFormType } from '../../generic-form/generic-form.component';
import { GenericFormBuilder } from '../../generic-form/generic-form.builder';

@Component({
  selector: 'app-event-meeting-info',
  templateUrl: './event-meeting-info.component.html'
})
export class EventMeetingInfoComponent implements OnInit {

  event: any;
  meetingInfo: any;
  meetingInfoReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  meetingInfoRules: GenericFormType[] = [];

  constructor(
    private eventService: EventService,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const eventId: string = this.route.parent.snapshot.paramMap.get('id');
    this.event = await this.eventService.getById(eventId);
    if (!!this.event.meeting) {
      this.meetingInfo = this.event.meeting;
      this.meetingInfoRules = [GenericFormBuilder.json('data', null, [], this.meetingInfo)];
    }
    this.meetingInfoReady.next(true);
  }
}
