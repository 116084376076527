import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './layout.header.component.html'
})
export class HeaderComponent implements OnInit {
  @Output() loggedOut = new EventEmitter<boolean>();

  showUserOptions: boolean;
  loggedUser: any;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.showUserOptions = false;
    this.loggedUser = this.authService.getCurrentUser();
  }

  ngOnInit() {
  }

  async logout() {
    await this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  toggleUserOptions() {
    this.showUserOptions = !this.showUserOptions;
  }
}
