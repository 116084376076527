
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../users/user.service';

@Component({
  selector: 'app-user-gallery',
  templateUrl: './user-gallery.component.html'
})
export class UserGalleryComponent implements OnInit {

  user: any;
  userReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private userService: UserService,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const userId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      this.user = await this.userService.getById(userId);
    } catch (err) {
      console.log(err);
    }
    this.userReady.next(true);
  }
}
