import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'humanize'
})
export class HumanizePipe implements PipeTransform {

  transform(value: Date, args?: any): string {
    const now = moment();
    const then = moment(value);

    return moment.duration(now.diff(then)).humanize();
  }

}
