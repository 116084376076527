
import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { VenueService } from '../venue.service';
import { GenericFormComponent } from '../../generic-form/generic-form.component';
import { VenueWorkingHoursConstraints } from '../venue.constraints';

@Component({
  selector: 'app-venue-working-hours',
  templateUrl: './venue-working-hours.component.html'
})
export class VenueWorkingHoursComponent implements OnInit {

  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;

  venue: any;
  venueReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  workingHoursRules = VenueWorkingHoursConstraints;

  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const venueId: string = this.route.parent.snapshot.paramMap.get('id');
    try {
      this.venue = await this.venueService.getById(venueId);
      setTimeout(() => {
        const whControl = this.genericForm.myForm.get('workingHours');
        if (whControl) {
          whControl.setValue(this.venue.workingHours);
        }
      });
    } catch (err) {
      console.log(err);
    }
    this.venueReady.next(true);
  }
}
