import { Injectable } from '@angular/core';

import { IEntityService } from '../entities/entity.service';
import { ApiService } from '../core/api.service';
import { GenericFormType } from '../generic-form/generic-form.component';
import { PagesCreateConstraints, PagesUpdateConstraints } from './pages.constraints';
import { ITableColumn } from '../entities/ITableColumn';

@Injectable()
export class PagesService implements IEntityService {

  constructor(
    private ApiService: ApiService) { }

  getName(): string {
    return 'pages';
  }

  getAll(): Promise<any[]> {
    return this.ApiService.get('/page', [])
      .then((pages: any[]) => {
        return pages;
      });
  }

  getOne(query: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  find(query: any): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  getById(id: string): Promise<any> {
    return this.ApiService.get('/page/' + id, [])
      .then((user) => {
        user._title = `${user.firstName} ${user.lastName}`;
        return user;
      });
  }

  create(entity: any): Promise<any> {
    return this.ApiService.post('/page', entity, []);
  }

  update(entity: any): Promise<any> {
    const entityCopy = JSON.parse(JSON.stringify(entity));
    delete entityCopy._title;
    delete entityCopy.createdAt;
    delete entityCopy.status;
    return this.ApiService.put('/page/' + entityCopy.id, entityCopy, []);
  }

  delete(entity: any): Promise<boolean> {
    return this.ApiService.delete('/page/' + entity.id, []);
  }

  getTableColumns(): ITableColumn[] {
    return [{
      index: 0,
      name: 'id',
      type: 'text',
      label: 'ID',
      hidden: false
    }, {
      index: 1,
      type: 'text',
      name: 'name',
      label: 'Name',
      hidden: false
    }, {
      index: 2,
      type: 'text',
      name: 'type',
      label: 'Type',
      hidden: false
    }];
  }

  getTableOptions(): any {
    return {};
  }

  getCreateConstraints(): GenericFormType[] {
    return PagesCreateConstraints;
  }
  getUpdateConstraints(): GenericFormType[] {
    return PagesUpdateConstraints;
  }
}
