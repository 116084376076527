import { FormGroup } from '@angular/forms';
import { GenericFormType } from '../generic-form/generic-form.component';

export class UtilityService {
  constructor() { }

  fillEntity(form: FormGroup, rules: GenericFormType[], entity: any): any {
    for (let i = 0; i < rules.length; i++) {
      if ((rules[i] as any).inputType === 'dropdown') {
        const selectedLookup = (rules[i] as any).lookups.find((l) => {
          return l.value === form.get(rules[i].name).value;
        });
        entity[rules[i].name] = selectedLookup ? selectedLookup.value : entity[rules[i].name];
      } else {
        entity[rules[i].name] = form.get(rules[i].name).value;
      }
    }
    return entity;
  }
}
