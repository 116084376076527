import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { User } from '../shared/user';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
  user: User;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.hasToken());
  private host: string;

  constructor(private http: HttpClient) {
    this.host = environment.bdmApi.host;
  }

  isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  async login(email: string, password: string): Promise<User> {
    const payload: any = {
      email: email,
      password: password
    };
    const response = await this.postLogin(payload, []);
    this.user = response;
    this.loggedIn.next(true);
    localStorage.setItem('user', JSON.stringify(this.user));
    return this.user;
  }

  getCurrentUser(): any {
    if (!this.user) {
      this.user = JSON.parse(localStorage.getItem('user'));
    }
    return this.user;
  }

  async logout(): Promise<any> {
    this.user = null;
    localStorage.removeItem('user');
    this.loggedIn.next(false);
    return Promise.resolve();
  }

  private hasToken(): boolean {
    this.user = JSON.parse(localStorage.getItem('user'));
    return !!this.user;
  }

  postLogin(data: any, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.host + '/token', data, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error.error);
        });
    });
  }
}
