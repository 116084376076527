import { Injectable } from '@angular/core';
import { AutomapperService } from '../core/automapper.service';
import { ApiService } from '../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class PromoCodesService {

  constructor(
    private ApiService: ApiService,
    private AutomapperService: AutomapperService
  ) { }

  getAllPromoCodes(): Promise<any[]> {
    return this.ApiService.get('/admin/promo-code', [])
    .then((subscriptions: any[]) => {
      // const res = this.AutomapperService.mapArray('UserApi', 'UserModel', subscriptions);
      const res = subscriptions;
      return res;
    })
  }

  getPromoCodesById(id: string): Promise<any[]> {
    return this.ApiService.get('/promo-code/' + id, [])
    .then((codes: any[]) => {
      // const res = this.AutomapperService.mapArray('UserApi', 'UserModel', codes);
      const res = codes;
      return res;
    })
  }
}
