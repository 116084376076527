import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { AutomapperService } from '../core/automapper.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  constructor(
    private ApiService: ApiService,
    private AutomapperService: AutomapperService
  ) { }

  getAllSubscriptions(): Promise<any[]> {
    return this.ApiService.get('/admin/subscription-credits', [])
    .then((subscriptions: any[]) => {
      // const res = this.AutomapperService.mapArray('UserApi', 'UserModel', subscriptions);
      const res = subscriptions;
      return res;
    })
  }

}
