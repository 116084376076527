import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { UserService } from './user.service';
import { IEntityService } from '../entities/entity.service';

@Injectable()
export class UserServiceResolver implements Resolve<IEntityService> {
  constructor(private userService: UserService) {}

  resolve(): IEntityService {
    return this.userService;
  }
}
