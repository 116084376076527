import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VenueService } from '../venue.service';
import { UserService } from '../../users/user.service';
import { BehaviorSubject } from 'rxjs';
import { GenericFormType, GenericFormComponent } from '../../generic-form/generic-form.component';

@Component({
  selector: 'app-venue-owner',
  templateUrl: './venue-owner.component.html'
})
export class VenueOwnerComponent implements OnInit {

  @ViewChild('genericForm' , {static: false}) genericForm: GenericFormComponent;

  venue: any;
  owner: any;
  ownerName: string;
  ownerRules: GenericFormType[];
  venueReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private venueService: VenueService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.ownerRules = this.userService.getOwnerConstraints();
    this.reloadEntity()
    .then(() => {
      setTimeout(() => {
        this.initForm();
        this.venueReady.next(true);
      }, 1500);
    });
  }

  async reloadEntity() {
    this.venueReady.next(false);
    const venueId: string = this.route.parent.snapshot.paramMap.get('id');
    this.venue = await this.venueService.getById(venueId);
    return this.userService.getById(this.venue.ownerId)
      .then((owner) => {
      this.owner = owner;
      this.ownerName = owner.name;
    });
  }

  initForm() {
    for (const key in this.owner) {
      if (this.owner.hasOwnProperty(key)) {
        const control = this.genericForm.myForm.get(key);
        if (control) {
          control.setValue(this.owner[key]);
        }
      }
    }
  }

}
