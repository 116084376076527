import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionsService } from './subscriptions.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { UserService } from '../users/user.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  subscriptions: any[];
  activeTab: string = 'subscriptions';
  filteredSubscriptions: any[];
  searchSubscriptions: string = '';

  constructor(private subscriptionsService: SubscriptionsService, private router: Router, private userService: UserService ) { }

  ngOnInit() {
    this.loadEntitiesData();
  }

  private async loadEntitiesData() {
    this.entityReady.next(false);
    this.subscriptions = await this.subscriptionsService.getAllSubscriptions();
    this.filteredSubscriptions = this.subscriptions;
    console.log('====================================');
    console.log(this.subscriptions);
    console.log('====================================');
    this.entityReady.next(true);
  }

  switchTab(tab: string) {
    this.activeTab = tab;
  }

  formatDate(date: string) {
    const formatedDate = moment(date).format('MM/DD/YYYY');
    return formatedDate;
  }

  calculateEarliesRefund(date: string): string {
    const newDate = moment(date).add(45, 'days');
    const formattedDate = newDate.format('MM/DD/YYYY');
    return formattedDate;
  }

  async getUserName(userId: string) {
    const user = await this.userService.getById(userId);
    const res = `${user.firstName} ${user.lastName}`;
    return res;
  }

  async filterAllSubscriptions() {
    this.filteredSubscriptions = this.subscriptions.filter(item => new RegExp(this.searchSubscriptions, 'i').test(item.userId));
  }

}
