import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticatedGuard } from './authenticated.guard';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { AutomapperService } from './automapper.service';
import { HttpClientModule } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { EnumService } from '../core/enum.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AuthService,
    AuthenticatedGuard,
    ApiService,
    AutomapperService,
    UtilityService,
    EnumService
  ]
})
export class CoreModule { }
