import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, TabsetComponent } from 'ngx-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { EventService } from '../event/event.service';
import { GenericMenuCategories } from '../venue/venue-menu/generic.menu.categories';
import { MenuService } from '../venue/venue-menu/venue-menu.service';
import { VenueService } from '../venue/venue.service';

@Component({
    selector: 'app-dashboard-create-orders',
    templateUrl: './dashboard.create.orders.component.html',
    styleUrls: ['../venue/venue-menu/venue-menu.component.scss', './dashboard.create.orders.component.scss']
})
export class DashboardCreateOrdersComponent implements OnInit {

    @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

    venue: any;
    event: any;
    menueTabs: any[];
    menuTypes: any;
    currentTabIndex: any;
    menues: any[];
    currentCategories: any[];
    selectedItems: any[];
    createInProgress: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private eventService: EventService,
        private menuService: MenuService) { }

    async ngOnInit() {
        await this.getMenuTypes();
        await this.loadMenus();
    }

    async getMenuTypes() {
        // const menuTypes = await this.enumService.getByType('menu-types');
        this.menueTabs = [];
        this.menuTypes = await this.menuService.getMenuTypes(this.venue.id);
        this.menuTypes.filter((x) => !x.disabled).forEach((element, index) => {
            this.menueTabs.push({
                key: element.key,
                label: element.value,
                active: index === this.currentTabIndex
            });
        });
        if (!this.currentTabIndex || this.currentTabIndex === -1) {
            this.menueTabs[0].active = true;
        }
    }

    async loadMenus() {
        const categories = [];
        this.menues = await this.menuService.getAllMenues(this.venue.id);
        this.menues.forEach(item => {
            const mt = this.menuTypes.find((x) => x.key === item.type);
            item.name = mt ? mt.value : item.type;
            item.categories.forEach((childItem, index) => {
                childItem.id = `${item.id}${index}`;
                categories.push(childItem);
            });
        });
        await this.getMenuTypes();
        if (this.staticTabs) {
            const activeTab = this.staticTabs.tabs.find((x) => x.active);
            const tabIndex = this.staticTabs.tabs.indexOf(activeTab);
            this.currentTabIndex = tabIndex;
        }
        this.currentCategories = categories;
    }

    getMenuItems(menu: string) {
        const menuItems = this.menues.filter((el) => el.type === menu);
        return menuItems;
    }

    shouldShowCategory(category) {
        return (category.items && category.items.length);
    }

    getConnectedList(key: string): any[] {
        const menuItem = this.getMenuItems(key)[0];
        return menuItem.categories.map(x => `${x.id}`);
    }

    getLowestPrice(menu: any) {
        const prices = [];
        if (menu.options) {
            menu.options.forEach((option) => {
                prices.push(option.price.value);
            });
        }
        return Math.min(...prices);
    }

    selectMenuItem(item) {
        console.log(item, 'selectItem');
        if (!this.selectedItems) {
            this.selectedItems = [];
        }

        const existingItem = this.selectedItems.find((x) => x.name === item.name);
        if (existingItem) {
            existingItem.quantity += 1;
        } else {
            // tslint:disable-next-line:max-line-length
            this.selectedItems.push({name: item.name, quantity: 1, menuItemId: item.id, optionId: item.options[0].id, price: item.options[0].price.value});
        }
    }

    removeItem(item) {
        const existingItem = this.selectedItems.find((x) => x.name === item.name);
        if (!!existingItem) {
            const index = this.selectedItems.indexOf(existingItem);
            if (index > -1) {
                this.selectedItems.splice(index, 1);
            }
        }
    }

    clearSelectedItems() {
        this.selectedItems.length = 0;
    }

    isItemSelected(item) {
        if (!this.selectedItems) {
            this.selectedItems = [];
        }
        return !!this.selectedItems.find((x) => x.name === item.name);
    }

    async create() {
        this.createInProgress = true;
        await this.eventService.createOrders(this.venue.id, this.event.id, this.selectedItems);
        this.createInProgress = false;
        this.bsModalRef.hide();
    }

    selectedItemsTotal() {
        let total = 0;
        this.selectedItems.forEach((x) => {
            total += x.price * x.quantity;
        });
        return total.toFixed(2);
    }

    cancel() {
        this.bsModalRef.hide();
    }
}
