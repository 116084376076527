import { Lookup } from '../generic-form/generic-form.component';

export const HourSlots: Lookup[] = [
    { label: '', value: null},
    { label: '12:00 AM', value: 'T00:00:00' },
    { label: '12:30 AM', value: 'T00:30:00' },
    { label: '01:00 AM', value: 'T01:00:00' },
    { label: '01:30 AM', value: 'T01:30:00' },
    { label: '02:00 AM', value: 'T02:00:00' },
    { label: '02:30 AM', value: 'T02:30:00' },
    { label: '03:00 AM', value: 'T03:00:00' },
    { label: '03:30 AM', value: 'T03:30:00' },
    { label: '04:00 AM', value: 'T04:00:00' },
    { label: '04:30 AM', value: 'T04:30:00' },
    { label: '05:00 AM', value: 'T05:00:00' },
    { label: '05:30 AM', value: 'T05:30:00' },
    { label: '06:00 AM', value: 'T06:00:00' },
    { label: '06:30 AM', value: 'T06:30:00' },
    { label: '07:00 AM', value: 'T07:00:00' },
    { label: '07:30 AM', value: 'T07:30:00' },
    { label: '08:00 AM', value: 'T08:00:00' },
    { label: '08:30 AM', value: 'T08:30:00' },
    { label: '09:00 AM', value: 'T09:00:00' },
    { label: '09:30 AM', value: 'T09:30:00' },
    { label: '10:00 AM', value: 'T10:00:00' },
    { label: '10:30 AM', value: 'T10:30:00' },
    { label: '11:00 AM', value: 'T11:00:00' },
    { label: '11:30 AM', value: 'T11:30:00' },
    { label: '12:00 PM', value: 'T12:00:00' },
    { label: '12:30 PM', value: 'T12:30:00' },
    { label: '01:00 PM', value: 'T13:00:00' },
    { label: '01:30 PM', value: 'T13:30:00' },
    { label: '02:00 PM', value: 'T14:00:00' },
    { label: '02:30 PM', value: 'T14:30:00' },
    { label: '03:00 PM', value: 'T15:00:00' },
    { label: '03:30 PM', value: 'T15:30:00' },
    { label: '04:00 PM', value: 'T16:00:00' },
    { label: '04:30 PM', value: 'T16:30:00' },
    { label: '05:00 PM', value: 'T17:00:00' },
    { label: '05:30 PM', value: 'T17:30:00' },
    { label: '06:00 PM', value: 'T18:00:00' },
    { label: '06:30 PM', value: 'T18:30:00' },
    { label: '07:00 PM', value: 'T19:00:00' },
    { label: '07:30 PM', value: 'T19:30:00' },
    { label: '08:00 PM', value: 'T20:00:00' },
    { label: '08:30 PM', value: 'T20:30:00' },
    { label: '09:00 PM', value: 'T21:00:00' },
    { label: '09:30 PM', value: 'T21:30:00' },
    { label: '10:00 PM', value: 'T22:00:00' },
    { label: '10:30 PM', value: 'T22:30:00' },
    { label: '11:00 PM', value: 'T23:00:00' },
    { label: '11:30 PM', value: 'T23:30:00' },
    { label: '11:59 PM', value: 'T23:59:99' },
];

export const HourSlotsTwo: Lookup[] = [
  { label: '', value: null},
  { label: '12:00 AM', value: 'T23:59:59.999Z' },
  { label: '12:30 AM', value: 'T00:30:00.000Z' },
  { label: '01:00 AM', value: 'T00:59:59.999Z' },
  { label: '01:30 AM', value: 'T01:30:00.000Z' },
  { label: '02:00 AM', value: 'T01:59:59.999Z' },
  { label: '02:30 AM', value: 'T02:30:00.000Z' },
  { label: '03:00 AM', value: 'T02:59:59.999Z' },
  { label: '03:30 AM', value: 'T03:30:00.000Z' },
  { label: '04:00 AM', value: 'T03:59:59.999Z' },
  { label: '04:30 AM', value: 'T04:30:00.000Z' },
  { label: '05:00 AM', value: 'T04:59:59.999Z' },
  { label: '05:30 AM', value: 'T05:30:00.000Z' },
  { label: '06:00 AM', value: 'T05:59:59.999Z' },
  { label: '06:30 AM', value: 'T06:30:00.000Z' },
  { label: '07:00 AM', value: 'T06:59:59.999Z' },
  { label: '07:30 AM', value: 'T07:30:00.000Z' },
  { label: '08:00 AM', value: 'T07:59:59.999Z' },
  { label: '08:30 AM', value: 'T08:30:00.000Z' },
  { label: '09:00 AM', value: 'T08:59:59.999Z' },
  { label: '09:30 AM', value: 'T09:30:00.000Z' },
  { label: '10:00 AM', value: 'T09:59:59.999Z' },
  { label: '10:30 AM', value: 'T10:30:00.000Z' },
  { label: '11:00 AM', value: 'T10:59:59.999Z' },
  { label: '11:30 AM', value: 'T11:30:00.000Z' },
  { label: '12:00 PM', value: 'T11:59:59.999Z' },
  { label: '12:30 PM', value: 'T12:30:00.000Z' },
  { label: '01:00 PM', value: 'T12:59:59.999Z' },
  { label: '01:30 PM', value: 'T13:30:00.000Z' },
  { label: '02:00 PM', value: 'T13:59:59.999Z' },
  { label: '02:30 PM', value: 'T14:30:00.000Z' },
  { label: '03:00 PM', value: 'T14:59:59.999Z' },
  { label: '03:30 PM', value: 'T15:30:00.000Z' },
  { label: '04:00 PM', value: 'T15:59:59.999Z' },
  { label: '04:30 PM', value: 'T16:30:00.000Z' },
  { label: '05:00 PM', value: 'T16:59:59.999Z' },
  { label: '05:30 PM', value: 'T17:30:00.000Z' },
  { label: '06:00 PM', value: 'T17:59:59.999Z' },
  { label: '06:30 PM', value: 'T18:30:00.000Z' },
  { label: '07:00 PM', value: 'T18:59:59.999Z' },
  { label: '07:30 PM', value: 'T19:30:00.000Z' },
  { label: '08:00 PM', value: 'T19:59:59.999Z' },
  { label: '08:30 PM', value: 'T20:30:00.000Z' },
  { label: '09:00 PM', value: 'T20:59:59.999Z' },
  { label: '09:30 PM', value: 'T21:30:00.000Z' },
  { label: '10:00 PM', value: 'T21:59:59.999Z' },
  { label: '10:30 PM', value: 'T22:30:00.000Z' },
  { label: '11:00 PM', value: 'T22:59:59.999Z' },
  { label: '11:30 PM', value: 'T23:30:00.000Z' },
];
