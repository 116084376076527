import { Component, OnInit } from '@angular/core';
import { BansService } from '../bans.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-bans',
  templateUrl: './bans.component.html',
  styleUrls: ['./bans.component.scss']
})
export class BansComponent implements OnInit {

  service: any;
  banned: any;
  deleted: any;
  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchTermBanned: string = '';
  searchTermDeleted: string = '';
  filteredBanned: any[];
  filteredDeleted: any[];

  constructor( private bansService: BansService, private router: Router ) { }

  ngOnInit() {
    this.service = BansService;
    this.loadEntitiesData();
    this.filteredBanned = this.banned;
    this.filteredDeleted = this.banned;
  }

  activeTab: string = 'bannedList';

  private async loadEntitiesData() {
    this.entityReady.next(false);
    const resbanned = await this.bansService.getAll();
    this.banned = resbanned.reverse();
    this.filteredBanned = this.banned;
    // this.banned = await this.bansService.getAll();
    const resdeleted = await this.bansService.getAllDeleted();
    this.deleted = resdeleted.reverse();
    this.filteredDeleted = this.deleted;
    // this.deleted = await this.bansService.getAllDeleted();
    this.entityReady.next(true);
  }

  async filterBanned() {
    this.filteredBanned = this.banned.filter(user => new RegExp(this.searchTermBanned, 'i').test(user.email));
  }

  async filterDeleted() {
    this.filteredDeleted = this.deleted.filter(user => user.email.includes(this.searchTermDeleted));
  }

  // Método para cambiar la pestaña activa
  switchTab(tab: string) {
    this.activeTab = tab;
  }

  selectedItem(item) {
    if (this.activeTab === 'bannedList') {
      this.router.navigate(['/' + 'bans' +'/' + 'bansInfo' + '/' + item.id]);
    }else{
      this.router.navigate(['/' + 'bans' +'/' + 'deletedInfo' + '/' + item.id]);
    }
    // this.router.navigate(['/' + 'bans' +'/' + 'bansInfo' + '/' + item.id]);
  }

}
