import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { IEntityService } from '../entity.service';
import { GenericFormType, GenericFormComponent } from '../../generic-form/generic-form.component';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { UtilityService } from '../../core/utility.service';

@Component({
  selector: 'app-entity-create',
  templateUrl: './entity-create.component.html'
})
export class EntityCreateComponent implements OnInit {

  @ViewChild('genericForm', {static: false}) genericForm: GenericFormComponent;

  entity: any;
  createInProgress: boolean;
  category: string;
  entityService: IEntityService;
  createRules: GenericFormType[];
  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private route: ActivatedRoute,
    private toasterService: ToasterService,
    private location: Location,
    private utilityService: UtilityService) { }

  async ngOnInit() {
    this.entityService = this.route.snapshot.data.service;
    this.category = this.entityService.getName();
    this.createRules = this.entityService.getCreateConstraints();
    this.entity = {};
    this.entityReady.next(true);
    setTimeout(() => {
      this.reloadEntity();
    }, 1000);
  }

  async createEntity() {
    if (!this.validateEntity()) {
      console.log('form invalid');
      return;
    }

    console.log('form valid');

    try {
      this.createInProgress = true;
      this.entity = this.utilityService.fillEntity(this.genericForm.myForm, this.createRules, this.entity);
      await this.entityService.create(this.entity, this.route.snapshot.paramMap);
      this.location.back();
      this.toasterService.pop('success', 'Entity created');
    } catch (e) {
      console.error(e);
      this.toasterService.pop('error', 'Entity create failed.');
    } finally {
      this.createInProgress = false;
    }
  }

  onChange(e) {
    console.log(e);
  }

  reloadEntity() {
    this.entity = {};
    for (let i = 0; i < this.createRules.length; i++) {
      if ((this.createRules[i] as any).inputType === 'dropdown') {
        const selectedLookup = (this.createRules[i] as any).lookups.find((l) => l.selected);
        this.entity[this.createRules[i].name] = selectedLookup ? selectedLookup.value : null;
      } else {
        this.entity[this.createRules[i].name] = null;
      }
    }
    // this.entity = this.utilityService.fillEntity(this.genericForm.myForm, this.createRules, this.entity);
  }

  closePanel() {
    // this.router.navigateByUrl('/' + this.category);
    this.location.back();
  }

  private validateEntity() {
    this.genericForm.myForm.markAsDirty();
    return this.genericForm.myForm.valid;
    // const validationResult: IValidationResult = ModelValidator.GetInstance().validate(this.createRules, this.entity);
    // for (let i = 0; i < validationResult.errors.length; i++) {
    //   this.toasterService.pop('error', validationResult.errors[i].message);
    // }
    // return validationResult.valid;
  }
}
