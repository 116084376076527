import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../users/user.service';
import { IAmbassadorDataReport } from '../users/user-ambassador/user-ambassadorData';

@Component({
  selector: 'app-referral-codes',
  templateUrl: './referral-codes.component.html',
  styleUrls: ['./referral-codes.component.scss']
})
export class ReferralCodesComponent implements OnInit {

  entityReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  codes: IAmbassadorDataReport[];
  users: any[];
  Filteredcodes: any[];
  filteredUsers: any[];
  selectedItemId: string = null;
  selectedUser: string = null;
  referralCodes: any[];
  activeTab: string = 'byUser';
  searchallCodes: string = '';
  searchallUserCodes: string = '';
  itemLoading: boolean = false;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.loadEntitiesData();
  }

  private async loadEntitiesData() {
    this.entityReady.next(false);
    const fromDate = new Date(2019, 0, 1).toISOString();
    const toDate = new Date().toISOString();
    this.codes = await this.userService.getAllAmbassadorData(fromDate, toDate);
    this.users = await this.userService.getAll();
    this.users = this.users.filter(user => user.role === 'AMBASSADOR');
    this.Filteredcodes = this.codes;
    this.filteredUsers = this.users;
    this.entityReady.next(true);
  }

  async toggleShowReferences(item: any) {
    if (this.selectedUser === item.id) {
      this.selectedUser = null;
    } else {
      const fromDate = new Date(2019, 0, 1).toISOString();
      const toDate = new Date().toISOString();
      this.referralCodes = await this.userService.getAmbassadorData(item.id, fromDate, toDate);
      this.selectedUser = item.id;
    }
  }

  switchTab(tab: string) {
    this.activeTab = tab;
  }

  async filterAllCodes() {
    this.Filteredcodes = this.codes.filter(item => new RegExp(this.searchallCodes, 'i').test(item.ambassadorName));
  }

  async filterAllUsers() {
    this.filteredUsers = this.users.filter(item => new RegExp(this.searchallUserCodes, 'i').test(item.email));
  }

}
