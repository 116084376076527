import { GenericFormType } from '../generic-form/generic-form.component';
import { AbstractControl, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { GenericFormBuilder } from '../generic-form/generic-form.builder';
import { ITableColumn } from '../entities/ITableColumn';

export const richEditorTextLengthValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const content = control.value;
        if (!content) {
            return null;
        }
        const cleanText = removeHtml(content);
        if (cleanText.length > 140) {
            return { richEditorTextLength: { requiredLength: 140, actualLength: control.value.length } };
        }
        return null;
    }
}

export const richEditortNumberOfMediaValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const content = control.value;
        if (!content) {
            return null;
        }
        let numberOfMedia = 0;
        // commented out for now we'll se in the future
        /* const imgCount = content.split("<img");
        numberOfMedia += imgCount.length - 1;
        const iFrameCount = content.split("<iframe");
        numberOfMedia += iFrameCount.length - 1;
        const blockPostCount = content.split("<blockquote");
        numberOfMedia += blockPostCount.length - 1; */
        if (numberOfMedia > 1) {
            return { richEditorNumberOfMedia: { requiredNumber: 1, actualNumber: numberOfMedia } };
        }
        return null;
    }
}

export const PostCreateConstraints: GenericFormType[] = [
    GenericFormBuilder.richText('content', 'Content', [Validators.required, richEditorTextLengthValidator(), richEditortNumberOfMediaValidator()]),
    GenericFormBuilder.text('title', "Title (Autofill data, won't be shown on post)", []),
    GenericFormBuilder.datepicker('eventStartDate', "Event start date (Autofill data, won't be shown on post)", []),
];

export const PostUpdateConstraints: GenericFormType[] = [
    GenericFormBuilder.text('creatorType', 'Post from', [], true),
    GenericFormBuilder.text('creatorName', 'Posted by', [], true),
    GenericFormBuilder.text('createdAt', 'Created At', [], true),
    GenericFormBuilder.richText('content', 'Content', [Validators.required, richEditorTextLengthValidator()]),
    GenericFormBuilder.text('title', "Title (Autofill data, won't be shown on post)", []),
    GenericFormBuilder.datepicker('eventStartDate', "Event start date (Autofill data, won't be shown on post)", []),
];

export const postDataColumns: ITableColumn[] =
    [{
        index: 0,
        type: 'text',
        name: 'creatorType',
        label: 'Posted from',
        hidden: false
    }, {
        index: 1,
        type: 'text',
        name: 'creatorName',
        label: 'Posted by',
        hidden: false
    }, {
        index: 2,
        type: 'date',
        name: 'createdAt',
        label: 'Created At',
        hidden: false
    }, {
        index: 3,
        type: 'text',
        name: 'title',
        label: 'Title',
        hidden: false
    }, {
        index: 4,
        type: "html",
        name: "content",
        label: "Content",
        hidden: false,
    }];

const removeHtml = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
}
