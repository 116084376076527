import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { UserRoles } from '../users/user.roles';
import { BansComponent } from './bans/bans.component';
import { EntityListComponent } from '../entities/entity-list/entity-list.component';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { BansServiceResolver } from './bans.service.resolver';
import { BansService } from './bans.service';
import { EntityEditComponent } from '../entities/entity-edit/entity-edit.component';
import { BansInfoComponent } from './bans-info/bans-info.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LaddaModule,
    GenericFormModule,
    FormsModule,
    RouterModule.forChild([{
      path: 'bans',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/bans',
          icon: 'glyphicon-eye-close',
          label: 'Bans',
          category: 'bans',
          index: 7
        },
        access: [UserRoles.ADMIN]
      },
      children: [
        {
          path: '',
          component: BansComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: BansServiceResolver },
          data: { title: 'Bans', category: 'bans',  }
        },
        {
            path: ':type/:id',
            component: BansInfoComponent,
            canActivate: [AuthenticatedGuard],
            resolve: { service: BansServiceResolver },
            data: {
                category: 'bans',
                title: 'User Info',
            }
        }
      ]
    }])
  ],
  declarations: [BansComponent, BansInfoComponent ],
  providers: [BansService, BansServiceResolver]
})
export class BansModule { }
