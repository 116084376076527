import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-panel-loader',
  templateUrl: 'panel-loader.component.html'
})
export class PanelLoaderComponent implements OnInit {
  @Input() stop: BehaviorSubject<boolean>;
  showLoader = true;
  constructor() { }

  ngOnInit() {
    this.stop.subscribe((value) => {
      this.showLoader = !value;
    });
  }
}
