import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityListComponent } from '../entities/entity-list/entity-list.component';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { EnumsServiceResolver } from './enums.service.resolver';
import { EnumService } from './enums.service';
import { EnumEditComponent } from './enum-edit.component/enum-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JSONEditorModule } from 'ngx-jsoneditor';
import { SharedModule } from '../shared/shared.module';
import { LaddaModule } from 'angular2-ladda';
import { DataTablesModule } from 'angular-datatables';
import { MomentModule } from 'angular2-moment';
import { GenericFormModule } from '../generic-form/generic-form.module';

@NgModule({
  imports: [
    FormsModule,
    JSONEditorModule,
    CommonModule,
    SharedModule,
    LaddaModule,
    ReactiveFormsModule,
    DataTablesModule,
    MomentModule,
    GenericFormModule,
    RouterModule.forChild([{
      path: 'enums', component: LayoutComponent, children: [
        {
          path: '',
          component: EntityListComponent,
          resolve: { service: EnumsServiceResolver },
          data: { category: 'enums', title: 'Enums' }
        },
        {
          path: ':type',
          component: EnumEditComponent,
          resolve: { service: EnumsServiceResolver },
          data: {
            category: 'enums',
            title: 'Enum Type',
            tabs: []
          },
        }
      ]
    }])
  ],
  declarations: [EnumEditComponent],
  providers: [EnumService, EnumsServiceResolver]
})
export class EnumsModule {
}
