
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { VenueService } from '../venue.service';
import { DataTableDirective } from 'angular-datatables';
import { ITableColumn } from '../../entities/ITableColumn';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-venue-audits',
  templateUrl: './venue-audits.component.html'
})
export class VenueAuditsComponent implements OnInit {

  @ViewChild('keywordInput', {static: false}) keywordInput: ElementRef;
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;

  audits: any[];
  selectedActionType = 'CreateVenue';
  venue: any;

  columns: ITableColumn[];
  dtOptions: any;
  displayFilters = false;
  dtTrigger: Subject<any> = new Subject();
  venueReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  datatableReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  actionTypes = [
    {label: 'Create Venue', value: 'CreateVenue'},
    {label: 'Create Happy Hour', value: 'CreateHappyHour'},
    {label: 'Create Menu ', value: 'CreateMenuItem'},
  ];
  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    this.columns = this.getTableColumns();
    this.search();
  }

  async search() {
    this.datatableReady.next(false);
    const venueId = this.route.parent.snapshot.paramMap.get('id');
    const query = {
      actionName: this.selectedActionType,
    };
    const audits = await this.venueService.getAudits(venueId, query);
    // avoiding the usual mapping, will do when we see where audits end up :)
    audits.forEach(item => {
      item.context = {... item.context, ... item.context.user };
      if (['CreateHappyHour', 'CreateMenuItem'].indexOf(this.selectedActionType) !== -1) {
        item.context.refName = item.result.name;
      }
      if (this.selectedActionType === 'CreateVenue') {
        // TODO:
        // item.refName = item.result.name;
      }
    });
    this.audits = audits;

    console.log(this.audits);
    const self = this;
    this.datatableReady.next(true);
  }

  getTableColumns(): ITableColumn[] {
    return [
      {
        index: 0,
        name: 'venueName',
        type: 'text',
        label: 'Venue Name',
        hidden: false
      },
      {
        index: 1,
        name: 'userId',
        type: 'text',
        label: 'User ID',
        hidden: false
      },
      {
        index: 2,
        name: 'fullName',
        type: 'text',
        label: 'User Name',
        hidden: false
      },
      {
        index: 3,
        name: 'email',
        type: 'text',
        label: 'User Email',
        hidden: false
      },
      {
        index: 4,
        name: 'createdAt',
        type: 'date',
        label: 'Timestamp',
        hidden: false
      },
      {
        index: 5,
        name: 'refName',
        type: 'text',
        label: 'Name',
        hidden: false
      },
    ];
  }

  getTableOptions(): any {
    return {
      order: [[0, 'desc']]
    };
  }

}
