import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ProductsService } from './products.service';
import { IEntityService } from '../entities/entity.service';

@Injectable()
export class ProductServiceResolver implements Resolve<IEntityService> {
  constructor(private productService: ProductsService) {}

  resolve(): IEntityService {
    return this.productService;
  }
}
