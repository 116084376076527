
import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PostService } from '../post.service';
import { FileUploadComponent } from '../../file.upload/file.upload.component';

@Component({
    selector: 'app-post-image',
    templateUrl: './post-image.component.html'
})
export class PostImageComponent implements OnInit {

    @ViewChild('fileUpload', { static: false }) fileUpload: FileUploadComponent;

    post: any;
    postReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    uploadInProgress = false;

    constructor(
        private postService: PostService,
        private route: ActivatedRoute) {
    }

    async ngOnInit() {
        const postId: string = this.route.parent.snapshot.paramMap.get('id');
        try {
            this.post = await this.postService.getById(postId);
        } catch (err) {
            console.log(err);
        }
        this.postReady.next(true);
    }

    async upload() {
        if (!this.fileUpload || !this.fileUpload.filesData || !this.fileUpload.filesData.length || !this.post) {
            return;
        }
        this.uploadInProgress = true;
        this.post = await this.postService.uploadPhoto(this.post.id, this.fileUpload.filesData[0]);
        this.uploadInProgress = false;
        this.fileUpload.filesData.splice(0, 1);
        this.fileUpload.files.splice(0, 1);
        this.fileUpload.base64Array.splice(0, 1);
        this.fileUpload.fileNames.splice(0, 1);
        this.fileUpload.fileUploaded = false;
    }
}
